import React, { useState, useEffect, useRef } from 'react';
import { ChevronUp, ChevronDown, Clock } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const TimePicker = ({ value, onChange, hoursStep = 1, minutesStep = 15, className = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHours, setSelectedHours] = useState("00");
  const [selectedMinutes, setSelectedMinutes] = useState("00");
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const pickerRef = useRef(null);
  const dropdownRef = useRef(null);
  const hoursContainerRef = useRef(null);
  const minutesContainerRef = useRef(null);
  const [t] = useTranslation("global");

  // Gera as opções de horas
  const hours = Array.from({ length: Math.floor(24 / hoursStep) }, (_, i) =>
    (i * hoursStep).toString().padStart(2, '0')
  );

  // Gera as opções de minutos
  const minutes = Array.from({ length: Math.floor(60 / minutesStep) }, (_, i) =>
    (i * minutesStep).toString().padStart(2, '0')
  );

  // Fecha o picker quando clica fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Atualiza os valores selecionados quando o value prop muda
  useEffect(() => {
    if (value) {
      const [hours, minutes] = value.split(':');
      setSelectedHours(hours);
      setSelectedMinutes(minutes);
    }
  }, [value]);

    // Verifica o espaço disponível e ajusta a posição do dropdown
    useEffect(() => {
        if (isOpen && pickerRef.current && dropdownRef.current) {
          const pickerRect = pickerRef.current.getBoundingClientRect();
          const dropdownHeight = dropdownRef.current.offsetHeight;
          const windowHeight = window.innerHeight;
          const spaceBelow = windowHeight - pickerRect.bottom;
          const spaceAbove = pickerRect.top;

          // Se não houver espaço suficiente abaixo e houver mais espaço acima
          if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
            setDropdownPosition('top');
          } else {
            setDropdownPosition('bottom');
          }
        }
      }, [isOpen]);

        // Fecha o picker quando clica fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleTimeChange = (hours, minutes) => {
    const newTime = `${hours}:${minutes}`;
    onChange(0,"ArrivalTime",newTime);
  };

  const scrollToSelected = (containerRef, selectedValue) => {
    const selectedElement = containerRef.current?.querySelector(`[data-value="${selectedValue}"]`);
    if (selectedElement) {
      selectedElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

    // Scroll para os valores selecionados quando o dropdown abrir
    useEffect(() => {
        if (isOpen) {
          scrollToSelected(hoursContainerRef, selectedHours);
          scrollToSelected(minutesContainerRef, selectedMinutes);
        }
      }, [isOpen, selectedHours, selectedMinutes]);

  return (
    <div className="relative OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width OC-FormField-border_color " ref={pickerRef}>
      {/* Input principal */}
      <div className={`flex items-center p-2 cursor-pointer dark:bg-gxp_dark_3 dark:text-white dark:border-white transition-colors`} onClick={() => setIsOpen(!isOpen)} >
        <span className="flex-1 text-center">
          {selectedHours}:{selectedMinutes}
        </span>
        <Clock className="w-4 h-4 mr-2 OC-FormField-icon_color dark:text-white" />
      </div>

      {/* Dropdown do picker */}
      {isOpen && (
        <div ref={dropdownRef} className={`absolute ${ dropdownPosition === 'top' ? 'bottom-full mb-1' : 'top-full mt-1' } bg-white border rounded-lg shadow-lg z-50 flex time-picker-dropdown`}>
          {/* Coluna das horas */}
          <div className="w-16 border-r">
            <div className="p-2 text-center text-sm text-gray-500 border-b">
            {`${t(`hour`)}`}
            </div>
            <div ref={hoursContainerRef} className="h-48 overflow-y-auto time-picker-scrollbar">
              {hours.map((hour) => (
                <div
                  key={hour}
                  data-value={hour}
                  className={`p-2 text-center cursor-pointer hover:bg-blue-50 transition-colors
                    ${selectedHours === hour ? 'bg-blue-100 font-medium' : ''}`}
                  onClick={() => {
                    setSelectedHours(hour);
                    handleTimeChange(hour, selectedMinutes);
                  }}
                >
                  {hour}
                </div>
              ))}
            </div>
          </div>

          {/* Coluna dos minutos */}
          <div className="w-16">
            <div className="p-2 text-center text-sm text-gray-500 border-b">
            {`${t(`minute`)}`}
            </div>
            <div ref={minutesContainerRef} className="h-48 overflow-y-auto time-picker-scrollbar">
              {minutes.map((minute) => (
                <div
                  key={minute}
                  data-value={minute}
                  className={`p-2 text-center cursor-pointer hover:bg-blue-50 transition-colors
                    ${selectedMinutes === minute ? 'bg-blue-100 font-medium' : ''}`}
                  onClick={() => {
                    setSelectedMinutes(minute);
                    handleTimeChange(selectedHours, minute);
                  }}
                >
                  {minute}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};



