import { Routes, Route, Navigate } from 'react-router-dom'
import { WebText, TableReservationMenu, PageNotFound, OnlineCheckIn, TableReservation, Checkout, MaintenanceRequest, EMenu, Home, HotelHome, HotelReservationDetails, QrCode, DocumentScanner, EMenuMenu} from '../pages'
import { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from '../context/AppContext';
import { getGXPInitialData, getHotelById, getHotelInitialData } from '../services';
import { useCookies } from "react-cookie";
import { RootLayout } from '../pages/RootLayout';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';
import { AdminPanel } from '../AdminPanel/AdminPanel';
import { HotelList } from '../AdminPanel/Pages/HotelList';
import { GroupHotelList } from '../AdminPanel/Pages/GroupHotelList';
import { Dashboard } from '../AdminPanel/Pages/DashBoard';
import { Parameters } from '../AdminPanel/Pages/Parameters';
import { StyleCategory } from '../AdminPanel/Pages/StyleCategory';
import { StyleSubCategory } from '../AdminPanel/Pages/StyleSubCategory';
import ThemeStyle from '../AdminPanel/Pages/ThemeStyle/ThemeStyle';
import { ThemeDefault } from '../AdminPanel/Pages/ThemeDefault/ThemeDefault';
import { applyTheme } from './applyTheme';
import { Register } from '../AdminPanel/Pages/Auth/Register';
import { Login } from '../AdminPanel/Pages/Auth/Login';
import { ProtectedRoute } from './ProtectedRoute';
import { ConfirmRegister } from '../AdminPanel/Pages/ConfirmRegister';


const ThemeController = ({ theme, isPreviewMode }) => {
  const previousThemeRef = useRef(theme);
  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    // Se o tema não estiver disponível, não faz nada
    if (!state.activeTheme) return;

    // Se não estiver em preview mode, aplica o tema sempre
    if (!isPreviewMode) {
      applyTheme(state.activeTheme);
      previousThemeRef.current = state.activeTheme;
      return;
    }

    // Se não tivermos um tema anterior (primeira montagem em preview mode)
    if (!previousThemeRef.current) {
      applyTheme(state.activeTheme);
      previousThemeRef.current = state.activeTheme;
      return;
    }

    // Se estiver em preview mode
    const changedProperty = state.activeTheme.find((item, index) =>
      item.value !== previousThemeRef.current[index]?.value
    );

    if (changedProperty) {
      const isSpecialField = changedProperty.themeStyleCode.includes('_is_active') ||
                            changedProperty.themeStyleCode.includes('_image');

      if (!isSpecialField) {
        applyTheme(state.activeTheme);
      }
    }

    previousThemeRef.current = state.activeTheme;
  }, [state.activeTheme, isPreviewMode]);

  return null;
};

/**
 * Component that sets up routes for navigation
 * @returns Routing for navigation
 */
export const AllRoutes = () => {
  const [darkMode]=useState(JSON.parse(localStorage.getItem(`darkMode`)) || false)
  const [state, dispatch] = useContext(AppContext);
  const [cookie, setCookie] = useCookies(['platformUid', 'currentHotel','detailId','sUid']); // eslint-disable-line
  const [isAdmin, setIsAdmin] = useState();
  const [isPreviewMode, setIsPreviewMode] = useState();
  const pathname = window.location.pathname;   // Obter o pathname completo
  const parts = pathname.split('/'); // Dividir o pathname por '/' para obter as partes
  const [mounted, setMounted] = useState(false);

  dayjs.locale(localStorage.getItem("userLanguage") ? localStorage.getItem("userLanguage").substring(0,2) : "pt");

  // Inicializa platformUid, detailId e sUid com valores padrão
  let platformUid, detailId, sUid;

  useEffect(() => {
    setMounted(true);
  }, []);

    /**
   * UseEffect to update the darkMode theme state in the App.
   */
    useEffect(()=>{
      localStorage.setItem(`darkMode`, JSON.stringify(darkMode));
      if(darkMode){
        document.documentElement.classList.add(`dark`)
      } else {
        document.documentElement.classList.remove(`dark`)
      }

    // Verifica se o pathname começa com '/overview'
    if (pathname.includes("/home")) {
      platformUid = parts[2].toLocaleLowerCase(); // Obtém platformUid
      sUid = parts[3]; // Obtém sUid
    }
    // Verifica se o pathname começa com '/hotel'
    else if (pathname.includes("/hotel/")) {
      platformUid = parts[2].toLocaleLowerCase(); // Obtém platformUid
      detailId = parts[3]; // Obtém detailId
    }
    else if (pathname.includes("/admin")) {
      setIsAdmin(true)
    }
    else if (pathname.includes("/previewmode")) {
      setIsPreviewMode(true)
    }
    },[]) // eslint-disable-line

  useEffect(() => {
    const cookieOptions = { path: "/" };

    const fetchHotelData = async () => {

      if (window.location.pathname.includes('/admin')) {
        return; // Sai do useEffect se estiver na rota admin
      }

      if (window.location.pathname.includes('/previewmode')) {
        return; // Sai do useEffect se estiver na rota admin
      }

    // Queremos buscar se:
    // - Não temos um hotel no state (pmsId é null)
    // OU
    // - O platformUid atual é diferente do hotelId armazenado
    const shouldFetch = !state.currentHotel?.hotelId || platformUid !== state.currentHotel?.hotelId?.substring(0, 8);

      if (shouldFetch) {
        try {

          dispatch({ type: "SET_LOADING", payload: true });
          const hotelData = await getHotelById(platformUid);
          dispatch({ type: "SET_CURRENT_HOTEL", payload: hotelData });
          setCookie('currentHotel', JSON.stringify(hotelData), cookieOptions);
          setCookie('platformUid', platformUid, cookieOptions);
          setCookie('cultureCode', hotelData?.defaultCultureCode, {path:"/"});
          if (detailId) {
            setCookie('detailId', detailId, cookieOptions);
          }
          if (sUid) {
            setCookie('sUid', sUid, cookieOptions);
          }
        } catch (error) {
          console.error("App: Error fetching hotel info:", error);
        } finally {
          dispatch({ type: "SET_LOADING", payload: false });
        }
    };}

    if (!isAdmin && !isPreviewMode) {
      fetchHotelData();
    }
  }, [platformUid, isAdmin]); // eslint-disable-line


    /**
   * @events useEffectFetchHotel
   * @description Fetches initial hotel data when the hotel prop changes.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [hotel]);
   */
    useEffect(() => {
      const fetchHotelInitialData = async () => {
      if (state.currentHotel && state.currentHotel.hotelId) {
        try {
          const hotelInitialData = await getHotelInitialData(state.currentHotel, localStorage.getItem('userLanguage') );
          dispatch({ type: "SET_HOTEL_INITIALDATA", payload: hotelInitialData.InitialData });
        } catch (error) {
          console.error("Error loading initialData-AllRoutes:", error);
        }
      }}

      if (!isAdmin && !isPreviewMode) {
        fetchHotelInitialData()
      }
    }, [state.currentHotel, localStorage.getItem('userLanguage'), isAdmin]); // eslint-disable-line

    useEffect(() => {
      const fetchGxpInitialData = async () => {
      if (state.currentHotel && state.currentHotel.hotelId) {
        try {
          const gxpInitialData = await getGXPInitialData(state.currentHotel.hotelId);
          dispatch({ type: "SET_ACTIVE_THEME", payload: gxpInitialData.activeTheme });
          dispatch({ type: "SET_GXP_PARAMETERS", payload: gxpInitialData.parameters });
        } catch (error) {
          console.error("Error loading initialData-AllRoutes:", error);
        }
      }}

      if (!isAdmin && !isPreviewMode) {
        fetchGxpInitialData()
      }
    }, [state.currentHotel, isAdmin]); // eslint-disable-line

    return (
      <div className="">
              {mounted && ( <ThemeController theme={state.activeTheme} isPreviewMode={isPreviewMode} />)}
          <Routes className="">

                <Route path="/admin/login" element={<Login />} />
                <Route path="/admin/register" element={<Register />} />

                <Route path="/admin" element={<ProtectedRoute><AdminPanel /></ProtectedRoute>} >
                  <Route path="/admin/confirmregister" element={<ConfirmRegister />} />
                  <Route path="/admin/dashboard" element={<Dashboard />} />
                  <Route path="/admin/hotels" element={<HotelList />} />
                  <Route path="/admin/grouphotels" element={<GroupHotelList />} />
                  <Route path="/admin/parameters/parameters" element={<Parameters />} />
                  <Route path="/admin/themes/stylecategories" element={<StyleCategory />} />
                  <Route path="/admin/themes/stylesubcategories" element={<StyleSubCategory />} />
                  <Route path="/admin/themes/themestyles" element={<ThemeStyle />} />
                  <Route path="/admin/themes/themesdefault" element={<ThemeDefault />} />
                </Route>

                <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />

              <Route element={<RootLayout />} >
                <Route path="/home/:platformUid/:sUid" element={<Home />} />

                <Route path="/hotel/:platformUid/:detailId" element={<HotelHome />} />

                <Route path="/hotel/:platformUid/:detailId/reservation" element={<HotelReservationDetails />} />
                <Route path="/hotel/:platformUid/:detailId/checkout" element={<Checkout />} />
                <Route path="/hotel/:platformUid/:detailId/qrcode" element={<QrCode />} />

                <Route path="/hotel/:platformUid/:detailId/maintenancerequest" element={<MaintenanceRequest />} />

                <Route path="/hotel/:platformUid/:detailId/webtext/:key" element={<WebText />} />

                <Route path="/hotel/:platformUid/:detailId/checkin/:sUid/:resNo" element={<OnlineCheckIn />} />
                <Route path="/hotel/:platformUid/:detailId/checkindirect/:sUid/:resNo" element={<OnlineCheckIn />} />

                <Route path="/hotel/:platformUid/:detailId/emenu-menu/:sUid/:keys" element={<EMenuMenu />} />
                <Route path="/hotel/:platformUid/:detailId/emenu/:sUid/:key" element={<EMenu />} />
                <Route path="/hotel/:platformUid/:detailId/emenu/:sUid/:key/:itemId" element={<EMenu />} />

                <Route path="/hotel/:platformUid/:detailId/treservationmenu/:sUid/:keys" element={<TableReservationMenu />} />
                <Route path="/hotel/:platformUid/:detailId/treservation/:sUid/:key" element={<TableReservation />} />

                <Route path="/hotel/:platformUid/treservationmenudirect/:keys" element={<TableReservationMenu />} />
                <Route path="/hotel/:platformUid/treservationdirect/:key" element={<TableReservation />} />

                <Route path="/hotel/:platformUid/:detailId/emenu/:sUid/:keys" element={<EMenu />} />


                <Route path="/previewmode/onlinecheckindirect/:defaultThemeId" element={<OnlineCheckIn />} />

              </Route>
          </Routes>
      </div>
    )
// }
}
