import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module Email
 * @description Component for entering and validating a guest's email address.
 * This component includes an input field for email entry, validation logic,
 * and displays error messages for invalid email formats.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing email data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered email input field with validation.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Email component
 * <Email
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const Email = ({ handleGuestChange, guest, requiredFields, error, emailError, setEmailError, }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  // Check if the Email field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "Email" && field.IsMandatory
  );

  /**
   * @description Validates the provided email address using a regular expression.
   * This function checks if the email matches a valid format. It returns true
   * if the email is valid and false otherwise.
   *
   * @function validateEmail
   * @param {string} email - The email address to validate.
   * @returns {boolean} True if the email is valid, otherwise false.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  const validateEmail = (email) => {
    // Regex pattern para validação de e-mail
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  /**
   * @description Validates the email address on blur event.
   * This function is called when the email input field loses focus.
   * It checks if the email is valid and sets an appropriate error message.
   *
   * @function handleEmailValidation
   * @param {string} email - The email address entered by the user.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  const handleEmailValidation = (email) => {
    if (email && !validateEmail(email)) {
      setEmailError(`${t(`Email.error`)}`);
    } else {
      setEmailError(""); // Limpa o erro se o e-mail for válido
    }
  };

  return (
    <div>
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="email"
          className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
        >
          {`${t(`Email.email`)}`}
          <span className="dark:text-white ml-2">
            {isRequired ? `( * )\u00A0` : ``}
          </span>
        </label>
      )}
      <input
        name="Email"
        required={isRequired}
        onChange={(e) =>
          handleGuestChange(guest.id, e.target.name, e.target.value)
        }
        onBlur={() => handleEmailValidation(guest.data.Email)}
        value={guest.data.Email || ""}
        type="email"
        id="email"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Email.email`)} ${isRequired ? `( * )\u00A0` : ``}` : "" }
      />
      {emailError && (
        <div className="flex mt-1 items-center">
          <AlertTriangle className="text-red-500 mr-1" size={16} />
          <span className="text-red-500 text-sm">{emailError}</span>
        </div>
      )}
      {error && (
        <div className="flex mt-1 items-center">
          {" "}
          <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
          <span className="text-red-500 text-sm">{`${t(
            `HostForm.fieldRequired`
          )}`}</span>{" "}
        </div>
      )}
    </div>
  );
};
