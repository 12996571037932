import { AllRoutes } from "./routes/AllRoutes";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./CSS/OnlineCheckin/oc.css";
import "./App.css";
import "./font-awesome-4.7.0.css";
import { useCookies } from "react-cookie";
import { useEffect } from "react";


function App() {
  const [t] = useTranslation("global");
  const [cookies, setCookie] = useCookies(['initialLink']);
  const pathname = window.location.pathname;

  useEffect(() => {
    if (pathname.includes('/home')) {
      setCookie('initialLink', pathname, {path: '/'});
    }
  }, []);

  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
