import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { ChevronDown } from "lucide-react";
import { updateI18nLanguage } from "../../Settings/Language/ConfigureI18n";
import { useLocation } from "react-router-dom";

export const LanguageSelectorOC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(); // Portuguese by default
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [avaliableLanguages, setAvaliableLanguages] = useState();
  const [state,dispatch] = useContext(AppContext);
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/onlinecheckin");

  const gxpLanguages = [
    { code: "pt", name: "Português", cultureCode:"pt-PT" },
    { code: "gb", name: "English", cultureCode:"en-GB" },
    { code: "us", name: "English", cultureCode:"en-US" },
  ];

  const handleLanguageChange = (langCode) => {
    const language = avaliableLanguages.find((l) => l.cultureCode === langCode);
    if (language && language.cultureCode !== selectedLanguage?.cultureCode) {
      setSelectedLanguage(language);
      localStorage.setItem("userLanguage", langCode);
      dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: language });
      updateI18nLanguage(language.cultureCode); // Use a nova função em vez de i18n.changeLanguage
    }
  };

  useEffect(() => {
    // Efeito imediato para definir o idioma do localStorage
    const storedLanguage = localStorage.getItem("userLanguage");
    if (storedLanguage) {
      const initialLanguage = gxpLanguages.find(l => l.cultureCode === storedLanguage);
      if (initialLanguage) {
        setSelectedLanguage(initialLanguage);
        updateI18nLanguage(storedLanguage);
        dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: initialLanguage });
      }
    }
  }, []); // Executa apenas na montagem

  // Efeito para processar as línguas disponíveis inicialmente
  useEffect(() => {

      // Em modo preview, definir imediatamente os idiomas disponíveis
  if (isPreviewMode) {
    setAvaliableLanguages(gxpLanguages);

    // Se não houver idioma selecionado, selecionar o primeiro
    if (!selectedLanguage) {
      const defaultLang = gxpLanguages[0];
      setSelectedLanguage(defaultLang);
      localStorage.setItem("userLanguage", defaultLang.cultureCode);
      updateI18nLanguage(defaultLang.cultureCode);
    }
    return; // Sair do useEffect se estiver em modo preview
  }

    if (state.hotelInitialData?.Hotel?.LanguagesList) {
      const matchedLanguages = gxpLanguages
        .filter(
          (gxpLang) =>
            state.hotelInitialData.Hotel.LanguagesList[gxpLang.cultureCode]
        )
        .map((gxpLang) => ({
          ...gxpLang,
          ...state.hotelInitialData.Hotel.LanguagesList[gxpLang.cultureCode],
        }));

      if (matchedLanguages.length > 0) {
          setAvaliableLanguages(matchedLanguages);

        // Só define uma nova língua se não tivermos uma selecionada
        if (!selectedLanguage) {
          const storedLanguage = localStorage.getItem("userLanguage");

          if (storedLanguage) {
            const language = matchedLanguages.find(
              (l) => l.cultureCode === storedLanguage
            );
            if (language) {
              setSelectedLanguage(language);
              updateI18nLanguage(language.cultureCode);
            } else {
              // Fallback para a língua padrão do hotel ou en-US
              const defaultLang =
                matchedLanguages.find(
                  (l) =>
                    l.cultureCode === state.hotelInitialData.Hotel.DefaultLanguage
                ) ||
                matchedLanguages.find((l) => l.cultureCode === "en-US") ||
                matchedLanguages[0];

              setSelectedLanguage(defaultLang);
              localStorage.setItem("userLanguage", defaultLang.cultureCode);
              updateI18nLanguage(defaultLang.cultureCode);
            }
          } else if (state.userDetails?.CultureCode) {
            const language =
              matchedLanguages.find(
                (l) => l.cultureCode === state.userDetails.CultureCode
              ) || matchedLanguages[0];

            setSelectedLanguage(language);
            localStorage.setItem("userLanguage", language.cultureCode);
            updateI18nLanguage(language.cultureCode);
          } else {
            // Fallback final
            setSelectedLanguage(matchedLanguages[0]);
            localStorage.setItem("userLanguage", matchedLanguages[0].cultureCode);
            updateI18nLanguage(matchedLanguages[0].cultureCode);
          }
        }
      }
    }
  }, [state.hotelInitialData, state.userDetails, selectedLanguage]);

  return (
      avaliableLanguages && selectedLanguage && (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
          <div className="relative">
            <div
              onClick={() => setIsLanguageOpen(!isLanguageOpen)}
              className="py-2 px-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer flex items-center gap-2 min-w-[120px]"
            >
              <img
                src={`https://flagcdn.com/w20/${selectedLanguage?.code}.png`}
                alt={selectedLanguage?.name}
                className="w-5 h-3.5 object-cover shadow-sm"
              />
              <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                {selectedLanguage?.name}
              </span>
              <ChevronDown
                className={`w-4 h-4 ml-auto transition-transform duration-200 text-gray-500 dark:text-gray-400
                  ${isLanguageOpen ? "rotate-180" : ""}`}
              />
            </div>

            {isLanguageOpen && (
              <div className="absolute right-0 mt-1 w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-50">
                {avaliableLanguages.map((lang) => (
                  <div
                    key={lang.code}
                    onClick={() => {
                      handleLanguageChange(lang.cultureCode);
                      setIsLanguageOpen(false);
                    }}
                    className="px-3 py-2 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer flex items-center gap-2 transition-colors"
                  >
                    <img
                      src={`https://flagcdn.com/w20/${lang.code}.png`}
                      alt={lang.name}
                      className="w-5 h-3.5 object-cover shadow-sm"
                    />
                    <span className="text-sm text-gray-700 dark:text-gray-200">
                      {lang.name}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )
    );
  };