import { useState, useEffect, useCallback, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, Calendar, Clock, Save } from 'lucide-react';
import { RestaurantHeader } from './RestaurantHeader';
import { GuestStep } from './GuestStep';
import { DateStep } from './DateStep';
import { TimeStep } from './TimeStep';
import { SubmissionForm } from './SubmissionForm';
import { getOutletSchedules, saveTableReservation } from '../../../services';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useParams, Link, useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import { CheckCircle, ArrowBigLeftDash, ArrowBigRightIcon} from "lucide-react";
import { AppContext } from '../../../context/AppContext';

/**
 * @module ReservationComponent
 * @description Main component for managing the table reservation process.
 * Allows the user to select the number of guests, date, time, and submit the reservation.
 *
 * @param {Object} props - The component properties.
 * @param {Object} props.service - Details of the reservation service.
 * @param {number} props.daysInCalendar - Number of days available for reservation in the calendar.
 * @param {Array} props.mainDialCodes - Main dial codes for contact.
 *
 * @returns {JSX.Element} The reservation component.
 *
 * @example
 * <ReservationComponent service={service} daysInCalendar={7} mainDialCodes={dialCodes} />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const ReservationComponent = ({service, daysInCalendar, mainDialCodes, withoutReservation, initialData}) => {
  const [step, setStep] = useState(1); // Controls the current step of the reservation process
  const [title, setTitle] = useState(''); // Title of the reservation form
  const {key} = useParams(); // Retrieves the parameter from the URL
  const [people, setPeople] = useState(null); // Number of people for the reservation
  const [date, setDate] = useState(); // Selected date for the reservation
  const [fromDate, setFromDate] = useState(); // Start date for the reservation
  const [t] = useTranslation("global"); // Function for text translation
  const [toDate, setToDate] = useState(); // End date for the reservation
  const [time, setTime] = useState(); // Selected time for the reservation
  const [successSubmit, setSuccessSubmit] = useState(false);  // State to check if the reservation was successful
  const [cookie, setCookie] = useCookies(["currentHotel", "detailId", "cultureCode","guest","platformUid"]) // Hook for managing cookies
  const [formData, setFormData] = useState({
    FromDate: '',
    Pax: '',
    SectorId: service.Id,
    FirstName: `${!withoutReservation&&cookie.guest? cookie.guest.FirstName:''}`,
    LastName: `${!withoutReservation&&cookie.guest? cookie.guest.LastName:''}`,
    GuestEmail1: `${!withoutReservation&&cookie.guest ? cookie.guest.Emails[0] : ''}`,
    GuestPhone1: '',
    Notes: '',
    GuestLanguageCultureCode: cookie.cultureCode,
    GuestId: '',
    FolioId: '',
    RoomNumber: ''
  }); // Form data
  const [availabilityData, setAvailabilityData] = useState({}); // Availability data for time slots
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AppContext);

  const fadeVariants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.8,  // Duração mais lenta para o fade out
        ease: [0.42, 0, 0.58, 1]  // Easing suave
      }
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,  // Duração mais lenta para o fade in
        ease: [0.42, 0, 0.58, 1]  // Easing suave para entrada
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.8,  // Duração da saída (fade out)
        ease: [0.42, 0, 0.58, 1]  // Easing suave na saída
      }
    }
  };

  /**
 * @component DynamicHeader
 * @description A component that displays the current steps of the reservation process,
 * allowing the user to navigate between selecting guests, date, and time.
 *
 * @param {Object} props - The component properties.
 * @param {number} props.step - The current step of the reservation process.
 * @param {number} props.people - The number of guests for the reservation.
 * @param {Date} props.date - The selected date for the reservation.
 * @param {string} props.time - The selected time for the reservation.
 * @param {function} props.setStep - Function to update the current step of the reservation process.
 * @param {function} props.t - Translation function for localization.
 *
 * @returns {JSX.Element} The dynamic header component.
 *
 * @example
 * <DynamicHeader step={step} people={people} date={date} time={time} setStep={setStep} t={t} />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const DynamicHeader = () => (
    <div className="flex items-center mb-6 bg-white dark:bg-gray-400 dark:text-white rounded-lg shadow">
      <div onClick={()=>setStep(1)} className={`flex p-2 items-center cursor-pointer justify-center text-center w-1/3 space-x-2 border-2 dark:hover:text-black hover:bg-teal-100 rounded-l-lg hover:border-teal-600 dark:hover:border-teal-400 border-r-2 ${step===1? "border-2 border-teal-600 dark:border-teal-400 rounded-l-lg":""}`}>
        <Users size={20} />
        <span>{people} {`${t(`SubmissionForm.guests`)}`}</span>
      </div>
      <div onClick={()=>{if(people){setStep(2)}}} className={`flex p-2 items-center cursor-pointer justify-center w-1/3 border-2 hover:bg-teal-100 dark:hover:text-black hover:border-teal-600 dark:hover:border-teal-400 space-x-2 ${step===2? "border-2 border-teal-600 dark:border-teal-400":""}`}>
        <Calendar size={20} />
        <span>{date ? date.toLocaleDateString('pt-PT', { day: '2-digit', month: '2-digit' }):""}</span>
      </div>
      <div onClick={()=>{if(people&&date){setStep(3)}}} className={`flex p-2 items-center cursor-pointer justify-center w-1/3 border-2 dark:hover:text-black hover:bg-teal-100 rounded-r-lg hover:border-teal-600 dark:hover:border-teal-400 space-x-2 border-l-2 ${step===3? "border-2 border-teal-600 dark:border-teal-400 rounded-r-lg":""}`}>
        <Clock size={20} />
        <span>{time}</span>
      </div>
    </div>
  );

  /**
 * @function getEndTime
 * @description Calculates the end time for a reservation based on a given start time.
 * The end time is set to two hours after the start time.
 * The function expects the start time in 'HH:mm' format and returns the end time in 'HH:mm' format.
 *
 * @param {string} startTime - The starting time of the reservation in 'HH:mm' format.
 *
 * @returns {string} The calculated end time in 'HH:mm' format.
 *
 * @example
 * const endTime = getEndTime('19:00'); // returns '21:00'
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const getEndTime = (startTime) => {
    const [hours, minutes] = startTime.split(':').map(Number);
    const endDate = new Date(2000, 0, 1, hours + 2, minutes);
    return endDate.toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' });
  };

  /**
 * @function handleSubmit
 * @description Handles the form submission for the table reservation.
 * It prevents the default form submission behavior and calls the
 * `saveTableReservation` service with the current hotel and form data.
 * If the reservation is successful, it sets the success state to true;
 * otherwise, it displays an alert with an error message.
 *
 * @param {Event} e - The event object from the form submission.
 *
 * @returns {void}
 *
 * @example
 * <form onSubmit={handleSubmit}>
 *   ...
 * </form>
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const handleSubmit = (e) => {
    e.preventDefault();
    saveTableReservation(state.currentHotel, formData)
    .then((data) => {
      setSuccessSubmit(true);
    })
    .catch((error) => {
      alert(`${t(`HostFormComplete.formfail`)}`, error);
    });
  };

  /**
 * @function updateFormData
 * @description Updates the form data state with the selected date and time
 * for the reservation. It formats the date into 'YYYY-MM-DD' and combines
 * it with the selected time to create a new `FromDate` string. If the
 * new `FromDate` is different from the previous one, it updates the
 * form data state; otherwise, it returns the current state.
 *
 * @returns {void}
 *
 * @example
 * useEffect(() => {
 *   updateFormData();
 * }, [date, time]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const updateFormData = useCallback(() => {
    if (date && time) {
      const formattedDate = String(dayjs(date).format('YYYY-MM-DD'));
      const newFromDate = `${formattedDate} ${time}:00`;
      setFormData(prevFormData => {
        if (prevFormData.FromDate !== newFromDate) {
          return { ...prevFormData, FromDate: newFromDate };
        }
        return prevFormData;
      });
    }
  }, [date, time]);

  /**
 * @function useEffectUpdateFormData
 * @description This effect triggers the `updateFormData` function whenever
 * the `updateFormData` function reference changes. This ensures that the
 * form data state is updated with the latest selected date and time for the
 * reservation, reflecting any changes made by the user.
 *
 * @dependencies [updateFormData]
 *
 * @example
 * useEffect(() => {
 *   updateFormData();
 * }, [updateFormData]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  useEffect(() => {
    updateFormData();
  }, [updateFormData]);

  /**
 * @function handleDateChange
 * @description Updates the selected date state with the new date provided
 * as an argument. This function is typically called when the user selects
 * a date in the date picker component.
 *
 * @param {Date} newDate - The new date selected by the user.
 *
 * @example
 * handleDateChange(new Date('2024-10-10'));
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  /**
 * @function handleTimeChange
 * @description Updates the selected time state with the new time provided
 * as an argument. This function is typically called when the user selects
 * a time in the time picker component.
 *
 * @param {string} newTime - The new time selected by the user, formatted as "HH:mm".
 *
 * @example
 * handleTimeChange("14:30");
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  /**
 * @function useEffectSetFromAndToDates
 * @description Calculates and sets the `fromDate` and `toDate` states based on
 * the current date and the number of days available for reservation (`daysInCalendar`).
 * This effect runs whenever the `people` state changes.
 *
 * The `fromDate` is set to today's date in 'YYYY-MM-DD' format.
 * The `toDate` is calculated by adding `daysInCalendar` to today's date,
 * and is also formatted in 'YYYY-MM-DD'.
 *
 * @returns {void}
 *
 * @example
 * // When the `people` state is updated, this effect calculates and updates
 * // the `fromDate` and `toDate`.
 * useEffect(() => {
 *   let today = new Date();
 *   let formattedFromDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' format
 *   setFromDate(formattedFromDate);
 *
 *   // Calculate the toDate using the offset (daysInCalendar)
 *   let futureDate = new Date(today);
 *   futureDate.setDate(today.getDate() + daysInCalendar);
 *   let formattedToDate = futureDate.toISOString().split('T')[0]; // 'YYYY-MM-DD' format
 *   setToDate(formattedToDate);
 * }, [people]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  useEffect(()=>{
    let today = new Date();
    let formattedFromDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' format
    setFromDate(formattedFromDate);

    // Calculate the toDate using the offset (daysInCalendar)
    let futureDate = new Date(today);
    futureDate.setDate(today.getDate() + daysInCalendar);
    let formattedToDate = futureDate.toISOString().split('T')[0]; // 'YYYY-MM-DD' format
    setToDate(formattedToDate);
  },[people])

  /**
 * @function useEffectGetOutletSchedules
 * @description Fetches the outlet schedules for reservations based on the
 * selected number of guests (`people`), `fromDate`, and `toDate`. This effect
 * runs whenever the `people` state changes. If `people` is defined, it calls
 * the `getOutletSchedules` function to retrieve available time slots for the
 * specified dates and the current hotel.
 *
 * The response data is structured to group available times by date, and the
 * result is saved in the `availabilityData` state. If an error occurs during
 * the fetching process, an error message is logged and a toast notification
 * is displayed to inform the user.
 *
 * @returns {void}
 *
 * @example
 * useEffect(() => {
 *   if(people) {
 *     getOutletSchedules(key, state.currentHotel,
 *       dayjs(fromDate).format('YYYY-MM-DD'),
 *       dayjs(toDate).format('YYYY-MM-DD'), people)
 *       .then((data) => {
 *         const structuredData = data.schedules.reduce((acc, curr) => {
 *           const date = dayjs(curr.FromDate).format('YYYY-MM-DD');
 *           const time = dayjs(curr.FromDate).format('HH:mm');
 *           if (!acc[date]) {
 *             acc[date] = [];
 *           }
 *           acc[date].push(time);
 *           return acc;
 *         }, {});
 *         setAvailabilityData(structuredData);
 *       })
 *       .catch(error => {
 *         console.error("TableReservation: Error fetching service info:", error);
 *         toast.error("TableReservation: Unable to get service info!");
 *       });
 *   }
 * }, [people]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  useEffect(() => {
    if(people){
    getOutletSchedules(key, state.currentHotel,dayjs(fromDate).format('YYYY-MM-DD'),dayjs(toDate).format('YYYY-MM-DD'), people)
      .then((data) => {
        const structuredData = data.schedules.reduce((acc, curr) => {
          const date = dayjs(curr.FromDate).format('YYYY-MM-DD');
          const time = dayjs(curr.FromDate).format('HH:mm');
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(time);
          return acc;
        }, {});
        setAvailabilityData(structuredData);
      })
      .catch(error => {
        console.error("TableReservation: Error fetching service info:", error);
        toast.error("TableReservation: Unable to get service info!");
      });}
  }, [people]);

  if (successSubmit) {
    // Success component
     return (
    <div className="max-w-md mx-auto bg-white dark:bg-gxp_dark_3 dark:text-white px-8 py-6 mt-4 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center mb-6">{`${t(`ReservationComponent.reservationconfirmed`)}`}</h1>

      <div className="flex justify-center mb-6">
        <CheckCircle className="text-green-500 dark:text-green-300" size={64} />
      </div>

      <p className="text-center text-lg mb-6">
      {`${t(`ReservationComponent.reservationsubmited`)}`}
      </p>
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">{`${t(`ReservationComponent.reservationdetails`)}`}</h2>
        <p><strong>{`${t(`ReservationComponent.datehour`)}`}</strong>{` ${dayjs(date).format(t("dayjs_format_long"))} at ${time}`}</p>
        <p><strong>{`${t(`ReservationComponent.guestsnumber`)}`}</strong>{` ${formData.Pax}`}</p>
        <p><strong>{`${t(`ReservationComponent.name`)}`}</strong>{` ${title} ${formData.FirstName} ${formData.LastName}`}</p>
        <p><strong>{`${t(`Email.email`)}`}</strong>{` ${formData.GuestEmail1}`}</p>
        <p><strong>{`${t(`ReservationComponent.phone`)}`}</strong>{` ${formData.GuestPhone1}`}</p>
        <p><strong>{`${t(`ReservationComponent.additionalcomments`)}`}</strong>{` ${formData.Notes}`}</p>
        <p><strong>{`${t(`ReservationComponent.duration`)}`}</strong>{`${t(`ReservationComponent.yourtable`)} ${time} ${t(`ReservationComponent.to`)} ${getEndTime(time)}`}</p>
      </div>

      <div className="mt-8 text-center">
        {!withoutReservation &&
        <Link type='button' to={`/hotel/${cookie.platformUid}/${cookie.detailId}`} className="bg-teal-600 text-white px-6 py-2 rounded-md hover:bg-teal-700 transition duration-300">
        {`${t(`HostFormComplete.backhotel`)}`}
        </Link>}
        {withoutReservation &&
        <button type='button' onClick={() => navigate(-1)} className="bg-teal-600 text-white px-6 py-2 rounded-md hover:bg-teal-700 transition duration-300">
        {`${t(`ReservationComponent.returnprevious`)}`}
        </button>}
      </div>
    </div>
  );
  }

  return (
    <div className="max-w-md mx-auto p-2 m-1 ">
        <form onSubmit={handleSubmit}>
      <div className='bg-white dark:bg-gxp_dark_3 p-4 rounded-lg shadow'>
        <DynamicHeader />
        <AnimatePresence mode="wait">
          {step===1 && <GuestStep fadeVariants={fadeVariants} setStep={setStep} setPeople={setPeople} people={people} formData={formData} setFormData={setFormData} />}
          {step===2 && people && <DateStep handleDateChange={handleDateChange} daysInCalendar={daysInCalendar} fadeVariants={fadeVariants} setStep={setStep} date={date} availableDates={Object.keys(availabilityData)}  />}
          {step===3 && date && availabilityData && <TimeStep handleTimeChange={handleTimeChange} fadeVariants={fadeVariants} setStep={setStep} time={time} availableTimes={availabilityData[dayjs(date).format('YYYY-MM-DD')] || []} />}
          {step===4 && formData.FromDate && <SubmissionForm mainDialCodes={mainDialCodes} title={title} setTitle={setTitle} date={date} people={people} time={time} formData={formData} setFormData={setFormData} getEndTime={getEndTime} service={service} initialData={initialData} />}
        </AnimatePresence>
        <div className="mt-8 flex justify-between">
          <button type='button' onClick={() => setStep((prev) => Math.max(1, prev - 1))} className="px-4 py-2 bg-gray-200 rounded" disabled={step === 1} >
            <ArrowBigLeftDash />
          </button>
          {step < 4 ? (
            step===1 && people && (
              <button type='button' onClick={() => setStep((prev) => Math.min(4, prev + 1))} className="px-4 py-2 bg-teal-600 text-white rounded" >
              <ArrowBigRightIcon />
            </button>)
          ) : (
            <button type='submit' onSubmit={(e) => handleSubmit(e)} className="px-4 py-2 bg-teal-600 text-white rounded flex" >
              <Save className='mr-3 w-5'/> {`${t(`HostFormComplete.submit`)}`}
            </button>
          )}
        </div>
        </div>
      </form>
    </div>
  );
};

