/* import Api from "../data/api.js"; */
const Reducer = (state, action) => {

    switch (action.type) {

      case "SET_USER_DETAILS":
        return {
          ...state,
          userDetails: action.payload,
        };

        case "SET_SYSTEM_LANGUAGE":
        return {
          ...state,
          systemLanguage: action.payload,
        };

        case "SET_GXP_PARAMETERS":
          return {
            ...state,
            gxpParameters: action.payload,
          };

        case 'UPDATE_ACTIVE_THEME_STYLE':
          console.log('Reducer: UPDATE_ACTIVE_THEME_STYLE', action.payload);
          return {
            ...state,
            activeTheme: state.activeTheme.map(style =>
              style.themeStyleCode === action.payload.themeStyleCode &&
              (style.themeStyleCode.includes('is_active') || style.themeStyleCode.includes('_image'))
                ? {
                    ...style,
                    value: action.payload.value  // Só atualiza o value para is_active e image
                  }
                : style
            )
          };

      case "SET_USER_RESERVATION":
        return {
          ...state,
          reservation: action.payload,
        };

       case "SET_CURRENT_HOTEL":
        return {
          ...state,
          currentHotel: action.payload }

       case "SET_DEFAULT_THEME":
        return {
          ...state,
          defaultTheme: {
            ...state.defaultTheme,  // Mantém as cores já existentes
            ...action.payload       // Substitui apenas as cores do payload
       }}

       case "SET_ACTIVE_THEME":
        return {
          ...state,
          activeTheme: action.payload
       }

       case "SET_USER_LANGUAGE":
        return {
          ...state,
          userLanguage: action.payload
       }

       case "SET_HOTEL_INITIALDATA":
        return {
          ...state,
          hotelInitialData: action.payload,
       }

       case "SET_GLOBAL_REFRESH":
        return {
          ...state,
          globalRefresh: action.payload,
       }

       case "SET_GLOBAL_LOADING":
        return {
          ...state,
          globalLoading: action.payload,
       }

       case "SET_ADMIN_INITIALDATA":
        return {
          ...state,
          adminInitialData: action.payload,
       }

       case "SET_HOTEL_MENU":
        return {
          ...state,
          hotelMenu: action.payload,
       }

       case "SET_LOADING":
        return {
          ...state,
          globalLoading: action.payload,
       }

       case "SET_ADMIN_LOGGEDIN":
        return {
          ...state,
          adminLoggedIn: action.payload,
        };

      default:
        return state;
    }
  };

  export default Reducer;

