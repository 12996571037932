import { useContext, useState, useEffect, useMemo } from "react";
import { AppContext } from "../../context/AppContext";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { useParams, Link, useLocation } from "react-router-dom";
import restauranteexample from "../../assets/images/restauranteexample.jpg";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useTranslation } from "react-i18next";
import { getEMenuHeaders } from "../../services/eMenuService";
import { Loader } from "../../components/Layout/components/Loader/Loader";

/**
 * @module TableReservationMenu
 * @description This component displays a menu of available services for table reservations.
 * It retrieves the services based on the route parameters and manages the state for
 * storing service information. It also includes navigation components like Navbar,
 * Sidebar, and Footer for a complete user interface.
 *
 * @returns {JSX.Element} The table reservation menu component.
 *
 * @example
 * <TableReservationMenu />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const EMenuMenu = () => {
  const [state, dispatch] = useContext(AppContext);
  const { keys } = useParams();
  const [services, setServices] = useState([]);
  const [cookie, setCookie] = useCookies([ "sUid", "currentHotel", "detailId", "platformUid","cultureCode" ]); // eslint-disable-line
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(true)
  const [serverTime, setServerTime] = useState();

  const [showSchedules, setShowSchedules] = useState({});

  const toggleSchedule = (serviceId) => {
    setShowSchedules((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };
  const getDayName = useMemo(() => {
    const currentDay = new Date().getDay();
    const days = [
      `${t(`sunday`)}`,
      `${t(`monday`)}`,
      `${t(`tuesday`)}`,
      `${t(`wednesday`)}`,
      `${t(`thursday`)}`,
      `${t(`friday`)}`,
      `${t(`saturday`)}`,
    ];
    const reorderedDays = [
      ...days.slice(currentDay),
      ...days.slice(0, currentDay)
    ];

    return (day) => reorderedDays[day];
  }, [localStorage.getItem("userLanguage")])

  const formatTime = (timeString) => {
    return new Date(timeString).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Modified isRestaurantOpen function to use serverTime
  const isRestaurantOpen = (schedules) => {
    if (!serverTime) return false;

    const serverDate = new Date(serverTime);
    const currentDay = serverDate.getDay();
    const currentTime = serverDate.toLocaleTimeString(localStorage.getItem("userLanguage"), { hour12: false });

    return schedules.some((schedule) => {
      if (schedule.Day !== currentDay) return false;
      const fromTime = new Date(schedule.FromHour).toLocaleTimeString(localStorage.getItem("userLanguage"), { hour12: false, });
      const toTime = new Date(schedule.ToHour).toLocaleTimeString(localStorage.getItem("userLanguage"), { hour12: false, });
      return currentTime >= fromTime && currentTime <= toTime;
    });
  };

  const getCurrentDaySchedule = (schedules) => {
    const currentDay = new Date().getDay();
    return schedules
      .filter((schedule) => schedule.Day === currentDay)
      .map( (schedule) => `${formatTime(schedule.FromHour)} - ${formatTime(schedule.ToHour)}` )
      .join(", ");
  };

  const groupSchedulesByDay = (schedules) => {
    const grouped = {};
    schedules.forEach((schedule) => {
      if (!grouped[schedule.Day]) {
        grouped[schedule.Day] = [];
      }
      grouped[schedule.Day].push(
        `${formatTime(schedule.FromHour)} - ${formatTime(schedule.ToHour)}`
      );
    });
    return grouped;
  };

  const sortDays = (groupedSchedules) => {
    const currentDay = new Date().getDay();
    const daysOrder = [0, 1, 2, 3, 4, 5, 6]; // Sunday to Saturday

    // Reorder days starting from the current day
    const reorderedDays = [
      ...daysOrder.slice(currentDay),
      ...daysOrder.slice(0, currentDay),
    ];

    // Create a new object with the reordered days
    const sortedSchedules = {};
    reorderedDays.forEach((day) => {
      const dayKey = day.toString(); // Convert to string to match groupedSchedules keys
      if (groupedSchedules.hasOwnProperty(dayKey)) {
        sortedSchedules[dayKey] = groupedSchedules[dayKey];
      }
    });

    return sortedSchedules;
  };

  const loadEmenuHeaders = async () => {
    if (keys && state.currentHotel&& cookie.cultureCode) {
    try {
      setIsLoading(true)
      const headers = await getEMenuHeaders( keys, cookie.cultureCode, state.currentHotel );
      setServerTime(headers.ServerTime)
      setServices(headers.Data);
    } catch (error) {
      console.error("Error loading EMenu Headers:", error);
      toast.error(t("OnlineCheckIn.errorhost"));
    } finally {
      setIsLoading(false)
    }
  }
  };

  console.log(services)

    // Function to update server time
    const updateServerTime = (initialTime) => {
      const currentServerTime = new Date(initialTime);
      currentServerTime.setMinutes(currentServerTime.getMinutes() + 1);
      setServerTime(currentServerTime.toISOString());
    };

  /**
   * @function useEffectSetService
   * @description This effect runs when the component mounts and retrieves outlet information
   * based on the `keys` parameter from the URL. It fetches the services available for table
   * reservations using the `getOutlet` service function and updates the local state with
   * the fetched service data. In case of an error, it logs the error to the console and
   * displays a toast notification to the user.
   *
   * @dependencies {string} keys - The keys extracted from the URL parameters that are
   * required to fetch the outlet information.
   * @dependencies {object} state.currentHotel - The current hotel identifier from cookies
   * used to specify the hotel context for the outlet fetch request.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    loadEmenuHeaders()
  }, [keys, state.currentHotel, localStorage.getItem("userLanguage")]);

    // Effect to update server time every minute
    useEffect(() => {
      if (!serverTime) return;

      const interval = setInterval(() => {
        updateServerTime(serverTime);
      }, 60000); // Update every minute

      return () => clearInterval(interval);
    }, [serverTime]);

    if (isLoading) {
      return <div className="flex justify-center h-screen dark:text-white dark:bg-gxp_dark_2"><Loader className={"w-20 h-20 mt-20"}/></div>;
    }

    return (
      <>
        {services && cookie.platformUid && cookie.detailId && cookie.sUid && state.currentHotel.subscriptionKey && (
            <div className={`${ state.darkMode ? "dark" : "" } antialiased dark:text-white dark:bg-gxp_dark_2 flex w-full justify-center`}  >
                <section className="p-1 flex flex-col mx-auto sm:w-1/2">
                  {isLoading? (<Loader />) :(
                   services.map((service) => {
                    const isOpen = isRestaurantOpen(service.Schedules);
                    const currentDaySchedule = getCurrentDaySchedule( service.Schedules );
                    const groupedSchedules = groupSchedulesByDay( service.Schedules );
                    const sortedGroupedSchedules = sortDays(groupedSchedules);

                    return (
                      <div key={service.Id} className="mt-2 mb-2 bg-white dark:bg-gxp_dark_2 rounded-lg overflow-hidden shadow-md" >
                        <Link to={`/hotel/${cookie.platformUid}/${cookie.detailId}/emenu/${cookie.sUid}/${service.Id}`} className="relative block" >
                          <div className="w-full h-48 bg-cover bg-center" style={{ backgroundImage: `url(${ service.ImageUrl || restauranteexample })`, }} alt={service.Name} />
                          <div className="absolute bottom-0 left-0 w-full bg-white dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 p-3">
                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
                              {service.Description}
                            </h2>
                          </div>
                        </Link>

                        {service.Schedules.length > 0 && (
                        <div className="p-2 dark:bg-gxp_dark_3 dark:text-white">
                          <div className="flex items-center justify-between">
                            <div className="flex justify-start">
                              <span className="font-bold max-sm:text-sm">
                                {getDayName(0)}
                              </span>
                              <span className=" max-sm:text-sm ml-3">
                                {currentDaySchedule}
                              </span>
                            </div>
                            <div className="flex items-center justify-end">
                              <div className="flex items-center  rounded-lg px-2 ">
                                <div className={`w-4 h-4 rounded-full ${ isOpen ? "bg-green-500" : "bg-red-500" } mr-2`} ></div>
                                  <span className={`text-sm ${ isOpen ? "text-green-500" : "text-red-500" }`} >
                                    {/* {isOpen ? `${t(`open`)}` : `${t(`closed`)}`} */}
                                  </span>
                                </div>
                              <div />
                              <button onClick={() => toggleSchedule(service.Id)} className="flex items-center text-right hover:text-gray-900 dark:hover:text-white transition-colors duration-200" >
                                {showSchedules[service.Id] ? (
                                  <ChevronUp size={20} />
                                ) : (
                                  <ChevronDown size={20} />
                                )}
                              </button>
                            </div>
                          </div>

                          {service.Schedules && service.Schedules.length > 0 && (
                              <div className="mt-2">
                                <div className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${ showSchedules[service.Id] ? "max-h-96" : "max-h-0" }`} >
                                {Object.entries(sortedGroupedSchedules).map(
                              ([day, times], index) => (
                                <div key={day} className={`py-1 text-sm dark:text-white ${index === 0 ? "font-bold" : ""}`} >
                                  <span className="font-medium">
                                    {getDayName(index)}:
                                  </span>{" "}
                                  {times.join(", ")}
                                </div>
                              )
                            )}
                                </div>
                              </div>
                            )}
                        </div>
                        )}
                      </div>
                    );
                  }))}
                </section>
            </div>
          )}
      </>
    );
};
