import { Search, X, Check, AlertTriangle, RefreshCw } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { AppContext } from "../../context/AppContext";
import { Phone } from "./FormFields/Phone";
import { useParams } from "react-router-dom";
import { DateTimePicker } from "./FormFields/DateTimePicker";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { saveWellnessTreatmentRequest } from "../../services";

export const WellnessFormModal = ({ isOpen, onClose, item, service }) => {
  const [t] = useTranslation("global");
  const { key} = useParams();
  const [successSubmit, setSuccessSubmit] = useState(false); // State to check if the reservation was successful
  const [state, dispatch] = useContext(AppContext);
  const [initialData, setInitialData] = useState(state.hotelInitialData);
  const [cookie, setCookie] = useCookies(["platformUid", "currentHotel", "GUid", "reservation", "detailId"]); // eslint-disable-line
  const [requiredFields, setRequiredFields] = useState(["FirstName", "LastName", "Email", "Phone", "StartDate"])
  const [fieldErrors, setFieldErrors] = useState({});
  const [emailError, setEmailError] = useState(""); // State to track the error message
  const [isSending, setIsSending] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false)
  const [postError, setPostError] = useState(false)
  const [reservationId, setReservationId] = useState(null)
  const [formData, setFormData] = useState({
    CultureCode: localStorage.getItem("userLanguage"),
    DetailId: 0,
    Email: `${cookie.guest ? cookie.guest.Emails[0] : "" }`,
    FirstName: `${cookie.guest ? cookie.guest.FirstName : "" }`,
    ItemId: item.ItemId,
    Key: "",
    LastName: `${cookie.guest ? cookie.guest.LastName : "" }`,
    MenuId: key,
    Notes: "",
    Phone: `${cookie.guest ? cookie.guest.Phones[0] : "" }`,
    Quantity: 1,
    StartDate: "",
  });
  const [selections, setSelections] = useState({
    date: undefined,
    time: undefined
});

  //////////// EMAIL ////////////
  const isRequiredEmail = requiredFields.some(
    field => field === "Email");

  const validateEmail = (email) => {
    // Regex pattern para validação de e-mail
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailValidation = (email) => {
    if (email && !validateEmail(email)) {
      setEmailError(`${t(`Email.error`)}`);
    } else {
      setEmailError(""); // Limpa o erro se o e-mail for válido
    }
  };

  ///////////////////////////////

  const postWellnessTreatmentRequest = async () => {
      try {
        setIsSending(true);
        const details = await saveWellnessTreatmentRequest(state.currentHotel, formData);
        return details
      } catch (error) {
        console.error("Error saving Wellness Treatment Request:", error);
      } finally {
        setIsSending(false);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const allFieldsFilled = validateRequiredFields(e)

    if(allFieldsFilled) {
      const result = await postWellnessTreatmentRequest()
      if(result.success){
        setReservationId(result.data.NewContractNumber)
        setPostSuccess(true)
        setFormData({...formData, StartDate:"", Notes:""})
      } else {
        setPostError(true)
      }
    }
  };

  const handleFormDataChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
    // Remove the error for the field being modified
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[fieldName];
      return newErrors;
    });  };

  const validateRequiredFields = () => {
    const errors = {};
    let allFieldsFilled = true;

    requiredFields.forEach((field) => {
      if (field === "StartDate") {
          // Validação especial para StartDate
          if (!selections.date || selections.date === "") {
              errors["Date"] = `${t("HostForm.fieldRequired")}`;
              allFieldsFilled = false;
          }
          if (!selections.time || selections.time === "") {
              errors["Time"] = `${t("HostForm.fieldRequired")}`;
              allFieldsFilled = false;
          }
      } else {
          // Validação normal para os outros campos
          const fieldValue = formData[field];
          if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
              errors[field] = `${t("HostForm.fieldRequired")}`;
              allFieldsFilled = false;
          }
      }
    });

    if (emailError !== "") {
      errors["Email"] = `${t(`Email.error`)}`;
      allFieldsFilled = false;
    }
    setFieldErrors(errors);
    return allFieldsFilled;
  };

  const resetStates = () => {
    setPostSuccess(false)
    setPostError(false)
    setReservationId(null)
  }

  useEffect(() => {
    if(state.hotelInitialData) {
      setInitialData(state.hotelInitialData)
    }
  }, [state.hotelInitialData])

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9999] flex items-center justify-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
        <motion.div className="bg-white dark:bg-gxp_dark_3 rounded-lg shadow-lg p-4 max-w-md w-full mx-4" initial={{ scale: 0.9, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.9, opacity: 0 }} >
          <div className="flex items-center justify-between">
            <h2 className="text-xl dark:text-white">{`${t( `WellnessFormModal.details` )}`}</h2>
            <button type="button" onClick={()=>{resetStates(); onClose()}} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white" >
              <X size={20} />
            </button>
          </div>
          {postSuccess && (
            <div className="flex flex-col items-center justify-center px-6 pt-6 pb-2">
              <div className="mb-4 rounded-full bg-green-100 p-3">
                <Check size={32} className="text-green-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-2">
              {`${t(`WellnessFormModal.reservationSuccess`)}`}
              </h3>
              <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2 border px-2 rounded-lg">
              {`${t(`WellnessFormModal.reservationNumber`)}`}
              {reservationId}
              </h4>
              <p className="text-gray-600 dark:text-gray-300 text-center mb-4">
              {`${t(`WellnessFormModal.reservationContact`)}`}
              </p>
              <button onClick={()=>{resetStates(); onClose()}} className="flex items-center px-4 py-2 bg-blue-600 text-white font-medium rounded-lg shadow-md transition duration-150 ease-in-out" >
                <X size={20} className="mr-2" />
                {`${t(`WellnessFormModal.close`)}`}
              </button>
            </div>
          )}

          {postError && (
            <div className="flex flex-col items-center justify-center px-6 pt-6 pb-2">
              <div className="mb-4 rounded-full bg-red-100 p-3">
                <AlertTriangle size={32} className="text-red-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-2">
              {`${t(`WellnessFormModal.error`)}`}
              </h3>
              <p className="text-gray-600 dark:text-gray-300 text-center mb-4">
              {`${t(`WellnessFormModal.errorMessage`)}`}
              </p>
              <button onClick={()=>{resetStates(); onClose()}} className="flex items-center px-4 py-2 bg-blue-600 text-white font-medium rounded-lg shadow-md transition duration-150 ease-in-out" >
                <RefreshCw size={20} className="mr-2" />
                {`${t(`WellnessFormModal.tryagain`)}`}
              </button>
            </div>
          )}
          {!postSuccess && !postError && (
          <form onSubmit={handleSubmit}>
            <DateTimePicker selections={selections} setSelections={setSelections} setFieldErrors={setFieldErrors} fieldErrors={fieldErrors} schedules={service.Schedules} scheduleExceptions={service.SchedulesExceptions} itemData={item} formData={formData} setFormData={setFormData} />
            <div className="max-w-full">
              <div className="max-w-full grid grid-cols-2 gap-2">
                <div className="w-full">
                  <label htmlFor="firstName" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >{`${t(`FirstName.firstname`)}`}</label>
                  <input name="FirstName" id="firstName" type="text" value={formData.FirstName} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)} className={`max-w-full p-2 border rounded max-sm:w-full ${fieldErrors.FirstName ? 'border-red-500' : 'border-gray-700 dark:border-white'} dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />

                </div>
                <div className="w-full">
                  <label htmlFor="lastName" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >{`${t(`LastName.lastname`)}`}</label>
                  <input name="LastName" id="lastName" type="text" value={formData.LastName} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)} className={`max-w-full p-2 border rounded max-sm:w-full ${fieldErrors.LastName ? 'border-red-500' : 'border-gray-700 dark:border-white'} dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
                </div>
              </div>
                  {(fieldErrors.FirstName || fieldErrors.LastName) && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
              <div>
                <label htmlFor="email" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >{`${t(`Email.email`)}`}</label>
                <input name="Email" type="email" onBlur={(()=>handleEmailValidation(formData.Email))} value={formData.Email} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)} className="w-full p-2 border rounded max-sm:w-full border-gray-700 dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
                {emailError && <div className="flex mt-1 items-center"><AlertTriangle className="text-red-500 mr-1" size={16} /><span className="text-red-500 text-sm">{emailError}</span></div> }{fieldErrors.Email && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
              </div>
              <Phone setFieldErrors={setFieldErrors} fieldErrors={fieldErrors} mainDialCodes={initialData.mainDialCodes} formData={formData} setFormData={setFormData} initialData={initialData} required />
              <div>
                <label htmlFor="email" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >
                {`${t(`WellnessFormModal.observations`)}`}
                  <span className="text-gray-400 dark:text-white ml-2">{`${t( `WellnessFormModal.optional` )}`}</span>
                </label>
                <textarea
                  value={formData.Notes}
                  onChange={(e) =>
                    setFormData({ ...formData, Notes: e.target.value })
                  }
                  className="w-full p-2 border rounded h-24 resize-none max-sm:w-full border-gray-700 dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out"
                />
              </div>
            </div>

          <div className="flex justify-center">
          {isSending ? (
                <button type="button" className={`w-auto px-6 py-3 mt-2 flex items-center justify-center bg-blue-600 text-white font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                  <Loader />
                </button>
              ) : (
              <button type="submit" className={`flex w-auto px-4 py-3 mt-2 items-center justify-center bg-blue-600 text-white font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                <Check size={20} /><span className="ml-2">{`${t(`SignaturePadComp.confirm`)}`}</span>
              </button>)}
          </div>
          </form>)}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
