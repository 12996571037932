import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { HostFormComplete } from "./components/HostFormComplete";
import { toast } from "react-toastify";
import { getHostProfile, getHotelPmsIdPrefix, searchReservation } from "../../services";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { AppContext } from "../../context/AppContext";
import { updateI18nLanguage } from "../Settings/Language/ConfigureI18n";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { previewCurrentHotel, previewGroupHotels, previewReservation } from "./components/Utilities/previewModeData";

export const OnlineCheckIn = () => {
  const { platformUid, detailId, resNo, sUid } = useParams();
  const [state,dispatch] = useContext(AppContext);
  const [cookies, setCookie] = useCookies([ "cultureCode", "GUid", "currentHotel", "sUid", "profileKey", "guest", "isProfileLoaded", ]);
  const [data, setData] = useState({ groupHotels: null, isLoading: true, isInitialized: false, profileLoaded: false, reservationLoaded: false });
  const [reservation, setReservation] = useState(null)
  const [t] = useTranslation("global");
  const { pathname } = useLocation();
  const showMenus = !pathname.includes("/checkindirect");
  const isPreviewMode = pathname.includes("/previewmode/onlinecheckin");
  const [guestLanguage, setGuestLanguage]= useState(localStorage.getItem(`userLanguage`) || null)

  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hover",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'Hover on specific hoverable element'
      }, "*");
    }
  };

  const handleMouseLeave = (e, elementRef) => {

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hoverOut",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'HoverOut from specific hoverable element'
      }, "*");
    }
  };


  const loadHotels = async () => {
    if (!platformUid || !pathname.includes("checkindirect")) return;

    try {
      const hotelsData = await getHotelPmsIdPrefix(platformUid);
      setData(prev => ({ ...prev, groupHotels: hotelsData }));

      // Encontra e define o hotel principal
      for (const hotel of hotelsData) {
        const pmsIdPrefix = hotel.hotelId.slice(0, 8);
        if (pmsIdPrefix === platformUid) {
          setCookie("currentHotel", hotel, { path: "/" });
          dispatch({ type: "SET_CURRENT_HOTEL", payload: hotel });
          break;
        }
      }
      // Define sUid nos cookies se existir
      if (sUid) {
        setCookie("sUid", sUid, { path: "/" });
      }
    } catch (error) {
      console.error("Error loading hotels:", error);
      toast.error("Unable to get hotels!");
    }
  };

  const loadProfile = async () => {
    if (!state.currentHotel?.subscriptionKey || !sUid || data.profileLoaded) return;

    try {
      const profileResult = await getHostProfile( sUid, cookies.profileKey, state.currentHotel.subscriptionKey );

      setCookie("profileKey", profileResult.ProfileKey, { path: "/" });
      setCookie("GUid", profileResult.Uid, { path: "/" });
      setCookie("guest", JSON.stringify(profileResult), { path: "/" });
      setCookie("isProfileLoaded", true, { path: "/" });

      setData(prev => ({ ...prev, profileLoaded: true }));
    } catch (error) {
      console.error("Error loading profile:", error);
      toast.error(t("OnlineCheckIn.errorhost"));
    }
  };

  const loadReservation = async () => {
    if (!state.currentHotel || !cookies.GUid || !detailId || !resNo || data.reservationLoaded) return;

    try {
        const reservationResponse = await searchReservation( resNo, detailId, state.currentHotel, cookies.GUid, guestLanguage );
        setReservation(reservationResponse.Data?.Details[0])
        setData(prev => ({
          ...prev,
          reservationLoaded: true,
          isLoading: false
        }));
    } catch (error) {
      console.error("Error loading reservation:", error);
      toast.error(t("OnlineCheckIn.errorreservation"));
      setData(prev => ({ ...prev, isLoading: false }));
    } finally {
      setData(prev => ({
      ...prev,
      reservationLoaded: true,
      isLoading: false
    }));

    }
  };

  // Efeito para carregar hotéis - só executa uma vez no início
  useEffect(() => {
    if(isPreviewMode){
      setData(prev => ({ ...prev, groupHotels: previewGroupHotels }));
      setCookie("currentHotel", previewCurrentHotel, { path: "/" });
      dispatch({ type: "SET_CURRENT_HOTEL", payload: previewCurrentHotel });
      return
    }

    if(state.currentHotel?.hotelId?.substring(0,8)!==platformUid){
      loadHotels();
    }
  }, [platformUid]); // eslint-disable-line

  // Efeito para carregar perfil - depende dos cookies necessários
  useEffect(() => {
    if(isPreviewMode){
      setData(prev => ({ ...prev, profileLoaded: true }));
      return
    }
    if (state.currentHotel?.subscriptionKey && sUid && !data.profileLoaded) {
      loadProfile();
    }
  }, [state.currentHotel?.subscriptionKey, sUid]); // eslint-disable-line

  // Efeito para carregar reserva - só executa quando o perfil estiver carregado
  useEffect(() => {
    if(isPreviewMode){
      if(!reservation){
        setReservation(previewReservation)
      }
      setData(prev => ({
        ...prev,
        reservationLoaded: true,
        isLoading: false
      }));
      return
    }
    if (state.currentHotel && data.profileLoaded && !data.reservationLoaded && !reservation) {
      loadReservation();
    }
  }, [data.profileLoaded, state.currentHotel, guestLanguage, detailId, resNo]); // eslint-disable-line

  useEffect(() => {
    updateI18nLanguage(guestLanguage); // Use a nova função em vez de i18n.changeLanguage
  }, [])

  useEffect(() => {
    setGuestLanguage(localStorage.getItem('userLanguage'))
  }, [state.userLanguage])


 // Utilizado para o previewMode
  useEffect(() => {
    // Registrar a função de atualização globalmente
    window.__THEME_UPDATE__ = (themeStyleCode, value) => {
      console.log('Theme update function called:', { themeStyleCode, value });
      dispatch({
        type: 'UPDATE_ACTIVE_THEME_STYLE',
        payload: { themeStyleCode, value }
      });
    };

    return () => {
      delete window.__THEME_UPDATE__;
    };
  }, [dispatch]);

  return (
    (data.isLoading && !data.groupHotels && !data.reservationLoaded ? (
      <div className="flex justify-center items-center h-full w-full">
        <Loader className="h-20 w-20" />
      </div>) : (
      <AnimatePresence mode="wait">
      {state.currentHotel && guestLanguage && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{
            type: "tween",
            ease: "easeOut",
            duration: 0.3
          }}
          className={`antialiased dark:gxp_dark_2`}
        >
          <section className={`flex-grow h-full w-full`}>
            <HostFormComplete
              data={data}
              showMenus={showMenus}
              hotel={state.currentHotel}
              reservation={reservation}
              setReservation={setReservation}
              cultureCode={guestLanguage}
              isPreviewMode={isPreviewMode}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          </section>
        </motion.div>
      )}
    </AnimatePresence>
    )
    )
  );
};