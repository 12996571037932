import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import Logo from "../../../assets/logos/Host_GXP-Dashboard-Black.png";
import Logo_dark from "../../../assets/logos/Host_GXP-Dashboard.png";
import { AppContext } from "../../../context/AppContext";
import { loginUser } from "../../../services";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { Shield } from "lucide-react";

export const Login = () => {
  const [state] = useContext(AppContext);
  const navigate = useNavigate();
  const email = useRef();
  const password = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false
  });

  const [rememberMe, setRememberMe] = useState(false);

// Na função handleChange, adicione:
const handleRememberMe = (e) => {
  setRememberMe(e.target.checked);
};

const handleChange = (e) => {
  const { name, value, type, checked } = e.target;

  setFormData(prev => ({
    ...prev,
    [name]: type === 'checkbox' ? checked : value
  }));
};

  const Login = async (event) => {
        event.preventDefault()
        setIsLoading(true);

        const authDetail = {
            email: formData.email,
            password: formData.password,
            rememberMe: rememberMe
        };

        try {
            await loginUser(authDetail);

            // Se remember me estiver marcado, salva o email
            if (rememberMe) {
              localStorage.setItem('rememberedEmail', formData.email);
          } else {
              localStorage.removeItem('rememberedEmail');
          }

            navigate('/admin/dashboard');
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
  }

  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
        setFormData(prev => ({
            ...prev,
            email: rememberedEmail
        }));
        setRememberMe(true);
    }
}, []);

  return (
    <main className="flex flex-col justify-center items-center bg-gradient-to-b from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900 max-sm:h-screen overscroll-y-none">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

        <div className="flex items-center mb-6 gap-2">
          <img
            className="w-20"
            src={state.darkMode ? Logo_dark : Logo}
            alt="GXP Logo"
          />
        </div>
        <div className="w-full bg-white rounded-lg shadow-2xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="flex items-center gap-2 border-b pb-4 dark:border-gray-700">
              <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                Administrator Login
              </h1>
            </div>

            <form onSubmit={Login} className="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  ref={email}
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="admin@company.com"
                  required=""
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  ref={password}
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                />
              </div>
              {error && (
                <div className="p-2 bg-red-100 border border-red-400 text-red-700 text-sm rounded">
                  {error}
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      checked={rememberMe}
                      onChange={handleRememberMe}
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <Link
                  to=""
                  className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-400"
                >
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {isLoading ? <Loader /> : "Access Admin Panel"}
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Need an admin account?{" "}
                <Link
                  to="/admin/register"
                  className="font-medium text-blue-600 hover:underline dark:text-blue-400"
                >
                  Request access
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};