import { getAdminInitialData } from "../../../services";

export const refreshData = async ({setIsSending, dispatch}) => {

        try {
            setIsSending(true);
          const response = await getAdminInitialData()

          dispatch({ type: "SET_ADMIN_INITIALDATA", payload: response});

        } catch (err) {

        } finally {
            setIsSending(false);
        }
}
