import { Check, Edit, Plus, Search, Trash2, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContext";
import { HotelModal } from "../Components/Modal/HotelModal";
import { useTranslation } from "react-i18next";

// HotelList Component
export const HotelList = () => {
    const [isHotelModalOpen, setIsHotelModalOpen] = useState(false);
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [state, dispatch] = useContext(AppContext);
    const [hotels, setHotels] = useState([]);
    const [type, setType] = useState("");
    const [hotel, setHotel] = useState(null);
    const [t] = useTranslation("global");

    const handleAddHotel = (newHotel) => {
      setHotels([...hotels, newHotel]);
    };

    useEffect(() => {
      setHotels(state.adminInitialData?.hotels)
    }, [state.adminInitialData])

    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">{`${t(`Admin.AdminPanel.hotelsList`)}`}</h2>
          <div className="flex space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder={`${t(`Admin.hotelList.searchHotel`)}`}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            </div>
            <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700" onClick={() => {setType("add");setModalTitle(`${t(`Admin.hotelList.newHotel`)}`); setIsHotelModalOpen(true)}} >
              <Plus className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.hotelList.platformId`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.hotelList.mainName`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.hotelList.secondaryName`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.hotelList.groupId`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.hotelList.subscriptionKey`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.hotelList.active`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.hotelList.actions`)}`}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {hotels?.length > 0 ?
              hotels.map((hotel, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="pl-4 py-4 whitespace-nowrap text-sm text-gray-900">{hotel.hotelId}</td>
                  <td className="pl-4 py-4 whitespace-nowrap text-sm text-gray-900">{hotel.name1}</td>
                  <td className="pl-4 py-4 whitespace-nowrap text-sm text-gray-500">{hotel.name2}</td>
                  <td className="pl-4 py-4 whitespace-nowrap text-sm text-gray-500">{hotel.groupId}</td>
                  <td className="pl-4 py-4 whitespace-nowrap text-sm text-gray-500">{hotel.subscriptionKey}</td>
                  <td className="pl-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {<div className="w-8 h-8 rounded-md flex items-center justify-center bg-white border-2 border-gray-200" >
                        {hotel.isActive ? (
                          <Check className="w-5 h-5 text-green-500" />
                        ) : (
                          <X className="w-5 h-5 text-red-500" />
                        )}
                    </div>}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex space-x-3">
                      <button onClick={() => {setModalTitle(`${t(`Admin.hotelList.editHotel`)}`); setIsHotelModalOpen(true); setType("edit"); setHotel(hotel)}} className="text-blue-600 hover:text-blue-900">
                        <Edit className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{`${t(`Admin.hotelList.notfound`)}`}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
              </tr>}
            </tbody>
          </table>
        </div>

        {type &&
           <HotelModal isGroupModalOpen={isGroupModalOpen} setIsGroupModalOpen={setIsGroupModalOpen} isHotelModalOpen={isHotelModalOpen} onCloseHotelModal={() => setIsHotelModalOpen(false)} title={modalTitle} type={type} hotel={type==="add" ? null : hotel} />
        }
      </div>
    );
  };
