import { useLocation, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { getUser } from "../services"
import { Loader } from "../components/Layout/components/Loader/Loader"
import { AppContext } from "../context/AppContext"

export const ProtectedRoute = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const location = useLocation()
    const [state, dispatch] = useContext(AppContext)
    const navigate = useNavigate()

    useEffect(() => {
        const verifyAuth = async () => {
            try {
                const user = await getUser()

                if(user) {
                    setIsAuthenticated(true)
                    dispatch({type:"SET_ADMIN_LOGGEDIN", payload:user})

                    // Verifica o status do usuário e redireciona se necessário
                    if(user.status === "Pending") {
                        if(location.pathname !== "/admin/confirmregister") {
                            navigate("/admin/confirmregister", { replace: true })
                        }
                    } else if(location.pathname === "/admin/confirmregister") {
                        // Se não está pendente mas está na página de confirmação
                        navigate("/admin/dashboard", { replace: true })
                    }
                } else {
                    setIsAuthenticated(false)
                    if(location.pathname !== "/admin/login") {
                        navigate("/admin/login", { replace: true })
                    }
                }
            } catch (error) {
                console.error('Auth error:', error)
                setIsAuthenticated(false)
                if(location.pathname !== "/admin/login") {
                    navigate("/admin/login", { replace: true })
                }
            } finally {
                setIsLoading(false)
            }
        }

        verifyAuth()
    }, [location.pathname, dispatch, navigate])

    if (isLoading) {
        return <div className="flex justify-center items-center h-screen"><Loader /></div>
    }

    // Páginas públicas
    if (location.pathname === "/admin/login" || location.pathname === "/admin/register") {
        return children
    }

    // Para todas as outras rotas, verifica autenticação
    return isAuthenticated ? children : null
}