import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { updateI18nLanguage } from "../../../pages/Settings/Language/ConfigureI18n";
import { ChevronDown, Moon, Sun, UserRoundCog } from "lucide-react";
import packageJson from '../../../../package.json';

// Main Content Header
export const Header = ({ title, subtitle }) => {
  const [darkMode, setDarkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false );
  const [selectedLanguage, setSelectedLanguage] = useState(); // Portuguese by default
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [avaliableLanguages, setAvaliableLanguages] = useState();

  const [state, dispatch] = useContext(AppContext);
  const dropdownRef = useRef(null);
  const languageSelectRef = useRef(null);
  const { pathname } = useLocation();
  const [t] = useTranslation("global");

  const gxpLanguages = [
    { code: "pt", name: "Português", cultureCode:"pt-PT" },
    { code: "gb", name: "English", cultureCode:"en-GB" },
    { code: "us", name: "English", cultureCode:"en-US" },
  ];

  const handleLanguageChange = (langCode) => {
    const language = gxpLanguages.find((l) => l.cultureCode === langCode);
    if (language && language.cultureCode !== selectedLanguage?.cultureCode) {
      setSelectedLanguage(language);
      localStorage.setItem("userLanguage", langCode);
      dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: language });
      updateI18nLanguage(language.cultureCode); // Use a nova função em vez de i18n.changeLanguage
    }
  };

  const handleDarkModeToggle = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", JSON.stringify(newDarkMode));
    // Aqui você pode adicionar lógica adicional para aplicar o tema escuro
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && languageSelectRef.current && !languageSelectRef.current.contains(event.target) ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    localStorage.setItem(`darkMode`, JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add(`dark`);
    } else {
      document.documentElement.classList.remove(`dark`);
    }
  }, [darkMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsLanguageOpen(false);
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    // Efeito imediato para definir o idioma do localStorage
    const storedLanguage = localStorage.getItem("userLanguage");
    if (storedLanguage) {
      const initialLanguage = gxpLanguages.find(l => l.cultureCode === storedLanguage);
      if (initialLanguage) {
        setSelectedLanguage(initialLanguage);
        dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: initialLanguage });
        updateI18nLanguage(storedLanguage);
      }
    }
  }, []); // Executa apenas na montagem

    return (
      <header className="bg-white shadow-sm text-gray-800 dark:text-white">
        <div className="px-6 py-4 flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-800">{title}</h1>
          {subtitle && <p className="text-gray-600 mt-1">{subtitle}</p>}
          <div className="relative" ref={dropdownRef}>
          <button onClick={() => setIsOpen(!isOpen)} className="p-2 hover:bg-gray-100 dark:hover:text-gray-800 rounded-full transition-colors" >
            <UserRoundCog className="h-5 w-5" />
          </button>
          {isOpen && (
            <div className="relative z-30">
              <div className="absolute right-0 mt-2 w-56 z-20 bg-white dark:bg-gray-800 dark:border-gray-600 rounded-lg shadow-lg border border-gray-200 py-1">
                <div className="px-4 py-2 text-sm font-semibold border-b border-gray-200 dark:border-gray-600">
                  {t("settings")}
                </div>

                {/* Dark Mode Toggle */}
                <div className="px-4 pt-2 pb-1 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer flex items-center justify-between">
                  <div className="flex items-center">
                    {darkMode ? ( <Moon className="mr-2 h-4 w-4" /> ) : ( <Sun className="mr-2 h-4 w-4" /> )}
                    <span className="text-sm">{t("darkMode")}</span>
                  </div>
                  <div onClick={handleDarkModeToggle} className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${ darkMode ? "bg-blue-600" : "bg-gray-300" }`} >
                    <div className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${ darkMode ? "translate-x-4" : "translate-x-0" }`} />
                  </div>
                </div>

                {/* Language Selector */}
                {selectedLanguage &&
                <div className="px-4 py-1 flex items-center gap-2">
                  <span className="text-sm">{t("language")}</span>
                  <div className="relative w-full ml-auto">
                    <div onClick={() => setIsLanguageOpen(!isLanguageOpen)} className="w-full p-1 rounded dark:bg-gxp_dark_1 hover:bg-gray-50 text-sm dark:hover:bg-gray-700 dark:text-white cursor-pointer flex items-center justify-between" >
                      <div className="flex items-center justify-end w-full">
                        <img src={`https://flagcdn.com/w20/${selectedLanguage?.code}.png`} alt={gxpLanguages.find(l => l.code === selectedLanguage?.code)?.name} className="w-6 h-4 mr-2 object-contain" />
                        <span className="text-sm">{gxpLanguages.find(l => l.code === selectedLanguage?.code)?.name}</span>
                      </div>
                      <span className="ml-2"><ChevronDown className={`transition-all ${isLanguageOpen?"rotate-180":""}`}/></span>
                    </div>

                    {isLanguageOpen && (
                      <div className="absolute top-full left-0 w-full mt-1 border dark:border-gray-600 rounded bg-white dark:bg-gxp_dark_1 shadow-lg z-50">
                        {gxpLanguages.map((lang) => (
                          <div key={lang.code} onClick={() => { handleLanguageChange(lang.cultureCode); setIsLanguageOpen(false); }} className="p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gxp_dark_2 flex items-center" >
                            <img src={`https://flagcdn.com/w20/${lang.code}.png`} alt={`${lang.name} flag`} className="w-6 h-4 mr-2" />
                            <span className="text-sm">{lang.name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>}

                  {/* Add App Version Information */}
                  <div className="px-4 py-1 text-sm text-gray-500 dark:text-gray-400 text-center border-t border-gray-500">
                  {t("appVersion")} {packageJson.version}
                  </div>
              </div>
            </div>
          )}
        </div>
        </div>
      </header>
    );
  };