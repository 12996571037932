import { useContext, useEffect, useState } from 'react';
import { BoxSelect, Brush, Building2, GroupIcon, Hotel, Layers, LayoutDashboard, LogOut, PaintBucket, Palette, Settings, Sliders, Users } from 'lucide-react';
import { Sidebar } from './Components/Layout/Sidebar';
import { Outlet } from 'react-router-dom';
import { Header } from './Components/Layout/Header';
import { getAdminInitialData } from '../services';
import { AppContext } from '../context/AppContext';
import { Loader } from '../components/Layout/components/Loader/Loader';
import { useTranslation } from 'react-i18next';

// Main Admin Panel Component
export const AdminPanel = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [expandedCategories, setExpandedCategories] = useState(['']);
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global");

  const sidebarCategories = [
    {
      name: 'dashboard',
      label: `${t(`Admin.AdminPanel.dashboard`)}`,
      icon: <LayoutDashboard className="w-5 h-5" />,
      linkTo: '/admin/dashboard'
    },
    {
      name: 'hotels',
      label: `${t(`Admin.AdminPanel.hotels`)}`,
      icon: <Hotel className="w-5 h-5" />,
      subCategories: [
        {
          name: 'groups',
          label: `${t(`Admin.AdminPanel.hotelsGroup`)}`,
          icon: <GroupIcon className="w-5 h-5" />,
          linkTo: '/admin/grouphotels'
        },
        {
          name: 'list',
          label: `${t(`Admin.AdminPanel.hotelsList`)}`,
          icon: <Building2 className="w-5 h-5" />,
          linkTo: '/admin/hotels'
        },
      ]
    },
    {
      name: 'themes',
      label: `${t(`Admin.AdminPanel.themes`)}`,
      icon: <Palette className="w-5 h-5" />,
      subCategories: [
        {
          name: 'styleCategories',
          label: `${t(`Admin.AdminPanel.categories`)}`,
          icon: <Layers className="w-5 h-5" />,
          linkTo: '/admin/themes/stylecategories'
        },
        {
          name: 'styleSubCategories',
          label: `${t(`Admin.AdminPanel.subcategories`)}`,
          icon: <BoxSelect className="w-5 h-5" />,
          linkTo: '/admin/themes/stylesubcategories'
        },
        {
          name: 'themeStyles',
          label: `${t(`Admin.AdminPanel.categoriesRelations`)}`,
          icon: <Brush className="w-5 h-5" />,
          linkTo: '/admin/themes/themestyles'
        },
        {
          name: 'default',
          label: `${t(`Admin.AdminPanel.defaultThemes`)}`,
          icon: <PaintBucket className="w-5 h-5" />,
          linkTo: '/admin/themes/themesdefault'
        }
      ]
    },
    {
      name: 'settings',
      label: `${t(`Admin.AdminPanel.settings`)}`,
      icon: <Settings className="w-5 h-5" />,
      subCategories: [
        {
          name: 'parameters',
          label: `${t(`Admin.AdminPanel.parameters`)}`,
          icon: <Sliders className="w-5 h-5" />,
          linkTo: '/admin/parameters/parameters'
        }
        // ,
        // {
        //   name: 'database',
        //   label: 'Banco de Dados',
        //   icon: <DatabaseIcon className="w-5 h-5" />,
        //   linkTo: '/admin/parameters/database'
        // },
        // {
        //   name: 'templates',
        //   label: 'Templates',
        //   icon: <FileCode className="w-5 h-5" />,
        //   linkTo: '/admin/parameters/templates'
        // },
        // {
        //   name: 'schemas',
        //   label: 'Schemas',
        //   icon: <FileJson className="w-5 h-5" />,
        //   linkTo: '/admin/parameters/schemas'
        // },
        // {
        //   name: 'docs',
        //   label: 'Documentação',
        //   icon: <BookOpen className="w-5 h-5" />,
        //   linkTo: '/admin/parameters/docs'
        // }
      ]
    },
    {
      name: 'users',
      label: 'Utilizadores',
      icon: <Users className="w-5 h-5" />,
      linkTo: '/admin/users'
    },
    {
      name: 'logout',
      label: 'Terminar Sessão',
      icon: <LogOut className="w-5 h-5" />,
    }
  ];

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const toggleCategory = (category) => {
    setActiveSection(category)
    setExpandedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const fetchAdminData = async () => {
    try {
      setLoading(true);
      const response = await getAdminInitialData()
      dispatch({ type: "SET_ADMIN_INITIALDATA", payload: response});

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

    } catch (err) {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, [state.globalRefresh]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar isOpen={isSidebarOpen} categories={sidebarCategories} activeSection={activeSection} expandedCategories={expandedCategories} onToggle={toggleSidebar} onCategoryClick={setActiveSection} onToggleCategory={toggleCategory} />
      <div className="flex flex-col flex-1 overflow-hidden">
        <Header title={activeSection.includes('.') ? sidebarCategories.find(c => c.name === activeSection.split('.')[0])?.label : sidebarCategories.find(c => c.name === activeSection)?.label } />
        {loading ?
        (<div className="flex justify-center items-center h-screen"><Loader/></div>)
        :
        (<main className="flex-1 overflow-auto">
          <Outlet />
        </main>)
}
      </div>
    </div>
  );
};

