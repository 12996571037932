import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export const EmailValidation = ({ email, onValidationChange, showValidation }) => {
  const [validations, setValidations] = useState({
    atSymbol: false,
    domain: false,
    extension: false,
    noSpaces: false,
    validChars: false
  });

  useEffect(() => {
    const newValidations = {
      atSymbol: email.includes('@'),
      domain: /@[^@\s]+\.[^@\s]+$/.test(email),
      extension: /\.[a-zA-Z]{2,}$/.test(email),
      noSpaces: !/\s/.test(email),
      validChars: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    };

    setValidations(newValidations);
    onValidationChange(Object.values(newValidations).every(v => v));
  }, [email]);

  const ValidationItem = ({ text }) => (
    <div className="flex items-center gap-2 text-[10px]">
      <X className="w-[10px] h-[10px] text-red-500" />
      <span className="text-red-500">{text}</span>
    </div>
  );

  if (!showValidation) return null;

  const failedValidations = {
    atSymbol: !validations.atSymbol && "Must contain @ symbol",
    domain: !validations.domain && "Must have a valid domain (e.g., example.com)",
    extension: !validations.extension && "Must have a valid extension (.com, .org, etc.)",
    noSpaces: !validations.noSpaces && "No spaces allowed",
    validChars: !validations.validChars && "Only valid email characters allowed"
  };

  const failedItems = Object.values(failedValidations).filter(Boolean);

  if (failedItems.length === 0) return null;

  return (
    <div className="mt-1 space-y-1">
      {failedItems.map((text, index) => (
        <ValidationItem key={index} text={text} />
      ))}
    </div>
  );
};