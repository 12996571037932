/**
 * Determina a propriedade CSS e formata o valor com base no código da subcategoria
 * @param {string} styleSubCategoryCode - Código da subcategoria do estilo
 * @param {string} value - Valor a ser formatado
 * @returns {Array|null} Array de objetos com variável e valor, ou null se inválido
 */
export const formatStyleValue = (themeStyleCode, value) => {
    try {
      switch (true) {
        case themeStyleCode.includes('bg_color'):
        case themeStyleCode.includes('text_color'):
        case themeStyleCode.includes('title_color'):
        case themeStyleCode.includes('label_color'):
        case themeStyleCode.includes('placeholder_color'):
        case themeStyleCode.includes('icon_color'):
        case themeStyleCode.includes('border_color'):
        case themeStyleCode.includes('font_family'):

          return [{ variable: themeStyleCode, value }];

        case themeStyleCode.includes('_width'):
        case themeStyleCode.includes('_size'):
          return [{ variable: themeStyleCode, value: `${value}px` }];

        case themeStyleCode.includes('_rounded'):
          const borderRoundedMap = {
            'None': '0px',
            'Small': '4px',
            'Medium': '8px',
            'Large': '12px',
            'Extra Large': '16px',
            'Round': '24px',
            'Full': '9999px'
          };
          return [{
            variable: themeStyleCode,
            value: borderRoundedMap[value] || 'none'
          }];

        case themeStyleCode.includes('border_position'):
          const borderMap = {
            'Bottom': 'none none solid none',
            'X-Axis': 'none solid none solid',
            'Y-Axis': 'solid none solid none',
            'Top': 'solid none none none',
            'Left': 'none none none solid',
            'Right': 'none solid none none',
            'All': 'solid'
          };
          return [{
            variable: themeStyleCode,
            value: borderMap[value] || 'none'
          }];

        case themeStyleCode.includes('OC-FormField-label_location'):
          const OCLabelLocationMap = {
            'Normal': '0px',
            'On Top': '14px',
          };
          return [{
            variable: themeStyleCode,
            value: OCLabelLocationMap[value] || 'none'
          }];

        case themeStyleCode.includes('_variant'):
          const variantMap = {
            'None': { style: 'normal', weight: 'normal' },
            'Italic': { style: 'italic', weight: 'normal' },
            'Bold': { style: 'normal', weight: 'bold' },
            'Bold & Italic': { style: 'italic', weight: 'bold' }
          };

          const variant = variantMap[value] || variantMap['None'];
          const baseVariable = themeStyleCode;

          return [
            { variable: `${baseVariable}-style`, value: variant.style },
            { variable: `${baseVariable}-weight`, value: variant.weight }
          ];

        default:
          console.warn('Unsupported style subcategory code:', themeStyleCode);
          return null;
      }
    } catch (error) {
      console.error('Error formatting style value:', error);
      return null;
    }
};