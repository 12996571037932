import { useState, useMemo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module Nationality
 * @description Component for selecting a guest's nationality.
 * This component provides a dropdown to select a nationality,
 * indicating whether it is a required field based on the provided validation rules.
 * It also handles changes in nationality and validates the NIF based on the selected nationality.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.validateNifonBlur - Function to validate the NIF when the nationality is changed.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing nationality data.
 * @param {Object} props.nifValues - The current NIF values for guests, indexed by guest ID.
 * @param {Object} props.initialData - Contains initial data such as country list.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered dropdown for selecting the nationality.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Nationality component
 * <Nationality
 *   validateNifonBlur={validateNifonBlur}
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   nifValues={nifValues}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const Nationality = ({ validateNifonBlur, handleGuestChange, guest, nifValues, initialData, requiredFields, error}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  // Check if the NationalityId field is required based on the provided validation rules
  const isRequiredId = requiredFields.some(
    (field) => field.Field === "NationalityId" && field.IsMandatory
  );

  const countries = useMemo(() => {
    return initialData.CountryList.map((country) => ({
      ...country,
    }));
  }, [initialData.CountryList]);

  const getCountryLabel = (countryIdOrIso) => {
    if (initialData) {
      const country = initialData.CountryList.find(
        (country) =>
          country.Id === countryIdOrIso || country.IsoCode === countryIdOrIso
      );
      return country ? country.TranslatedDescription : "";
    }
  };

  const getCountryValue = () => {
    if (guest.data.NationalityId) {
      return guest.data.NationalityId;
    } else if (guest.data.NationalityIso) {
      const country = initialData.CountryList.find(
        (c) => c.IsoCode === guest.data.NationalityIso
      );
      return country ? country.Id : undefined;
    }
    return undefined;
  };

  const countryValue = getCountryValue();

  return (
    <div className="flex flex-col w-full h-full content-end ">
      <div className="w-full h-full justify-end flex flex-col dark:text-white ">
        {(labelIsActive === "true" || labelIsActive === null) && (
          <label htmlFor="genero" className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
            {`${t(`Nationality.nationality`)}`} <span className=" dark:text-white ml-2"> {isRequiredId ? `( * )\u00A0` : ``} </span>
          </label>
        )}
        <Select
          suffixIcon={ <ChevronDown className={`OC-FormField-icon_color cursor-pointer dark:text-white transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
          onDropdownVisibleChange={(open) => setIsOpen(open)}
          className={` OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${error ? 'border-red-500' : 'OC-FormField-border_color dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
          onChange={(selected) => { handleGuestChange(guest.id, "NationalityId", selected.value); const selectedCountry = initialData.CountryList.find( (c) => c.Id === selected.value ); if (selectedCountry) { handleGuestChange( guest.id, "NationalityIso", selectedCountry.IsoCode ); } }}
          placeholder={ <span className="ant-select-selection-placeholder dark:bg-gxp_dark_3 dark:text-white w-full py-2 ${error ? 'border-red-500' : 'OC-FormField-border_color dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out"> {placeholderIsActive === "true" ? `${t(`Nationality.nationality`)} ${isRequiredId ? `( * )\u00A0` : ``}` : `${t(`Nationality.select`)}`} </span> }
          optionLabelProp="label"
          showSearch
          dropdownStyle={{ minWidth: "260px" }}
          popupClassName={`dark:dark`}
          labelInValue
          value={ countryValue ? { value: countryValue, label: getCountryLabel(countryValue) } : undefined }
          filterOption={(input, option) => { const country = countries.find( (country) => country.Id === option.value ); return country?.TranslatedDescription ?.toLowerCase() .includes(input.toLowerCase()); }}
          virtual={false}
        >
          {countries.map((country) => (
            <Select.Option key={country.Id} value={country.Id} className="flex items-center " >
              <div className="flex items-center ">
                <img src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`} alt={`${country.TranslatedDescription} flag`} className="w-6 h-4 mr-2" />
                <span className="text-l ">{country.TranslatedDescription}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
        {error && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span>{" "} </div> )}
      </div>
    </div>
  );
};
