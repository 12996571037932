import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Edit, Edit2, ListCollapse, ListCollapseIcon, LucideListCollapse, Plus } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { DynamicValueEditor } from '../../../Components/utils/DynamicValueEditor';
import { AppContext } from '../../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { OCConsentDataCategories, OCFormDataCategories, OCgeneralTermDataCategories, OCSuccessDataCategories } from './iFrameCategoriesData';
import { Tooltip } from 'react-tooltip'

export const SidebarThemeDefault = ({setFile, themeDefaultLines, setThemeDefaultLines, themes, selectedTheme, setSelectedTheme, setIsThemeDefaultModalOpen, categories, setCategories, expandedCategories, toggleCategory, setModalType }) => {
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global");
  const [organizedCategories, setOrganizedCategories] = useState([]);
  const [expandedSections, setExpandedSections] = useState([]);
  const [iFrameCategories, setIFrameCategories] = useState(OCFormDataCategories);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const collapseAll = () => {
    setExpandedSections([]);
  }

  const toggleSection = (sectionId) => {
    setExpandedSections(prev =>
      prev.includes(sectionId)
        ? prev.filter(id => id !== sectionId)
        : [...prev, sectionId]
    );
  };

    const handleThemeChange = (event) => {
        // Find the full theme object that matches the selected ID
        const selectedId = parseInt(event.target.value);
        const newTheme = themes.find(theme => theme.id === selectedId);
        setSelectedTheme(newTheme);
        localStorage.setItem("themeDefaultSelected",JSON.stringify(newTheme))
    };

    const onChangeThemeValues = (e, subCategory) => {
      const existingLine = themeDefaultLines.find(line => (
        line.themeDefaultDescriptionId === selectedTheme.id &&
        line.themeStyleId === subCategory.themeStyleId
      ))

      if (existingLine) {
        const updatedLines = themeDefaultLines.map(line => {
          if (line.themeDefaultDescriptionId === selectedTheme.id &&
              line.themeStyleId === subCategory.themeStyleId) {
            return { ...line, value: e.target.value }
          }
          return line
        })
        setThemeDefaultLines(updatedLines)
      } else {
        const newThemeDefaultLine = {
          themeDefaultDescriptionId: selectedTheme.id,
          themeStyleId: subCategory.themeStyleId,
          value: e.target.value,
          isActive: true
        }
        setThemeDefaultLines([...themeDefaultLines, newThemeDefaultLine])
      }
    }

    const organizeSubCategories = (categories) => {
      return categories.map(category => {
        // Agrupa subcategorias por seção
        const sections = category.subCategories.reduce((acc, subCategory) => {
          // Se não tiver hífen, vai para "General", caso contrário usa o texto antes do hífen
          const section = subCategory.styleSubCategoryCode.includes('-')
            ? subCategory.styleSubCategoryCode.split('-')[0]
            : 'General';

          if (!acc[section]) {
            acc[section] = [];
          }
          acc[section].push(subCategory);
          return acc;
        }, {});

        // Converte o objeto de seções em um array e ordena alfabeticamente
        const organizedSections = Object.entries(sections)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([sectionName, items]) => ({
            id: `${category.styleCategoryCode}-${sectionName}`,
            name: sectionName,
            items: items.sort((a, b) => a.description.localeCompare(b.description))
          }));

        return {
          ...category,
          sections: organizedSections
        };
      });
    };

    useEffect(() => {
      const newCategories = [...categories]
        .sort((a, b) => a.styleCategoryCode.localeCompare(b.styleCategoryCode))
        .map(category => ({
          ...category,
          subCategories: category.subCategories
            .sort((a, b) => a.styleSubCategoryCode.localeCompare(b.styleSubCategoryCode))
            .map(subCategory => {
              const existingLine = themeDefaultLines.find(
                line => line.themeStyleId === subCategory.themeStyleId
              );

              if (existingLine) {
                return {
                  ...subCategory,
                  value: existingLine.value
                };
              }
              return subCategory;
            })
        }));

      if (JSON.stringify(newCategories) !== JSON.stringify(categories)) {
        setCategories(newCategories);
      }
    }, [themeDefaultLines]); // Remover categories das dependências


    useEffect(() => {
      const organized = organizeSubCategories(categories);
      setOrganizedCategories(organized);
    }, [categories]); // Só recalcula quando categories mudar

    useEffect(() => {

      const handleMessage = (event) => {
        if (event.data.action === "hover") {
          // console.log(`Hovered over element:`, event.data);

          const element = document.getElementById(event.data.elementId);

          if (element) {
            element.classList.add('bg-gray-200');
          }

        } else if (event.data.action === "hoverOut") {
          // console.log(`Hover out from element:`, event.data);

          const element = document.getElementById(event.data.elementId);
          if (element) {
            element.classList.remove('bg-gray-200');
          }
        } else if (event.data.action === "OC-FormPage") {
          setIFrameCategories(OCFormDataCategories);
        } else if (event.data.action === "OC-ConsentPage") {
          setIFrameCategories(OCConsentDataCategories);
        } else if (event.data.action === "OC-GeneralTermPage") {
          setIFrameCategories(OCgeneralTermDataCategories);
        } else if (event.data.action === "OC-SuccessPage") {
          setIFrameCategories(OCSuccessDataCategories);
        }
      };

      window.addEventListener("message", handleMessage);

      return () => {
        window.removeEventListener("message", handleMessage);
      };
    }, []);

    useEffect(() => {
      if (organizedCategories?.length && iFrameCategories?.length) {
        const filtered = organizedCategories
          .map(category => ({
            ...category,
            sections: category.sections
              .filter(section =>
                iFrameCategories.some(frameCategory => frameCategory.id === section.id)
              )
              .sort((a, b) => {
                const orderA = iFrameCategories.find(frame => frame.id === a.id)?.order || Number.MAX_VALUE;
                const orderB = iFrameCategories.find(frame => frame.id === b.id)?.order || Number.MAX_VALUE;
                return orderA - orderB;
              })
          }))
          .filter(category => category.sections.length > 0);

        setFilteredCategories(filtered);
      }
    }, [organizedCategories, iFrameCategories]);


    return (
    <div className="min-w-64 bg-white shadow-lg flex flex-col h-[calc(100vh-68px)]">
      {/* Theme Selector no topo do sidebar */}
      <div className="p-4 border-b">
        <div className="flex items-center justify-between mb-2">
          <h2 className="font-semibold">{`${t(`Admin.AdminPanel.themes`)}`}</h2>
          <div>
          <button onClick={() =>{setModalType("edit"); setIsThemeDefaultModalOpen(true)}} className="p-1 hover:bg-gray-100 rounded" >
            <Edit2 className="w-4 h-4" />
          </button>
          <button onClick={() =>{setModalType("add"); setIsThemeDefaultModalOpen(true)}} className="p-1 hover:bg-gray-100 rounded" >
            <Plus className="w-4 h-4" />
          </button>
          </div>
        </div>
        <select value={selectedTheme?.id || ''} onChange={handleThemeChange} className="w-full border rounded px-2 py-1.5 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500" >
          {themes && themes.map((theme) => (
            <option key={theme.id} value={theme.id}>
              {theme.description}
            </option>
          ))}
        </select>
      </div>

      {/* Categories List modificada */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-2">
        <h2 className="font-semibold mx-2 mb-2">{`${t(`Admin.AdminPanel.sections`)}`}</h2>
        {filteredCategories.map((category) => (
            <div key={category.id} className="mb-2 text-sm">
              <div className='mx-2 mb-4'>
              <select
                value={expandedCategories[0] || ''}
                onChange={(e) => toggleCategory(parseInt(e.target.value))}
                className="w-full border rounded hover:bg-gray-50 text-sm py-1.5"
                >
                <option value="">{`${t(`Admin.ThemeStyle.selectCategory`)}`}</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.description}
                  </option>
                ))}
              </select>
                </div>

              <div className="flex items-center justify-between">
                <h2 className="text-lg font-bold mb-2 ml-2">{t(`Admin.AdminPanel.settings`)}</h2>
                <button onClick={collapseAll} className="text-sm text-gray-500 hover:text-gray-700">
                  <ListCollapse data-tooltip-id="collapseAll" data-tooltip-content={t(`Admin.AdminPanel.collapseAll`)} data-tooltip-place="top" />
                  <Tooltip
                    id="collapseAll"
                    style={{ padding: "4px 8px"}}
                    offset={5}
                    />
                </button>
              </div>

              <AnimatePresence>
                {expandedCategories.includes(category.id) && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="ml-4"
                  >
                    {category.sections.map((section) => (
                      <div key={section.id}  className="mb-2 transition-all duration-300">
                        <button id={section.id} onClick={() => toggleSection(section.id)} className="w-full flex items-center justify-between p-2 hover:bg-gray-50 rounded" >
                          <span>{section.name}</span>
                          <ChevronDown className={`w-4 h-4 transform transition-transform ${ expandedSections.includes(section.id) ? "rotate-180" : "" }`} />
                        </button>

                        <AnimatePresence>
                          {expandedSections.includes(section.id) && (
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              className="ml-4"
                            >
                              {section.items.map((subCategory) => (
                                <div key={subCategory.id} className="flex flex-col justify-between py-2 hover:bg-gray-50 rounded group border-b">
                                  <p className='text-sm text-start pb-2'>{subCategory.description}</p>
                                  <DynamicValueEditor
                                    key={`${selectedTheme?.id}-${subCategory.id}`}
                                    type={subCategory.valueType}
                                    value={subCategory.value}
                                    onChange={(e) => onChangeThemeValues(e, subCategory)}
                                    valueList={subCategory.valueList}
                                    setFile={setFile}
                                    styleSubCategoryCode={subCategory.styleSubCategoryCode}
                                    styleCategoryCode={category.styleCategoryCode}
                                  />
                                </div>
                              ))}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};