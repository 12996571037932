import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Hotel, Users, Palette, Home, TrendingUp, ArrowUpRight, ArrowDownRight } from 'lucide-react';

const Card = ({ children, className = "" }) => (
  <div className={`bg-white rounded-lg shadow-md p-6 ${className}`}>
    {children}
  </div>
);

export const Dashboard = () => {
  const themeData = [
    { name: 'Modern', value: 35 },
    { name: 'Classic', value: 25 },
    { name: 'Minimalist', value: 20 },
    { name: 'Luxury', value: 15 },
    { name: 'Boutique', value: 5 },
  ];

  const hotelsByCategory = [
    { category: 'Beach', hotels: 24 },
    { category: 'Mountain', hotels: 18 },
    { category: 'City', hotels: 32 },
    { category: 'Resort', hotels: 15 },
    { category: 'Boutique', hotels: 11 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  const stats = [
    {
      title: "Total Hotels",
      value: "100",
      icon: <Hotel className="w-4 h-4" />,
      trend: "up",
      change: "12%"
    },
    {
      title: "Active Themes",
      value: "24",
      icon: <Palette className="w-4 h-4" />,
      trend: "up",
      change: "8%"
    },
    {
      title: "Hotel Groups",
      value: "15",
      icon: <Home className="w-4 h-4" />,
      trend: "down",
      change: "3%"
    },
    {
      title: "Active Users",
      value: "234",
      icon: <Users className="w-4 h-4" />,
      trend: "up",
      change: "24%"
    }
  ];

  return (
    <div className="p-4 space-y-4">

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {stats.map((stat, index) => (
          <Card key={index}>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">{stat.title}</p>
                <h3 className="text-2xl font-bold mt-1">{stat.value}</h3>
              </div>
              <div className={`p-2 rounded-full ${
                stat.trend === 'up' ? 'bg-green-100' : 'bg-red-100'
              }`}>
                {stat.icon}
              </div>
            </div>
            <div className="flex items-center mt-4">
              {stat.trend === 'up' ? (
                <ArrowUpRight className="w-4 h-4 text-green-500" />
              ) : (
                <ArrowDownRight className="w-4 h-4 text-red-500" />
              )}
              <span className={`ml-1 text-sm ${
                stat.trend === 'up' ? 'text-green-500' : 'text-red-500'
              }`}>
                {stat.change}
              </span>
            </div>
          </Card>
        ))}
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {/* Theme Distribution */}
        <Card>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Theme Distribution</h3>
          </div>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={themeData}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {themeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            {themeData.map((theme, index) => (
              <div key={index} className="flex items-center">
                <div
                  className="w-3 h-3 rounded-full mr-2"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span className="text-sm">{theme.name}: {theme.value}%</span>
              </div>
            ))}
          </div>
        </Card>

        {/* Hotels by Category */}
        <Card>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Hotels by Category</h3>
          </div>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={hotelsByCategory}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="hotels" fill="#0088FE" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    </div>
  );
};
