import onlineCheckInDesktop from "../../../../assets/images/onlineCheckInDesktop.jpeg";
import onlineCheckIn from "../../../../assets/images/onlineCheckIn.jpeg";
import hhsLogo from "../../../../assets/images/hhsLogo.png";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

export const HeadersPageOC = ({ title, room, militaryMode, guest, post }) => {
  const [t] = useTranslation("global");
  const [cookie] = useCookies(["guest"]);
  const [state,dispatch] = useContext(AppContext);
  const [imageUrl, setImageUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {

    if(state.activeTheme){
      const imageUrl = state.activeTheme?.find(theme => theme.themeStyleCode === "OC-Header-image")?.value;
      setImageUrl(imageUrl);
    }

    if(state.activeTheme){
      const logoUrl = state.activeTheme?.find(theme => theme.themeStyleCode === "OC-Header-logo_image")?.value;
      setLogoUrl(logoUrl);
    }
  }, [state.activeTheme]);

  return (
    <section>
      <div className="sm:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-40" style={{ backgroundImage: `url(${imageUrl})`}}>
            <div className="flex justify-center items-center h-full w-ful">
              <img src={logoUrl} className={`w-1/4`} alt="logo" />
            </div>
            <div className="flex ml-6">
              <h1 className={`OC-Header-title_color OC-Header-title_size OC-Header-title_font_family OC-Header-title_variant mb-2 sm:mb-4`}> {" "} {title}{" "} </h1>
            </div>
          </div>
            <div className=" OC-Header-text_color OC-Header-text_size OC-Header-text_font_family OC-Header-text_variant OC-Header-bar_bg_color OC-Header-bar_border_color OC-Header-bar_border_width OC-Header-bar_border_rounded OC-Header-bar_border_position w-full py-4 items-center flex justify-between">
              { militaryMode && post?
                <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
                :
                <h1 className="ml-6"> {" "} {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName} </h1>
              }
              <span className="ml-6"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`} </span>
            </div>
      </div>

      <div className="max-sm:hidden lg:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-52" style={{ backgroundImage: `url(${imageUrl})`}}>
            <div className="flex justify-center items-center h-full w-ful">
              <img src={logoUrl} className={`h-1/2`} alt="logo" />
            </div>
            <div className="flex ml-6">
              <h1 className={`OC-Header-title_color OC-Header-title_size OC-Header-title_font_family OC-Header-title_variant mb-2 sm:mb-4`}> {" "} {title}{" "} </h1>
            </div>
          </div>
            <div className="OC-Header-text_color OC-Header-text_size OC-Header-text_font_family OC-Header-text_variant OC-Header-bar_bg_color OC-Header-bar_border_color OC-Header-bar_border_width OC-Header-bar_border_rounded OC-Header-bar_border_position w-full py-4 items-center flex justify-between">
            { militaryMode && post?
                <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
                :
                <h1 className="ml-6"> {" "} {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName} </h1>
              }
              <span className="ml-6"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`}</span>
            </div>
      </div>

      <div className="max-lg:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-52" style={{ backgroundImage: `url(${imageUrl})`}}>
            <div className="flex justify-center items-center h-full w-ful">
              <img src={logoUrl} className={`h-2/3`} alt="logo" />
            </div>
            <div className="flex ml-6">
              <h1 className={`OC-Header-title_color OC-Header-title_size font-bold OC-Header-title_font_family OC-Header-title_variant mb-2 sm:mb-4`} > {" "} {title}{" "} </h1>
            </div>
          </div>
            <div className="OC-Header-text_color OC-Header-text_size OC-Header-text_font_family OC-Header-text_variant OC-Header-bar_bg_color OC-Header-bar_border_color OC-Header-bar_border_width OC-Header-bar_border_rounded OC-Header-bar_border_position w-full py-4 items-center flex justify-between">
            { militaryMode && post?
                <h1 className="ml-6">{`${post.Description} ${guest?.LastName}`}</h1>
                :
                <h1 className="ml-6"> {" "} {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName} </h1>
              }
              <span className="ml-6"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`} </span>
            </div>
      </div>
    </section>
  );
};
