import { useCookies } from "react-cookie";
import { HotelGroup } from "./HotelGroup";
import { useContext, useEffect, useState } from "react";
import { getHostReservation } from "../../../services";
import { toast } from "react-toastify";
import {Loader} from "../../../components/Layout/components/Loader/Loader.js"
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext.js";

export const ReservationsDisplay = ({ reservations, groupHotels, setReservations, }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(["platformUid", "sUid", "profileKey"]);
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [filteredReservations, setFilteredReservations] = useState([]); // dados filtrados

  const retryRequest = async (fn, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        if (i === retries - 1) throw error;
      }
    }
  };

  const fetchReservations = async () => {
    if (groupHotels && groupHotels.length > 0 && cookies.profileKey) {
      try {
        const reservationPromises = groupHotels.map((hotel) => {
          return retryRequest(() =>
            getHostReservation(cookies.sUid, cookies.profileKey, hotel)
          );
        });
        const results = await Promise.allSettled(reservationPromises);

        const successfulResults = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);

        const combinedReservations = successfulResults.flat();

        setReservations(combinedReservations);

        const failedResults = results.filter(
          (result) => result.status === "rejected"
        );
        if (failedResults.length > 0) {
          toast.warn(`${failedResults.length} reservations failed to fetch.`);
        }
      } catch (error) {
        toast.error("Unable to get reservations!");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const filteredData = reservations
      .map(item => ({
        ...item,
        reservations: item.reservations.filter(res =>
          res.resStatus === 0
        )
      }))
      .filter(item => item.reservations.length > 0); // Remove itens sem reservations

    setFilteredReservations(filteredData);

  }, [reservations]);

  useEffect(() => {
    fetchReservations();
  }, [groupHotels, cookies.profileKey]);

  return (
    <>
      {!isLoading &&
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
            {`${t("ReservationsDisplay.yourreservations")}`}
          </h2>
          <div className="space-y-6">
            <HotelGroup reservations={filteredReservations} isLoading={isLoading} setIsLoading={setIsLoading} />
          </div>
       </div>
      }
    </>
    );
};
