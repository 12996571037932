import { useState, useMemo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module Phone
 * @description Component for handling a guest's phone number input, including country dial code selection.
 * This component provides a dropdown for selecting a dial code, a text input for the phone number,
 * and validates whether the phone number input is required based on provided rules.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.initialData - The initial data containing country information and dial codes.
 * @param {Object} props.guest - The guest object containing data such as the phone number.
 * @param {function} props.handleGuestChange - Function to update guest data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered phone number input field with dial code selection.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Phone component
 * <Phone
 *   initialData={initialData}
 *   guest={guestData}
 *   handleGuestChange={handleGuestChange}
 *   requiredFields={requiredFields}
 * />
 */
export const Phone = ({ initialData, guest, handleGuestChange, requiredFields, error, }) => {
  const [selectedDialCode, setSelectedDialCode] = useState(""); // State to store the selected dial code
  const [phoneNumber, setPhoneNumber] = useState(guest.data.Mobile || ""); // State to store the phone number
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false); // State to check if a dial code has been selected
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  // Check if the Phone field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "Phone" && field.IsMandatory
  );

  const countries = useMemo(() => {
    return initialData.CountryList.map((country) => ({
      ...country,
      dialCode: initialData.DialCodes[country.IsoCode] || "",
    }));
  }, [initialData.CountryList, initialData.DialCodes]);

  /**
   * @function handleDialCodeChange
   * @description Handles changes to the dial code selection.
   * This function is triggered when the user selects a new dial code from the dropdown.
   * It updates the local state with the new dial code and formats the phone number
   * to include the selected dial code, ensuring the previous dial code is removed
   * from the phone number if it exists.
   *
   * @param {Event} e - The event object from the change event of the select element.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // When a user selects a new dial code from the dropdown:
   * handleDialCodeChange(event);
   *
   * // This will result in updating the guest's phone number to include the new dial code,
   * // formatted as "dialCode phoneNumber", without any previous dial code.
   *
   * @see handleGuestChange
   */
  const handleDialCodeChange = (selected) => {
    const dialCode = selected.value; // Aqui extraímos o dialCode selecionado
    setSelectedDialCode(dialCode); // Atualiza o estado com o código de discagem
    setIsDialCodeSelected(true);

    // Atualiza o número de telefone com o código de discagem
    if (phoneNumber) {
      handleGuestChange(
        guest.id,
        "Phone",
        `${dialCode} ${phoneNumber.replace(/^\+\d+\s/, "")}`
      );
    }
  };

  /**
   * @function handlePhoneChange
   * @description Updates the phone number based on user input.
   * This function is triggered when the user types in the phone number input field.
   * It captures the current input value, updates the local state with the new phone number,
   * and formats the phone number by prepending the selected dial code before saving it
   * to the guest's data.
   *
   * @param {Event} e - The event object from the change event of the input element.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // When a user types a new phone number:
   * handlePhoneChange(event);
   *
   * // This will result in updating the guest's phone number to be formatted as
   * // "selectedDialCode phoneNumber", where selectedDialCode is the currently selected
   * // dial code from the dropdown.
   *
   * @see handleGuestChange
   */
  const handlePhoneChange = (e) => {
    const value = e.target.value; // Get the value from the phone input
    // Get the value from the phone input
    setPhoneNumber(value);
    // Call the function to update the guest with the formatted phone number
    handleGuestChange(guest.id, "Mobile", `${selectedDialCode} ${value}`); // Pass formatted value to the function
  };

  return (
    <div>
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="telefone"
          className={`OC-FormField-label_location_phone OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1 z-10`}
        >
          {`${t(`Phone.phonenumber`)}`}
          <span className="text-gray-400 dark:text-white ml-2">
            {isRequired ? `( * )\u00A0` : ``}
          </span>{" "}
        </label>
      )}
      <div className="flex h-[43.62px] w-full">
        <div className="flex h-full w-full items-stretch dark:border-gray-600">
          <Select
            suffixIcon={
              <ChevronDown
                className={`OC-FormField-icon_color dark:text-white transition-transform duration-300 ease-in-out ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded_phone_select OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${
              error
                ? "border-red-500"
                : "OC-FormField-border_color dark:border-white"
            } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
            style={{ width: "150px" }}
            onChange={handleDialCodeChange}
            placeholder={
              <span className="dark:text-gray-400">
                {`${t(`Phone.select`)}`}
              </span>
            }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={
              selectedDialCode
                ? { value: selectedDialCode, label: selectedDialCode }
                : undefined
            }
            filterOption={(input, option) => {
              const country = countries.find(
                (country) => country.dialCode === option.value
              );
              return (
                country?.TranslatedDescription?.toLowerCase().includes(
                  input.toLowerCase()
                ) ||
                country?.dialCode?.toLowerCase().includes(input.toLowerCase())
              );
            }}
            virtual={false}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.IsoCode}
                className={`dark:dark`}
                value={country.dialCode}
                label={
                  <div className="flex items-center">
                    {" "}
                    <img
                      src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                      alt={`${country.TranslatedDescription} flag`}
                      className="w-6 h-4 mr-2"
                    />{" "}
                    <span className="text-l">{country.dialCode}</span>{" "}
                  </div>
                }
              >
                <div className="flex items-center">
                  <img
                    src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                    alt={`${country.TranslatedDescription} flag`}
                    className="w-6 h-4 mr-2 "
                  />
                  <span className="text-l dark:text-white">
                    {country.TranslatedDescription}
                  </span>
                  <span className="ml-2 text-l text-gray-500 dark:text-white">
                    {country.dialCode}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>

          <div className="justify-center w-full h-full">
            <input
              name="Mobile"
              onChange={handlePhoneChange}
              value={phoneNumber}
              type="tel"
              id="telefone"
              className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded_phone OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                error
                  ? "border-red-500"
                  : "OC-FormField-border_color dark:border-white"
              } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
              OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
              placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Phone.phonenumber`)} ${isRequired ? `( * )\u00A0` : ``}` : "" }
            />
          </div>
        </div>
      </div>
      {error && (
        <div className="flex mt-1 items-center">
          {" "}
          <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
          <span className="text-red-500 text-sm">{`${t(
            `HostForm.fieldRequired`
          )}`}</span>{" "}
        </div>
      )}
    </div>
  );
};
