export const categoryTypes = [
 'text',
 'integer',
 'integer-range',
 'boolean',
 'list',
 'font-family-list',
 'color',
 'decimal',
 'image'
];
