import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { useContext, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { useEffect } from "react";

/**
 * @module DocumentType
 * @description Component for selecting the type of document for a guest.
 * This component includes a dropdown menu for selecting a document type,
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing document type data.
 * @param {Array<Object>} props.initialData - Initial data containing available document types,
 *                                            where each object includes:
 *                                            - {string} Id - The unique identifier for the document type.
 *                                            - {string} Description - The name/description of the document type.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered document type selection dropdown.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the DocumentType component
 * <DocumentType
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const DocumentType = ({ handleGuestChange, guest, initialData, requiredFields, error, cS }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  // Determine if the document type field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "DocType" && field.IsMandatory
  );

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col w-full h-full justify-end">
        {(labelIsActive === "true" || labelIsActive === null) && (
          <label className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
            {`${t(`DocumentType.documenttype`)}`}
            <span className="dark:text-white ml-2"> {isRequired ? `( * )\u00A0` : ``} </span>
          </label>
        )}
        <Select
          suffixIcon={ <ChevronDown className={`OC-FormField-icon_color dark:text-white cursor-pointer transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
          onDropdownVisibleChange={(open) => setIsOpen(open)}
          className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${error ? 'border-red-500' : 'OC-FormField-border_color dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
          onChange={(selected) => handleGuestChange(guest.id, "DocType", selected.value) }
          placeholder={ <span className="dark:text-gray-400"> {placeholderIsActive === "true" ? `${t(`DocumentType.documenttype`)} ${isRequired ? `( * )\u00A0` : ``}` : `${t(`DocumentType.select`)}`} </span> }
          optionLabelProp="label"
          popupClassName={`dark:dark`}
          showSearch
          dropdownStyle={{ minWidth: "260px" }}
          labelInValue
          value={ guest.data.DocType ? { value: guest.data.DocType, label: initialData.GuestDocumentTypes.find( (doc) => doc.Id === guest.data.DocType )?.Description, } : undefined }
          filterOption={(input, option) => { return option.children.toLowerCase().includes(input.toLowerCase()); }}
          virtual={false}
        >
          {initialData.GuestDocumentTypes.map((document) => (
            <Select.Option key={document.Id} value={document.Id} label={document.Description} className={`dark:dark`} >
              {document.Description}
            </Select.Option>
          ))}
        </Select>
        {error && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
      </div>
    </div>
  );
};
