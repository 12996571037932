import { Camera } from 'lucide-react'
import React, { useState } from 'react'

export default function ScanDoc() {
    const [connection, setConnection] = useState(false);

    const handleConnection = () => {

    }



  return (
    <div className='flex items-center justify-center rounded-md px-2 py-1 hover:shadow-md cursor-pointer bg-white' style={{border: '2px solid var(--OC-FormField-icon_color)' }}>
        <Camera
            size={24}
            className='OC-Form-icon_color'
            style={{ color: "var(--OC-FormField-icon_color)" }}
        />
    </div>
  )
}
