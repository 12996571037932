import React, { createContext, useReducer } from "react";
import Reducer from "../reducers/AppReducer"

const initialState = {
    globalRefresh:0,
    globalLoading:false,
    userDetails:null,
    reservation:null,
    hotelMenu:[],
    currentHotel:null,
    hotelInitialData:null,
    systemLanguage:{},
    activeTheme:null,
    userLanguage:localStorage.getItem('userLanguage') || null,
    gxpParameters:null,

    adminInitialData:null,
    adminLoggedIn:null
}

export const AppContext = createContext(initialState);

export const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};


