import { ArrowBigLeftDash, Plus, Save, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { Loader } from '../../../components/Layout/components/Loader/Loader';
import { GroupHotelModal } from './GroupHotelModal';
import { getAdminInitialData, saveHotel } from '../../../services';
import { useTranslation } from 'react-i18next';

export const HotelModal = ({isGroupModalOpen, setIsGroupModalOpen, isHotelModalOpen, onCloseHotelModal, title, type, hotel }) => {

    const [isSending, setIsSending] = useState(false);
    const [state, dispatch] = useContext(AppContext);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [t] = useTranslation("global");
    const [saveStatus, setSaveStatus] = useState({
      status: null,
      message: ''
    });
    const [formData, setFormData] = useState({
      id: '',
      hotelId: '',
      name1: '',
      name2: '',
      groupId: '',
      subscriptionKey: '',
      isActive: '',
    });

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;

      if (name === 'hotelId' && type !== 'checkbox') {
        // Verifica se já existe um groupId igual
        const hotelExists = state.adminInitialData?.hotels.some(
          h => h.hotelId.toLowerCase() === value.toLowerCase().trim() &&
          (!hotel || h.id !== hotel.id) // Ignora o próprio registro em caso de edição
        );

        if (hotelExists) {
          setSaveStatus({
            status: 'error',
            message: `${t(`Admin.hotelList.errorHotelID`)}`
          });
        } else {
          setSaveStatus({
            status: null,
            message: ''
          });
        }
      }

      if (name === 'subscriptionKey' && type !== 'checkbox') {
        // Verifica se já existe um groupId igual
        const hotelExists = state.adminInitialData?.hotels.some(
          h => h.subscriptionKey.toLowerCase() === value.toLowerCase().trim() &&
          (!hotel || h.id !== hotel.id) // Ignora o próprio registro em caso de edição
        );

        if (hotelExists) {
          setSaveStatus({
            status: 'error',
            message: `${t(`Admin.hotelList.errorSubscriptionKey`)}`
          });
        } else {
          setSaveStatus({
            status: null,
            message: ''
          });
        }
      }

      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    };

    const postHotel = async () => {
      try {
        setIsSending(true); // Set sending state to true
        await saveHotel(formData); // Send request
        setSaveStatus({
          status: 'success',
          message: `${t(`Admin.hotelList.success`)}`
        });
        return true;
      } catch (error) {
        console.error("Error saving hotel:", error);
        setSaveStatus({
          status: 'error',
          message: error.message || `${t(`Admin.hotelList.error`)}`
        });
        return false;
      } finally {
        setIsSending(false); // Set sending state back to false
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSending(true);
      if (type === 'add') {

        const success = await postHotel(formData);

        if (success) {

          // Aguarda 2 segundos para mostrar o feedback antes de fechar
          setTimeout(() => {
              dispatch({ type: "SET_GLOBAL_REFRESH", payload: state.globalRefresh + 1 });
                onCloseHotelModal();
            }, 2000);
        }
      } else if (type === 'edit') {

        const success = await saveHotel(formData);

        if (success) {
          setTimeout(() => {
            dispatch({ type: "SET_GLOBAL_REFRESH", payload: state.globalRefresh + 1 });
            onCloseHotelModal();
          }, 2000);
        }

    };
  }

  const refreshData = useCallback(async () => {
    try {
      setIsSending(true);
      const newData = await getAdminInitialData(); // sua chamada API

      dispatch({ type: "SET_HOTEL_INITIALDATA", payload: newData });
      // Atualiza os grupos disponíveis localmente
      setAvailableGroups(newData.groupHotels);
    } catch (error) {
      console.error("Erro ao atualizar dados:", error);
    } finally {
      setIsSending(false);
    }
  }, [dispatch]);

    useEffect(() => {
      if(type === 'add'){
        setFormData({
          id: 0,
          hotelId: '',
          name1: '',
          name2: '',
          groupId: '',
          subscriptionKey: '',
          isActive: true,
        });
      } else if (type === 'edit') {
        setFormData({
          id: hotel.id,
          hotelId: hotel.hotelId,
          name1: hotel.name1,
          name2: hotel.name2,
          groupId: hotel.groupId,
          subscriptionKey: hotel.subscriptionKey,
          isActive: hotel.isActive,
        });
      }
    }, [isHotelModalOpen]);

    useEffect(() => {
      setAvailableGroups(state.adminInitialData?.groupHotels)
    }, [state.adminInitialData?.groupHotels])

  return (
    <AnimatePresence>
      {isHotelModalOpen && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"  >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{
              duration: 0.2,
              ease: "easeOut"
            }}
            className="bg-white rounded-lg shadow-xl w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <motion.div className="flex items-center justify-between p-4 border-b" initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.1 }} >
              <h2 className="text-xl font-semibold">{title}</h2>
              <motion.button onClick={onCloseHotelModal} className="p-1 hover:bg-gray-100 rounded-full" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
                <X className="w-5 h-5" />
              </motion.button>
            </motion.div>

            <motion.div className="p-4" initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.15 }} >
                <>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {`${t(`Admin.hotelList.platformId`)}`}
                      </label>
                      <input disabled={type === 'edit'} type="text" name="hotelId" value={formData.hotelId} onChange={handleChange} className={`mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 ${type === 'edit' ? 'bg-gray-100' : ''}`} required />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {`${t(`Admin.hotelList.mainName`)}`}
                      </label>
                      <input type="text" name="name1" value={formData.name1} onChange={handleChange} className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500" required />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {`${t(`Admin.hotelList.secondaryName`)}`}
                      </label>
                      <input type="text" name="name2" value={formData.name2} onChange={handleChange} className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500" />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {`${t(`Admin.hotelList.groupId`)}`}
                      </label>
                      <div className="flex gap-2">
                        <select name="groupId" value={formData.groupId} onChange={handleChange} className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500" required >
                          <option disabled value="">{`${t(`Admin.hotelModal.selectGroup`)}`}</option>
                          {availableGroups?.map((group) => (
                            <option key={group.groupId} value={group.groupId}>
                              {group.description} ({group.groupId})
                            </option>
                          ))}
                        </select>
                        <button type="button" onClick={() => setIsGroupModalOpen(true)} className="mt-1 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" >
                          <Plus className="h-5 w-5" />
                        </button>
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        {`${t(`Admin.hotelList.subscriptionKey`)}`}
                      </label>
                      <input type="text" name="subscriptionKey" value={formData.subscriptionKey} onChange={handleChange} className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500" required />
                    </div>

                    {type === 'edit' && (
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="isActive"
                          name="isActive"
                          checked={formData.isActive}
                          onChange={handleChange}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900">
                          {`${t(`Admin.hotelList.active`)}`}
                        </label>
                      </div>
                    )}

                            {/* Status Message */}
                            {saveStatus.status && (
                    <div className={`p-3 rounded-md ${
                        saveStatus.status === 'success'
                        ? 'bg-green-50 text-green-700'
                        : 'bg-red-50 text-red-700'
                    }`}>
                        {saveStatus.message}
                    </div>
                    )}

                    <div className="flex justify-end space-x-3 pt-4">
                      <button type="button" onClick={onCloseHotelModal} className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                        <ArrowBigLeftDash className="w-5 h-5" />
                      </button>
                        {isSending ?
                            <button type="submit" className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                               <Loader />
                            </button>
                        :
                            <button type="submit" disabled={saveStatus.status === 'error' || isSending} className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                              <Save className="w-5 h-5" />
                            </button>
                        }
                    </div>
                  </form>
                  <GroupHotelModal onSuccess={refreshData} isGroupModalOpen={isGroupModalOpen} onCloseGroupModal={() => setIsGroupModalOpen(false)} title={`${t(`Admin.groupHotelList.newHotelGroup`)}`} type={"add"} groupHotel={null} />
                </>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};