import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module LastName
 * @description Component for entering a guest's last name.
 * This component provides a text input field for the guest's last name,
 * indicating whether it is a required field based on the provided validation rules.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing last name data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered input field for the last name.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the LastName component
 * <LastName
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const LastName = ({ handleGuestChange, guest, requiredFields, error, cS, }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  // Check if the LastName field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "LastName" && field.IsMandatory
  );

  return (
    <div>
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label htmlFor="apelido" required={isRequired} className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
          {`${t(`LastName.lastname`)}`}
          <span className="dark:text-white ml-2">
            {isRequired ? `( * )\u00A0` : ``}
          </span>
        </label>
      )}
      <input
        name="LastName"
        onChange={(e) =>
          handleGuestChange(guest.id, e.target.name, e.target.value)
        }
        value={guest.data.LastName || ""}
        type="text"
        id="apelido"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`LastName.lastname`)} ${isRequired ? `( * )\u00A0` : ``}` : ""}
      />
      {error && (
        <div className="flex mt-1 items-center">
          {" "}
          <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
          <span className="text-red-500 text-sm">{`${t(
            `HostForm.fieldRequired`
          )}`}</span>{" "}
        </div>
      )}
    </div>
  );
};
