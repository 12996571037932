import { SquarePen } from "lucide-react";
import { useEffect, useRef, useState, useCallback } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";


/**
 * @module SignaturePadComp
 * @description A component for capturing a guest's signature using a signature pad.
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @example
 * <SignaturePadComp guest={guest} setGuests={setGuests} activeTab={activeTab} />
 */
export const SignaturePadComp = ({ guest, setGuests, activeTab, militaryMode, titles }) => {
  const signaturePadRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false); // State to control confirmation
  const [hasSignature, setHasSignature] = useState(false); // State to check if signature exists
  const [t] = useTranslation("global");
  const [post, setPost] = useState("")

  useEffect(()=>{
    if(militaryMode && titles && guest){
      const newTitle = titles?.result?.title.find((element) => element.ID === guest.data.Title_Id)
      setPost(newTitle)
    }
  },[guest, titles, militaryMode])

  /**
   * @function clearSignature
   * @description Clears the signature pad and updates the signature state.
   * @param {Event} e - The event triggered by the clear button click.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * clearSignature(event);
   */
  const clearSignature = (e) => {
    e.preventDefault();
    setGuests((prevGuests) => {
      return prevGuests.map((g) => {
        if (g.id === guest.id) {
          return { ...g, data: { ...g.data, GuestSignatureBase64: null } };
        }
        return g;
      });
    });
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
      setIsConfirmed(false); // Allow user to draw a new signature
      setHasSignature(false); // Reset the signature existence check
        // Atualize o estado do guest e a assinatura
    }
  };

  /**
   * @function loadSignature
   * @description Loads the guest's signature from the guest data if available.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * loadSignature();
   */
  const loadSignature = (guest) => {
    if (signaturePadRef.current && guest.data?.GuestSignatureBase64) {
      const signatureDataUrl = `data:image/png;base64,${guest.data.GuestSignatureBase64}`;
      signaturePadRef.current.fromDataURL(signatureDataUrl, {
        width: signaturePadRef.current.canvas.width,
        height: signaturePadRef.current.canvas.height,
        ratio: 1,  // Adjust this if needed
      });
      setIsLoaded(true);
      setHasSignature(true); // Set the flag that signature exists
    } else {
      setIsLoaded(false);
      setHasSignature(false); // Reset if there's no signature
    }
  };

  /**
   * @function saveSignature
   * @description Saves the signature data to the guest object and updates the guests list.
   * @param {string} signatureData - The base64 encoded signature data.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * saveSignature(signatureData);
   */
// No lugar de chamar setState dentro de eventos, manipule diretamente o SignaturePad
const saveSignature = useCallback(() => {
  const signatureData = signaturePadRef.current?.toDataURL();
  // Armazene os dados de assinatura apenas se o SignaturePad tiver sido desenhado
  if (!signaturePadRef.current.isEmpty()) {
    // Atualize o estado do guest e a assinatura
    setGuests((prevGuests) => {
      return prevGuests.map((g) => {
        if (g.id === guest.id) {
          return { ...g, data: { ...g.data, GuestSignatureBase64: signatureData } };
        }
        return g;
      });
    });
  }
}, [setGuests, guest.id]);

  /**
   * @function handleConfirm
   * @description Confirms the signature and saves it if the signature pad is not empty.
   * @param {Event} e - The event triggered by the confirm button click.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * handleConfirm(event);
   */
  const handleConfirm = (e) => {
    e.preventDefault();
    if (signaturePadRef.current.isEmpty()) {
      alert(`${t(`SignaturePadComp.alersign`)}`); // Improve to provide feedback in UI
      return;
    }
    const dataURL = signaturePadRef.current
      .toDataURL("image/png")
      .split(",")[1];
    saveSignature(dataURL);
    setIsConfirmed(true);  // Updates the state to show the success component
    setHasSignature(true); // Set the flag that signature exists
  };

  /**
   * @events useEffectLoadSignature
   * @description Loads the guest's signature when the guest, activeTab, or isConfirmed changes.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   loadSignature();
   * }, [guest, activeTab, isConfirmed]);
   */
  useEffect(() => {
    loadSignature(guest);
  }, [guest, activeTab, isConfirmed]); // Including guest as a dependency

// New useEffect to check for existing signature and update `hasSignature`
useEffect(() => {
  if (guest?.data?.GuestSignatureBase64) {
    setHasSignature(true);
  } else {
    setHasSignature(false);
  }
}, [guest.id]);

  if (isConfirmed) {
    // Success component
    return (
      <div className="text-center">
        <div className="flex justify-between items-center">
          <h2 className="OC-Consent-title_color OC-Consent-title_size OC-Consent-title_font_family OC-Consent-title_variant mb-4 dark:text-white">{`${t(`SignaturePadComp.signature`)}`}</h2>
          <SquarePen type="button" onClick={(e)=>{clearSignature(e);setIsConfirmed(false)}} className="cursor-pointer dark:text-white " />
        </div>
        <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-4">
        { militaryMode && post?
          <p>{`${t(`HostFormComplete.thankyou`)}`} {post.Description} {guest?.data?.LastName ||`${t(`HostFormComplete.guest`)}`}{`${t(`SignaturePadComp.signaturesaved`)}`}</p>
          :
                <p>{`${t(`HostFormComplete.thankyou`)}`}{guest?.data?.FirstName ||`${t(`HostFormComplete.guest`)}`}{`${t(`SignaturePadComp.signaturesaved`)}`}</p>
              }
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="OC-Consent-title_color OC-Consent-title_size OC-Consent-title_font_family OC-Consent-title_variant mb-4 dark:text-white">{`${t(`SignaturePadComp.guest`)}`}</h2>
      {guest.data.GuestSignatureBase64 ? (
        // Component to indicate signature already exists
        <div className="bg-yellow-100 text-yellow-700 p-4 rounded-lg mb-4">
          <p>{`${t(`SignaturePadComp.signatureexists`)}`}</p>
          <div className="flex space-x-4 mt-4 justify-center">
            <button type="button" onClick={(e)=>clearSignature(e)} className=" py-2 px-4 bg-blue-600 text-white rounded">
              {`${t(`SignaturePadComp.redraw`)}`}
            </button>
          </div>
        </div>
      ) : (
        // Signature pad if no signature exists
        <>
          <div className="border border-gray-300 dark:border-white mb-4">
            <SignaturePad ref={signaturePadRef} options={{ minWidth: 1, maxWidth: 3, penColor: "black" }} canvasProps={{ className: "w-full h-40 bg-white", style: { touchAction: "none" }, }} />
          </div>
          <div className="flex space-x-4 mb-6">
            <button type="button" onClick={clearSignature} className="flex-1 py-2 px-4 border border-gray-300 rounded dark:bg-white" >{`${t(`SignaturePadComp.clean`)}`}</button>
            <button type="button" onClick={handleConfirm} className="flex-1 py-2 px-4 bg-blue-600 text-white rounded" >{`${t(`SignaturePadComp.confirm`)}`}</button>
          </div>
        </>
      )}
    </div>
  );
};
