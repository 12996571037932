export const OCFormDataCategories = [
  {
    order: 1,
    id: "OC-General"
  },
  {
    order: 2,
    id: "OC-Header"
  },
  {
    order: 3,
    id: "OC-Stepper"
  },
  {
    order: 4,
    id: "OC-Form"
  },
  {
    order: 5,
    id: "OC-FormField"
  },
  {
    order: 6,
    id: "OC-Button1"
  }
]

export const OCConsentDataCategories = [
    {
        order: 1,
        id: "OC-General"
      },
      {
        order: 2,
        id: "OC-Header"
      },
      {
        order: 3,
        id: "OC-Stepper"
      },
      {
        order: 4,
        id: "OC-Consent"
      },
      {
        order: 5,
        id: "OC-Button1"
      },
      {
        order: 6,
        id: "OC-Button2"
      },
      {
        order: 7,
        id: "OC-FormField"
      }
  ]

  export const OCgeneralTermDataCategories = [
    {
        order: 1,
        id: "OC-General"
      },
      {
        order: 2,
        id: "OC-Header"
      },
      {
        order: 3,
        id: "OC-Stepper"
      },
      {
        order: 4,
        id: "OC-GeneralTerm"
      },
      {
        order: 5,
        id: "OC-Button2"
      },
      {
        order: 6,
        id: "OC-FormField"
      },
      {
        order: 7,
        id: "OC-Button1"
      }
  ]

  export const OCSuccessDataCategories = [
    {
        order: 1,
        id: "OC-General"
      },
      {
        order: 2,
        id: "OC-Header"
      }

  ]

