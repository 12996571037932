import React, { useCallback, useContext, useEffect, useState } from "react";
import { Plus, X, Check, Search, ArrowRight, Edit2, Save } from "lucide-react";
import { AppContext } from "../../../context/AppContext";
import { getAdminInitialData, saveThemeStyle, uploadImage } from "../../../services";
import { categoryTypes } from "../../Components/utils/categoryTypes";
import { DynamicValueEditor } from "../../Components/utils/DynamicValueEditor";
import { ValueListEditor } from "../../Components/utils/ValueListEditor";
import { ValueListModal } from "../../Components/Modal/ValueListModal";
import { DynamicValueDisplay } from "../../Components/utils/DynamicValueDisplay";
import { CategoriesColumn } from "./Components/CategoriesColumn";
import { SubCategoriesColumn } from "./Components/SubCategoriesColumn";
import { useTranslation } from "react-i18next";

export default function ThemeStyle() {
  const [state, dispatch] = useContext(AppContext);
  const [file, setFile] = useState(null);
  const [isValueListModalOpen, setIsValueListModalOpen] = useState(false);
  const [styleCategories, setStyleCategories] = useState();
  const [styleSubCategories, setStyleSubCategories] = useState();
  const [themeStyles, setThemeStyles] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [t] = useTranslation("global");
  const [saveStatus, setSaveStatus] = useState({
    status: null,
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [editForm, setEditForm] = useState({
    valueDefault: "",
    valueType: "",
    valueList: [],
  });

  const handleStartEdit = (style) => {
    setEditingId(style.id);
    setEditForm({
      valueDefault: style.valueDefault || "",
      valueType: style.valueType || "",
      valueList: style.valueList || [],
    });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({
      valueDefault: "",
      valueType: "",
      valueList: [],
    });
  };

  const handleSaveEdit = async (style) => {

    if(style.valueType === 'image') {
      try {
        const success = await handleImageUpload(style)
        if(success) {
          return
        }
      } catch (error) {
        console.log("error", error)
        return
      }
    }

    const updatedStyle = {
      ...style,
      valueDefault: editForm.valueDefault,
      valueType: editForm.valueType,
      valueList: editForm.valueList,
    };

    const success = await postThemeStyle(updatedStyle);
    if (success) {
      await refreshData();
      setEditingId(null);
    }
  };

  const handleImageUpload = async (style) => {
    if (!file) return false; // Retorna false se não houver arquivo

    try {
        const extension = file.name.split('.').pop();
        const categoryName = state.adminInitialData.styleCategories.find(
            category => category.id === style.styleCategoryId
        ).styleCategoryCode;

        const subcategoryName = state.adminInitialData.styleSubCategories.find(
            subcategory => subcategory.id === style.styleSubCategoryId
        ).styleSubCategoryCode;

        const renamedFile = new File(
            [file],
            `Default-${categoryName}-${subcategoryName}.${extension}`,
            { type: file.type }
        );

        const imageUrl = await uploadImage(renamedFile);
        const updatedStyle = {
            ...style,
            valueDefault: imageUrl.imageUrl,
            valueType: editForm.valueType,
            valueList: editForm.valueList,
        };

        const success = await postThemeStyle(updatedStyle);
        if (success) {
            await refreshData();
            setEditingId(null);
            return true; // Retorna true se tudo der certo
        }
        return false; // Retorna false se postThemeStyle falhar
    } catch (error) {
        console.error("Erro ao fazer upload da imagem:", error);
        return false; // Retorna false em caso de erro
    }
};

  const updateThemeStyles = (style, newValueType) => {
    // Encontra o style a ser atualizado
    const updatedThemeStyles = themeStyles.map(s => {
      if (s.id === style.id) {
        // Se mudar para um tipo que não usa lista, limpar a valueList
        const shouldClearValueList = newValueType !== 'list' && newValueType !== 'integer-range';

        return {
          ...s,
          valueType: newValueType,
          valueList: shouldClearValueList ? [] : s.valueList
        };
      }
      return s;
    });

    // Atualiza o estado dos themeStyles
    setThemeStyles(updatedThemeStyles);

    // Atualiza também o editForm para refletir as mudanças
    setEditForm(prev => ({
      ...prev,
      valueType: newValueType,
      valueList: style.valueList
    }));
  };


  const postThemeStyle = async (newStyleTheme) => {
    try {
      setIsSending(true); // Set sending state to true
      await saveThemeStyle(newStyleTheme); // Send request
      setSaveStatus({
        status: "success",
        message: "SubCategoria guardada com sucesso!",
      });
      return true;
    } catch (error) {
      console.error("Erro ao guardar a subcategoria:", error);
      setSaveStatus({
        status: "error",
        message: error.message || "Falha ao guardar a subcategoria.",
      });
      return false;
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

  const refreshData = async () => {
    try {
      setIsSending(true);
      const newData = await getAdminInitialData(); // sua chamada API
      dispatch({ type: "SET_ADMIN_INITIALDATA", payload: newData });
      // Atualiza os grupos disponíveis localmente
      setThemeStyles(newData.themeStyles);
    } catch (error) {
      console.error("Erro ao atualizar dados:", error);
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setStyleCategories(state.adminInitialData?.styleCategories);
    setStyleSubCategories(state.adminInitialData?.styleSubCategories);
    setThemeStyles(state.adminInitialData?.themeStyles);
  }, [state.adminInitialData]);

  // Função auxiliar para obter o código da categoria
  const getCategoryCode = (categoryId) => {
    const category = styleCategories?.find((cat) => cat.id === categoryId);
    return category?.styleCategoryCode || "";
  };

  // Função auxiliar para obter o código da subcategoria
  const getSubCategoryCode = (subCategoryId) => {
    const subCategory = styleSubCategories?.find(
      (sub) => sub.id === subCategoryId
    );
    return subCategory?.styleSubCategoryCode || "";
  };

  // Função de ordenação atualizada
  const getSortedThemeStyles = useCallback(() => {
    if (!themeStyles) return [];

    return [...themeStyles]
      .filter(
        (style) =>
          !selectedCategory || style.styleCategoryId === selectedCategory.id
      )
      .sort((a, b) => {
        // Primeiro, ordenar por categoria
        const categoryA = getCategoryCode(a.styleCategoryId);
        const categoryB = getCategoryCode(b.styleCategoryId);

        if (categoryA !== categoryB) {
          return categoryA.localeCompare(categoryB);
        }

        // Se as categorias forem iguais, ordenar por subcategoria
        const subCategoryA = getSubCategoryCode(a.styleSubCategoryId);
        const subCategoryB = getSubCategoryCode(b.styleSubCategoryId);

        return subCategoryA.localeCompare(subCategoryB);
      });
  }, [themeStyles, selectedCategory, styleCategories, styleSubCategories]);

  const handleAddRelationship = async (subcategoryId) => {
    if (!selectedCategory || !themeStyles) return;

    const existingStyle = themeStyles.find(
      (style) =>
        style.styleCategoryId === selectedCategory.id &&
        style.styleSubCategoryId === subcategoryId
    );

    if (existingStyle) {
      if (!existingStyle.isActive) {
        const newStyleTheme = {
          id: existingStyle.id,
          styleCategoryId: existingStyle.styleCategoryId,
          styleSubCategoryId: existingStyle.styleSubCategoryId,
          valueDefault: existingStyle.valueDefault,
          valueList: existingStyle.valueList,
          valueType: existingStyle.valueType,
          isActive: true,
        };
        const success = await postThemeStyle(newStyleTheme);

        if (success) {
          await refreshData();
        }
      }
    } else {
      const newStyleTheme = {
        id: 0,
        styleCategoryId: selectedCategory.id,
        styleSubCategoryId: subcategoryId,
        valueDefault: "",
        valueList: [],
        valueType: "",
        isActive: true,
      };

      const success = await postThemeStyle(newStyleTheme);

      if (success) {
        await refreshData();
      }
    }
  };

  const handleRemoveRelationship = async (categoryId, subcategoryId) => {
    if (!themeStyles) return;

    const existingStyle = themeStyles.find(
      (style) =>
        style.styleCategoryId === categoryId &&
        style.styleSubCategoryId === subcategoryId
    );

    const newStyleTheme = {
      id: existingStyle.id,
      styleCategoryId: existingStyle.styleCategoryId,
      styleSubCategoryId: existingStyle.styleSubCategoryId,
      valueDefault: existingStyle.valueDefault,
      valueList: existingStyle.valueList,
      valueType: existingStyle.valueType,
      isActive: false,
    };

    const success = await postThemeStyle(newStyleTheme);

    if (success) {
      await refreshData();
    }
  };

  const getSubcategoryById = (id) => {
    return styleSubCategories?.find((sub) => sub.id === id) || null;
  };

  const getCategoryById = (id) => {
    return styleCategories?.find((cat) => cat.id === id) || null;
  };

  // Filtros com verificações de null/undefined
  const filteredSubcategories = styleSubCategories
    ? styleSubCategories.filter(
        (sub) =>
          sub.styleSubCategoryCode
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          sub.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const filteredThemeStyles = themeStyles
    ? themeStyles.filter(
        (style) =>
          !selectedCategory || style.styleCategoryId === selectedCategory.id
      )
    : [];

  const renderValueDefaultCell = (style, isEditing) => {

    const styleSubCategoryCode = state.adminInitialData?.styleSubCategories?.find(
      subCat => subCat.id === style.styleSubCategoryId
    ).styleSubCategoryCode;

    const styleCategoryCode = state.adminInitialData?.styleCategories?.find(
      Cat => Cat.id === style.styleCategoryId
    ).styleCategoryCode;

    if (isEditing) {
      return (
        <DynamicValueEditor
          type={editForm.valueType}
          value={editForm.valueDefault}
          onChange={(e) =>
            setEditForm({ ...editForm, valueDefault: e.target.value })
          }
          valueList={editForm.valueList}
          styleCategoryCode={styleCategoryCode}
          styleSubCategoryCode={styleSubCategoryCode}
          setFile={setFile}
        />
      );
    }
    return <DynamicValueDisplay type={style.valueType} value={style.valueDefault}/>;
  };

  return (
    <div className="p-6 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl font-semibold text-gray-800 mb-8">{`${t(`Admin.AdminPanel.categoriesRelations`)}`}</h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">

          <CategoriesColumn styleCategories={styleCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

          {/* Relationship Indicator */}
          <div className="flex items-center justify-center">
            <div className="text-center">
              <ArrowRight className="w-8 h-8 text-gray-400 mx-auto" />
              <div className="text-sm text-gray-500 mt-2">
                {selectedCategory ? `${t(`Admin.ThemeStyle.associate`)} "${selectedCategory.description}` : `${t(`Admin.ThemeStyle.selectCategory`)}`}
              </div>
            </div>
          </div>

          <SubCategoriesColumn handleRemoveRelationship={handleRemoveRelationship} handleAddRelationship={handleAddRelationship} filteredSubcategories={filteredSubcategories} themeStyles={themeStyles} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        </div>

        <div className="mt-8">
          <h3 className="text-lg font-medium text-gray-700 mb-4">
            {selectedCategory ? `${t(`Admin.ThemeStyle.relationships`)} - ${selectedCategory.styleCategoryCode}` : `${t(`Admin.ThemeStyle.relationships`)} - ${t(`Admin.ThemeStyle.selectCategory`)}`}
          </h3>
          <div className="bg-white rounded-lg shadow-md border overflow-x-auto">
            <table className="w-full table-fixed divide-y divide-gray-200">
            <colgroup><col className="w-48"/><col className="w-48"/><col className="w-40"/><col className="w-40"/><col className="w-40"/><col className="w-32"/><col className="w-32"/></colgroup>
              <thead className="bg-gray-50">
                <tr>
                  <th className="pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                    {`${t(`Admin.ThemeStyle.category`)}`}
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                    {`${t(`Admin.ThemeStyle.subcategory`)}`}
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                    {`${t(`Admin.ThemeStyle.defaultValue`)}`}
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                    {`${t(`Admin.ThemeStyle.type`)}`}
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                    {`${t(`Admin.ThemeStyle.listValues`)}`}
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                    {`${t(`Admin.ThemeStyle.status`)}`}
                  </th>
                  <th className="pl-2 pr-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider truncate">
                    {`${t(`Admin.ThemeStyle.actions`)}`}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getSortedThemeStyles().length > 0 ? (
                  getSortedThemeStyles().map((style) => {
                    const category = getCategoryById(style.styleCategoryId);
                    const subcategory = getSubcategoryById( style.styleSubCategoryId );
                    const isEditing = editingId === style.id;

                    return (
                      <tr key={style.id} className={!style.isActive ? "bg-gray-50" : ""} >
                        <td className="pl-4 pr-2 py-2">
                          <div className="font-medium text-gray-900 truncate"> {category?.styleCategoryCode} </div>
                          <div className="text-sm text-gray-500 truncate"> {category?.description} </div>
                        </td>
                        <td className="pl-2 pr-2 py-2">
                          <div className="font-medium text-gray-900 truncate"> {subcategory?.styleSubCategoryCode} </div>
                          <div className="text-sm text-gray-500 truncate"> {subcategory?.description} </div>
                        </td>
                        <td className="pl-2 pr-2 py-2"> {renderValueDefaultCell(style, isEditing)} </td>
                        <td className="pl-2 pr-2 py-2">
                          {isEditing ? (
                            <select
                              value={editForm.valueType}
                              onChange={(e) => {setEditForm({ ...editForm,valueDefault:'', valueType: e.target.value, valueList:[] }); updateThemeStyles(style, e.target.value) } }
                              className="w-full px-2 py-1 border rounded bg-white"
                            >
                              <option disabled value=""> {`${t(`Admin.ThemeStyle.selectType`)}`} </option>
                              {categoryTypes.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <div className="truncate">{style.valueType}</div>
                          )}
                        </td>
                        <td className="pl-2 pr-2 py-2">
                          {((style.valueType === 'list' && !subcategory?.styleSubCategoryCode.includes('_variant')&& !subcategory?.styleSubCategoryCode.includes('_location') && !subcategory?.styleSubCategoryCode.includes('_position')&& !subcategory?.styleSubCategoryCode.includes('_rounded')) || style.valueType === 'integer-range') && (
                          isEditing ? (
                            <div onClick={() => { setIsValueListModalOpen(true); }} className="truncate px-2 py-1 border rounded border-gray-900 flex cursor-pointer">
                              <Edit2 className="w-5 h-5 mr-2" />
                              <span className="overflow-hidden text-xs">
                                {editForm.valueList?.length ?
                                      <span className="tag-style text-xs">{editForm.valueList?.join('; ')}</span>
                                  : `${t(`Admin.ThemeStyle.valueNull`)}`}
                              </span>
                            </div>
                          ) : (
                            <div className="truncate text-xs">{style.valueList?.join('; ')}</div>
                        ))}
                        </td>
                        <td className="pl-2 pr-2 py-2">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${ style.isActive ? "bg-green-100 text-green-800" : "bg-gray-100 text-gray-800" }`} >
                            {style.isActive ? `${t(`active`)}` : `${t(`inactive`)}`}
                          </span>
                        </td>
                        <td className="pl-2 pr-4 py-2 text-right space-x-2">
                          {isEditing ? (
                            <>
                              <button onClick={() => handleSaveEdit(style)} className="text-green-600 hover:text-green-900 p-1" >
                                <Save className="w-5 h-5" />
                              </button>
                              <button onClick={handleCancelEdit} className="text-gray-600 hover:text-gray-900 p-1" >
                                <X className="w-5 h-5" />
                              </button>
                            </>
                          ) : (
                            <>
                              {style.isActive && (<button onClick={() => handleStartEdit(style)} className="text-blue-600 hover:text-blue-900 p-1" > <Edit2 className="w-5 h-5" /> </button>)}
                              {style.isActive && ( <button onClick={() => handleRemoveRelationship( style.styleCategoryId, style.styleSubCategoryId ) } className="text-red-600 hover:text-red-900 p-1" > <X className="w-5 h-5" /> </button> )}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="px-4 py-4 text-sm text-gray-500 text-center" >
                      {selectedCategory ? `${t(`Admin.ThemeStyle.noRelationship`)}` : `${t(`Admin.ThemeStyle.selectCategoryRelationship`)}`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ValueListModal isValueListModalOpen={isValueListModalOpen} onCloseValueListModal={() => setIsValueListModalOpen(false)} title={`${t(`Admin.ThemeStyle.valueListModalTitle`)}`} type="add" editForm={editForm} setEditForm={setEditForm} />
    </div>
  );
}
