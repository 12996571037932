import { useTranslation } from "react-i18next";
import { AlertTriangle, CalendarIcon } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module BirthDate
 * @description Component for managing the birth date of a guest.
 * This component includes an input field for selecting the birth date
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing birth date data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered birth date input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the BirthDate component
 * <BirthDate
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const BirthDate = ({ handleGuestChange, guest, requiredFields, error}) => {
  const [t] = useTranslation("global");
  const inputRef = useRef("text");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);


  /**
   * @description Formats the date string into 'yyyy-MM-dd' format.
  *
  * @function formatDate
  * @param {string} dateString - The date string to format.
  * @returns {string} The formatted date string in 'yyyy-MM-dd' format or an empty string if invalid.
  *
  * @example
  * // Example usage of formatDate
  * const formattedDate = formatDate(guest.data.BirthDate);
  */
 const formatDate = (dateString) => {
   if (!dateString) return ""; // Return an empty string if the date is null or undefined
   const date = new Date(dateString);
   return date.toISOString().split("T")[0]; // Get only the date part in 'yyyy-MM-dd' format
  };

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.showPicker(); // Este método abre o date picker nativo
    }
  };


  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  // Determine if the BirthDate field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "BirthDate" && field.IsMandatory
  );

  useEffect(() => {
    // Função para lidar com cliques fora do inputRef
    const handleOutsideClick = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        if (inputRef.current.value === "") {
          inputRef.current.type = "text"; // Volta o tipo para 'text' se o valor estiver vazio
        }
      }
    };

    // Adiciona o eventListener para clicar fora
    document.addEventListener("mousedown", handleOutsideClick);

    // Limpa o eventListener quando o componente desmonta
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="dataNascimento"
          className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
        >
          {`${t(`Birthdate.birthdate`)}`}
          <span className="dark:text-white ml-2">
            {" "}
            {isRequired ? `( * )\u00A0` : ``}{" "}
          </span>
        </label>
      )}
      <input
        name="BirthDate"
        required={isRequired}
        onChange={(e) =>
          handleGuestChange(guest.id, e.target.name, e.target.value)
        }
        value={formatDate(guest.data.BirthDate) || ""}
        type={"text"}
        id="dataNascimento"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out [&::-webkit-calendar-picker-indicator]:hidden  OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant custom-placeholder`}
        placeholder={placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Birthdate.birthdate`)} ${isRequired ? `( * )\u00A0` : ``}` : ""}
        onFocus={(e) => (e.target.value === "" ? e.target.type = "date" : e.target.type = "date")}
        onBlur={(e) => (e.target.value === "" ? e.target.type = "text" : e.target.type = "date")}
        ref={inputRef}
      />
      <CalendarIcon
        className="absolute right-[30px] md:right-[70px] -translate-y-9 cursor-pointer"
        style={{ color: "var(--OC-FormField-icon_color)" }}
        onClick={()=>{inputRef.current.type = "date";handleIconClick()}}
      />
      {error && (
        <div className="flex mt-1 items-center">
          {" "}
          <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
          <span className="text-red-500 text-sm">{`${t(
            `HostForm.fieldRequired`
          )}`}</span>{" "}
        </div>
      )}
    </div>
  );
};
