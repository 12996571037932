import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AlertTriangle, ChevronDown, Search } from 'lucide-react';
import { AppContext } from '../../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { PostalCodeModal } from './PostalCodeModal';
import { Select } from 'antd';

export const InvoiceData = ({setError,setLocalReservation, setGuests, handleInvoiceNifChange, initialData, validateInvoiceNifOnBlur, handleGuestChange, guest, error, requiredFields, setRequiredFields, billingType, setBillingType }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [state, dispatch] = useContext(AppContext);
  const [ocInvoiceDataIsActive, setOcInvoiceDataIsActive] = useState("false");
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);
  const [t] = useTranslation("global");
  const [isSameCountry, setIsSameCountry] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    // Lógica para confirmar o endereço
    setIsModalOpen(false);
  };

  const countries = useMemo(() => {
    return initialData.CountryList.map((country) => ({
      ...country,
    }));
  }, [initialData.CountryList]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setBillingType('');
    }
  };

  const handleBillingTypeChange = (type) => {
    setBillingType(type);

    // Construir os novos dados do guest
    const updatedGuestData = {
        ...guest.data,
        invoiceEntityVATNumber: '',
        invoiceEntityFirstName: '',
        invoiceEntityLastName: '',
        invoiceEntityAddress1: '',
        invoiceEntityTown: '',
        invoiceEntityZipCode: '',
        invoiceEntityCountryId: null,
        invoiceEntityCountryIso: '',
        billingType: type
    };

    // Se for sameAsGuest, copiar os dados do guest
    if (type === 'sameAsGuest' && guest.data.IsMainGuest) {
        updatedGuestData.invoiceEntityVATNumber = guest.data.FiscalNumber || '';
        updatedGuestData.invoiceEntityFirstName = guest.data.FirstName || '';
        updatedGuestData.invoiceEntityLastName = guest.data.LastName || '';
        updatedGuestData.invoiceEntityAddress1 = guest.data.Address1 || '';
        updatedGuestData.invoiceEntityTown = guest.data.Town || '';
        updatedGuestData.invoiceEntityZipCode = guest.data.ZipCode || '';
        updatedGuestData.invoiceEntityCountryId = guest.data.CountryId || null;
        updatedGuestData.invoiceEntityCountryIso = guest.data.CountryIso || '';
        updatedGuestData.billingType = 'sameAsGuest';
    }

    if (error) {
      const newError = { ...error };
      delete newError.invoiceEntityVATNumber;
      delete newError.invoiceEntityFirstName;
      delete newError.invoiceEntityLastName;
      delete newError.invoiceEntityAddress1;
      delete newError.invoiceEntityTown;
      delete newError.invoiceEntityZipCode;
      delete newError.invoiceEntityCountryId;
      // Nota: invoiceEntityCountryIso e IsInvoiceSameAsGuest não são campos de erro tipicamente

      // Atualizar o estado de erro (assumindo que existe uma função para isso)
      setError(newError);  // Você precisará receber setFieldErrors como prop
  }

    // Atualizar guests e localReservation em uma única operação
    setGuests(prevGuests =>
        prevGuests.map(g =>
            g.id === guest.id ? { ...g, data: updatedGuestData } : g
        )
    );

    // Atualizar localReservation com o novo billingType e guests atualizados
    setLocalReservation(prev => ({
        ...prev,
        billingType: type,
        reservation: {
            ...prev.reservation,
            Guests: prev.reservation.Guests.map((g, index) =>
                index === guest.id ? updatedGuestData : g
            )
        }
    }));

};

  const getCountryLabel = (countryIdOrIso) => {
    if (initialData) {
      const country = initialData.CountryList.find(
        (country) =>
          country.Id === countryIdOrIso || country.IsoCode === countryIdOrIso
      );
      return country ? country.TranslatedDescription : "";
    }
  };

  const getCountryValue = () => {
    if (guest?.data?.invoiceEntityCountryId) {
      return guest.data.invoiceEntityCountryId;
    } else if (guest?.data?.invoiceEntityCountryIso) {
      const country = initialData.CountryList.find(
        (c) => c.IsoCode === guest.data.invoiceEntityCountryIso
      );
      return country ? country.Id : undefined;
    }
    return undefined;
  };

  const countryValue = getCountryValue();


  //verificar nomes de campos para fields obrigatórios
  //invoiceEntityVATNumber
  //invoiceEntityFirstName
  //invoiceEntityLastName
  //invoiceEntityAddress1
  //invoiceEntityTown
  //invoiceEntityZipCode
  //invoiceEntityCountryId

  // Check if the LastName field is required based on the provided validation rules
  const isRequiredInvoiceEntityVATNumber = requiredFields.some(
    (field) => field.Field === "invoiceEntityVATNumber" && field.IsMandatory
  );

    // Check if the LastName field is required based on the provided validation rules
  const isRequiredInvoiceEntityFirstName = requiredFields.some(
    (field) => field.Field === "invoiceEntityFirstName" && field.IsMandatory
  );

  const isRequiredInvoiceEntityLastName = requiredFields.some(
    (field) => field.Field === "invoiceEntityLastName" && field.IsMandatory
  );

  const isRequiredInvoiceEntityAddress1 = requiredFields.some(
    (field) => field.Field === "invoiceEntityAddress1" && field.IsMandatory
  );

  const isRequiredInvoiceEntityTown = requiredFields.some(
    (field) => field.Field === "invoiceEntityTown" && field.IsMandatory
  );

  const isRequiredInvoiceEntityZipCode = requiredFields.some(
    (field) => field.Field === "invoiceEntityZipCode" && field.IsMandatory
  );

  const isRequiredInvoiceEntityCountryId = requiredFields.some(
    (field) => field.Field === "invoiceEntityCountryId" && field.IsMandatory
  );

  const handleCountryChange = (e, id) => {
    const selectedCountry = JSON.parse(e.target.value);
    handleGuestChange(id, "invoiceEntityCountryId", selectedCountry.Id);
    handleGuestChange(id, "invoiceEntityCountryIso", selectedCountry.IsoCode);
  };

  useEffect(() => {
    setLabelIsActive( state?.activeTheme?.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state?.activeTheme?.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  useEffect(() => {
    setOcInvoiceDataIsActive( state?.gxpParameters?.find( (style) => style.paramCode === "oc_show_invoice_data_form" && style.isActive )?.value || "false" );
  }, [state?.gxpParameters]);

  useEffect(() => {
    const updateRequiredFields = (newFields) => {
        setRequiredFields(prevFields => {
            // Criar um Map dos campos existentes para fácil acesso
            const existingFieldsMap = new Map(
                prevFields.map(field => [field.Field, field])
            );

            // Processar cada novo campo
            newFields.forEach(newField => {
                if (existingFieldsMap.has(newField.Field)) {
                    // Se o campo existe, atualiza o valor
                    existingFieldsMap.set(newField.Field, {
                        ...existingFieldsMap.get(newField.Field),
                        IsMandatory: newField.IsMandatory
                    });
                } else {
                    // Se o campo não existe, adiciona ao Map
                    existingFieldsMap.set(newField.Field, newField);
                }
            });

            // Converter o Map de volta para array
            return Array.from(existingFieldsMap.values());
        });
    };

    if (billingType === 'company') {
        const newBillingFields = [
            {
                "Field": "invoiceEntityFirstName",
                "IsMandatory": false
            },
            {
                "Field": "invoiceEntityLastName",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityAddress1",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityVATNumber",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityTown",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityZipCode",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityCountryId",
                "IsMandatory": true
            }
        ];
        updateRequiredFields(newBillingFields);
    } else if (billingType === 'individual') {
        const newBillingFields = [
            {
                "Field": "invoiceEntityLastName",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityFirstName",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityAddress1",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityVATNumber",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityTown",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityZipCode",
                "IsMandatory": true
            },
            {
                "Field": "invoiceEntityCountryId",
                "IsMandatory": true
            }
        ];
        updateRequiredFields(newBillingFields);
    } else if (billingType === 'sameAsGuest') {
        const newBillingFields = [
            {
                "Field": "invoiceEntityLastName",
                "IsMandatory": false
            },
            {
                "Field": "invoiceEntityFirstName",
                "IsMandatory": false
            },
            {
                "Field": "invoiceEntityAddress1",
                "IsMandatory": false
            },
            {
                "Field": "invoiceEntityVATNumber",
                "IsMandatory": false
            },
            {
                "Field": "invoiceEntityTown",
                "IsMandatory": false
            },
            {
                "Field": "invoiceEntityZipCode",
                "IsMandatory": false
            },
            {
                "Field": "invoiceEntityCountryId",
                "IsMandatory": false
            }
        ];
        updateRequiredFields(newBillingFields);
    }
}, [billingType]);

 useEffect(() => {
    if(isSameCountry){
        validateInvoiceNifOnBlur( guest.id, guest.data.invoiceEntityVATNumber, guest.data.NationalityIso );
    }
  }, [guest.data.NationalityIso]);

  useEffect(() => {
    if (!initialData?.CountryList || !initialData?.Hotel?.CountryCodeLic)
      return;

    if (!guest?.data?.CountryIso) return;

    const hotelCountry = initialData.CountryList.find(
      (element) => element?.IsoCode === initialData.Hotel.CountryCodeLic
    );
    setIsSameCountry(hotelCountry?.IsoCode === guest.data.CountryIso);
  }, [initialData, guest]);

  return (
    (guest.data.IsMainGuest && ocInvoiceDataIsActive === "true" &&
    <div className="mt-4">
      <div onClick={toggleExpand} className="flex items-center justify-between cursor-pointer OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant dark:text-white" >
        <h3 className="font-bold">{`${t(`InvoiceData.billingdata`)}`}</h3>
        <ChevronDown
          className={`transform transition-transform duration-200 ${isExpanded ? 'rotate-180' : ''}`}
          size={20}
        />
      </div>

      <div className={` p-1 overflow-hidden transition-all duration-200 ${isExpanded ? 'max-h-screen mt-4' : 'max-h-0'}`}>
        <div className="flex gap-6 mb-4">

          <label className="ml-1 flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={billingType === 'sameAsGuest'}
              onChange={() => handleBillingTypeChange('sameAsGuest')}
              className="mr-2"
            />
            <span className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant">
            {`${t(`InvoiceData.sameasguest`)}`}
            </span>
          </label>

          <label className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={billingType === 'company'}
              onChange={() => handleBillingTypeChange('company')}
              className="mr-2"
            />
            <span className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant">
              {`${t(`InvoiceData.company`)}`}
            </span>
          </label>

          <label className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={billingType === 'individual'}
              onChange={() => handleBillingTypeChange('individual')}
              className="mr-2 ml-2"
            />
            <span className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant">
              {`${t(`InvoiceData.individual`)}`}
            </span>
          </label>

        </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            {(billingType === 'individual' || billingType === 'company') && (
              <div>
                {(labelIsActive === "true" || labelIsActive === null) && (
                    <label required={isRequiredInvoiceEntityVATNumber} className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
                    {`${billingType === 'individual' ? t(`Nif.vatnumber`) : t(`Nif.companyvatnumber`)}`}
                    <span className="dark:text-white ml-2">
                        {isRequiredInvoiceEntityVATNumber ? `( * )\u00A0` : ``}
                    </span>
                    </label>
                )}
              <input
                type="text"
                name="invoiceEntityVATNumber"
                value={guest.data.invoiceEntityVATNumber || ""}
                onChange={(e) => handleInvoiceNifChange(guest.id, e.target.value)}
                onBlur={() => validateInvoiceNifOnBlur ( guest.id, guest.data.invoiceEntityVATNumber, guest.data.NationalityIso ) }


                className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                    error.invoiceEntityVATNumber ? "border-red-500" : "OC-FormField-border_color dark:border-white"
                  } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
                  OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
                  placeholder={placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Nif.vatnumber`)} ${isRequiredInvoiceEntityVATNumber ? `( * )\u00A0` : ``}` : ""}
              />
                {error.invoiceEntityVATNumber && (
                    <div className="flex mt-1 items-center">
                    {" "}
                    <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
                    <span className="text-red-500 text-sm">{error.invoiceEntityVATNumber}</span>{" "}
                    </div>
                )}

            </div>
            )}
            {billingType === 'individual' && (
            <div>
                {(labelIsActive === "true" || labelIsActive === null) && (
                    <label
                    required={isRequiredInvoiceEntityFirstName}
                    className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
                    >
                    {`${t(`FirstName.firstname`)}`}
                    <span className="dark:text-white ml-2">
                        {isRequiredInvoiceEntityFirstName ? `( * )\u00A0` : ``}
                    </span>
                    </label>
                )}
                <input
                    name="invoiceEntityFirstName"
                    onChange={(e) =>
                    handleGuestChange(guest.id, e.target.name, e.target.value)
                    }
                    value={guest.data.invoiceEntityFirstName || ""}
                    type="text"
                    id="nome"
                    className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                    error.invoiceEntityFirstName
                        ? "border-red-500"
                        : "OC-FormField-border_color dark:border-white"
                    } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
                    OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
                    placeholder={placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`FirstName.firstname`)} ${isRequiredInvoiceEntityFirstName ? `( * )\u00A0` : ``}` : ""}
                />
                {error.invoiceEntityFirstName && (
                    <div className="flex mt-1 items-center">
                    {" "}
                    <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
                    <span className="text-red-500 text-sm">{`${t(
                        `HostForm.fieldRequired`
                    )}`}</span>{" "}
                    </div>
                )}
            </div>
            )}


            {(billingType === 'individual' || billingType === 'company') && (
            <div>
                {(labelIsActive === "true" || labelIsActive === null) && (
                    <label htmlFor="apelido" required={isRequiredInvoiceEntityLastName} className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
                        {`${billingType === 'individual' ? t(`LastName.lastname`) : t(`LastName.designation`)}`}
                        <span className="dark:text-white ml-2">
                            {isRequiredInvoiceEntityLastName ? `( * )\u00A0` : ``}
                        </span>
                    </label>
                )}
                <input
                    name="invoiceEntityLastName"
                    onChange={(e) =>
                    handleGuestChange(guest.id, e.target.name, e.target.value)
                    }
                    value={guest.data.invoiceEntityLastName || ""}
                    type="text"
                    id="apelido"
                    className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                    error.invoiceEntityLastName ? "border-red-500" : "OC-FormField-border_color dark:border-white"
                    } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
                    OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
                    placeholder={placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`LastName.lastname`)} ${isRequiredInvoiceEntityLastName ? `( * )\u00A0` : ``}` : ""}
                />
                {error.invoiceEntityLastName && (
                    <div className="flex mt-1 items-center">
                    {" "}
                    <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
                    <span className="text-red-500 text-sm">{`${t(
                        `HostForm.fieldRequired`
                    )}`}</span>{" "}
                    </div>
                )}
            </div>
            )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 gap-y-0">
            {(billingType === 'individual' || billingType === 'company') && (
              <div>
                    {(labelIsActive === "true" || labelIsActive === null) && (
                        <label
                        className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
                        >
                        {`${t(`Address.address1`)}`}
                        <span className="dark:text-white ml-2">
                            {" "}
                            {isRequiredInvoiceEntityAddress1 ? `( * )\u00A0` : ``}{" "}
                        </span>
                        </label>
                    )}
                    <input
                    name="invoiceEntityAddress1"
                    required={isRequiredInvoiceEntityAddress1}
                    onChange={(e) =>
                    handleGuestChange(guest.id, e.target.name, e.target.value)
                    }
                    value={guest.data.invoiceEntityAddress1 || ""}
                    type="text"
                    id="invoiceEntityAddress1"
                    className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                    error.invoiceEntityAddress1
                        ? "border-red-500"
                        : "OC-FormField-border_color dark:border-white"
                    } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
                    OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
                    placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Address.address1`)} ${isRequiredInvoiceEntityAddress1 ? `( * )\u00A0` : ``}` : "" }
                />
                {error.invoiceEntityAddress1 && (
                    <div className="flex mt-1 items-center">
                    {" "}
                    <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
                    <span className="text-red-500 text-sm">{error.invoiceEntityAddress1}</span>{" "}
                    </div>
                )}
              </div>
            )}

            {(billingType === 'individual' || billingType === 'company') && (
            <div className="relative">
              {(labelIsActive === "true" || labelIsActive === null) && (
                <label
                  htmlFor="postalCode"
                  className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1 `}
                >
                  {`${t(`Address.zipcode`)}`}
                  <span className="dark:text-white ml-2">
                    {" "}
                    {isRequiredInvoiceEntityZipCode ? `( * )\u00A0` : ``}{" "}
                  </span>
                </label>
              )}
              <input
                name="invoiceEntityZipCode"
                required={isRequiredInvoiceEntityZipCode}
                onChange={(e) =>
                  handleGuestChange(guest.id, e.target.name, e.target.value)
                }
                value={guest.data.invoiceEntityZipCode || ""}
                type="text"
                id="invoiceEntityZipCode"
                className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
                  error.invoiceEntityZipCode
                    ? "border-red-500"
                    : "OC-FormField-border_color dark:border-white"
                } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
                OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
                placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Address.zipcode`)} ${isRequiredInvoiceEntityZipCode ? `( * )\u00A0` : ``}` : "" }
              />
              {initialData.Hotel.CountryCodeLic === "BR" && isSameCountry && (
                <span className="absolute top-1/2 right-3 transform flex items-center cursor-pointer dark:text-white dark:hover:text-gxp_dark_2">
                  <Search onClick={() => setIsModalOpen(true)} />
                </span>
              )}
              {error.invoiceEntityZipCode && (
                <div className="flex mt-1 items-center">
                  {" "}
                  <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
                  <span className="text-red-500 text-sm">{error.invoiceEntityZipCode}</span>{" "}
                </div>
              )}
            </div>
            )}

            {(billingType === 'individual' || billingType === 'company') && (
          <div>
          {(labelIsActive === "true" || labelIsActive === null) && (
            <label
              htmlFor="city"
              className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
            >
              {`${t(`Address.city`)}`}
              <span className="dark:text-white ml-2">
                {" "}
                {isRequiredInvoiceEntityTown ? `( * )\u00A0` : ``}{" "}
              </span>
            </label>
          )}
          <input
            name="invoiceEntityTown"
            required={isRequiredInvoiceEntityTown}
            onChange={(e) =>
              handleGuestChange(guest.id, e.target.name, e.target.value)
            }
            value={guest.data.invoiceEntityTown || ""}
            type="text"
            id="invoiceEntityTown"
            className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
              error.invoiceEntityTown
                ? "border-red-500"
                : "OC-FormField-border_color dark:border-white"
            } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
            OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
            placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Address.city`)} ${isRequiredInvoiceEntityTown ? `( * )\u00A0` : ``}` : "" }
          />
          {error.invoiceEntityTown && (
            <div className="flex mt-1 items-center">
              {" "}
              <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
              <span className="text-red-500 text-sm">{error.invoiceEntityTown}</span>{" "}
            </div>
          )}
        </div>
        )}

        {(billingType === 'individual' || billingType === 'company') && (
        <div className="flex flex-col w-full h-full justify-end">
        {(labelIsActive === "true" || labelIsActive === null) && (
          <label
            htmlFor="invoiceEntityCountryId"
            className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2 \ rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
            {`${t(`Address.country`)}`}{" "}
            <span className="dark:text-white ml-2">
              {" "}
              {isRequiredInvoiceEntityCountryId ? `( * )\u00A0` : ``}{" "}
            </span>
          </label>
        )}
          <Select
            suffixIcon={
              <ChevronDown className={`OC-FormField-icon_color dark:text-white transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} />
            }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${
              error.invoiceEntityCountryId
                ? "border-red-500"
                : "OC-FormField-border_color dark:border-white"
            } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
            onChange={(selected) => {
              handleGuestChange(guest.id, "invoiceEntityCountryId", selected.value);

              console.log("selected", guest)
              const selectedCountry = initialData.CountryList.find(
                (c) => c.Id === selected.value
              );
              if (selectedCountry) {
                handleGuestChange( guest.id, "invoiceEntityCountryIso", selectedCountry.IsoCode );
              }
            }}
            placeholder={ <span className="dark:text-gray-400"> {placeholderIsActive === "true" ? `${t(`Address.country`)} ${isRequiredInvoiceEntityCountryId ? `( * )\u00A0` : ``}` : `${t(`Address.select`)}`} </span> }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={ countryValue ? { value: countryValue, label: getCountryLabel(countryValue) } : undefined }
            filterOption={(input, option) => { const country = countries.find( (country) => country.Id === option.value ); return country?.TranslatedDescription?.toLowerCase().includes( input.toLowerCase() ); }}
            virtual={false}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.Id}
                className={`dark:dark`}
                value={country.Id}
                label={
                  <div className="flex items-center">
                    {" "}
                    <img
                      src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                      alt={`${country.TranslatedDescription} flag`}
                      className="w-6 h-4 mr-2"
                    />{" "}
                    <span className="text-l">{country.dialCode}</span>{" "}
                  </div>
                }
              >
                <div className="flex items-center">
                  <img
                    src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                    alt={`${country.TranslatedDescription} flag`}
                    className="w-6 h-4 mr-2"
                  />
                  <span className="text-l">
                    {country.TranslatedDescription}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
          {error.invoiceEntityCountryId && (
            <div className="flex mt-1 items-center">
              {" "}
              <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
              <span className="text-red-500 text-sm">{error.invoiceEntityCountryId}</span>{" "}
            </div>
          )}
        </div>
        )}
          </div>
      </div>

      <PostalCodeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        initialData={initialData}
        guest={guest}
        handleGuestChange={handleGuestChange}
      />
    </div>
    )
  );
};

