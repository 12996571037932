import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module Gender
 * @description Component for selecting a guest's gender.
 * This component provides a dropdown menu for selecting
 * the gender of the guest, with options for Male, Female, and Other.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing gender data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered gender selection dropdown.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Gender component
 * <Gender
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const Gender = ({handleGuestChange, guest, requiredFields, error, cS}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  const genderOptions = [
    { value: 'F', label: t('Gender.female') },
    { value: 'M', label: t('Gender.male') },
    { value: 'O', label: t('Gender.other') }
  ];

  const getGenderLabel = (value) => {
    const option = genderOptions.find(opt => opt.value === value);
    return option ? option.label : '';
  };

    // Check if the Gender field is required based on the provided validation rules
  const isRequired = requiredFields.some( field => field.Field === "Gender" && field.IsMandatory);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col w-full h-full justify-end">
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label htmlFor="genero" className={`z-10 w-fit OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`} >
          {`${t(`Gender.gender`)}`}
          <span className="dark:text-white ml-2"> {isRequired ? `( * )\u00A0` : ``} </span>
        </label>
      )}
      <Select
        suffixIcon={ <ChevronDown className={`OC-FormField-icon_color dark:text-white cursor-pointer transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
        onDropdownVisibleChange={(open) => setIsOpen(open)}
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] ${error ? 'border-red-500' : 'OC-FormField-border_color dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
        onChange={(selected) => handleGuestChange(guest.id, "Gender", selected.value) }
        placeholder={ <span className="dark:text-gray-400"> {placeholderIsActive === "true" ? `${t(`Gender.gender`)} ${isRequired ? `( * )\u00A0` : ``}` : `${t(`Gender.select`)}`} </span> }
        optionLabelProp="label"
        popupClassName={`dark:dark`}
        showSearch
        id="tipoDocumento"
        dropdownStyle={{ minWidth: "260px" }}
        labelInValue
        value={guest.data.Gender ? { value: guest.data.Gender, label: getGenderLabel(guest.data.Gender) } : undefined}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase()) }
        virtual={false}
        >
              {genderOptions.map(option => (
              <Select.Option key={option.value} value={option.value} label={option.label} className={`dark:dark`}>
                {option.label}
              </Select.Option>
            ))}
        </Select>

      {error && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
      </div>
    </div> )
}
