import React from 'react';

export const DynamicValueDisplay = ({ type, value }) => {

  switch (type) {
    case 'text':
      return (
        <div className="px-2 py-1 text-gray-700 rounded">
          {value || '-'}
        </div>
      );

    case 'integer':
      return (
        <div className="px-2 py-1 text-gray-700 rounded">
          {value || '-'}
        </div>
      );

    case 'decimal':
      return (
        <div className="px-2 py-1 text-gray-700 rounded">
          {value || '-'}
        </div>
      );

    case 'color':
      return (
        <div className="flex items-center gap-2">
          {value ? (
            <>
              <div
                className="w-6 h-6 rounded border"
                style={{ backgroundColor: value }}
              />
              <span className="text-gray-700">{value}</span>
            </>
          ) : (
            <span className="text-gray-400">-</span>
          )}
        </div>
      );

    case 'list':
      return (
        <div className="px-2 py-1 text-gray-700 rounded">
          {value || '-'}
        </div>
      );

      case 'font-family-list':
        return (
          <div className={`px-2 py-1 text-gray-700 rounded`} style={{ fontFamily: value }}>
            {value || '-'}
          </div>
        );

      case 'image':
        return (
          <>
          {type === 'image' && value !== '' && (
            <div className="mt-2 relative group">
              <img
                src={value}
                alt="Uploaded"
                className="max-w-xs w-full h-full rounded cover"
                />
            </div>
          )}
          </>
        );



    default:
      return (
        <div className="px-2 py-1 text-gray-700 rounded">
          {value || '-'}
        </div>
      );
  }
};