import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import { Check, Edit, Plus, Search, X } from 'lucide-react';
import { ParameterDescriptionModal } from '../Components/Modal/ParameterDescriptionModal';
import { StyleCategoryModal } from '../Components/Modal/StyleCategoryModal';
import { useTranslation } from 'react-i18next';

export const StyleCategory = () => {
  const [isStyleCategoryModalOpen, setIsStyleCategoryModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [state, dispatch] = useContext(AppContext);
  const [styleCategories, setStyleCategories] = useState([]);
  const [type, setType] = useState("");
  const [styleCategory, setStyleCategory] = useState(null);
  const [t] = useTranslation("global");

  useEffect(() => {
    setStyleCategories(state.adminInitialData?.styleCategories)
  }, [state.adminInitialData])

  return (
<div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">{`${t(`Admin.AdminPanel.categories`)}`}</h2>
          <div className="flex space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder={`${t(`Admin.styleCategory.searchCategory`)}`}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            </div>
            <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700" onClick={() => {setModalTitle(`${t(`Admin.styleCategory.newCategory`)}`);setType("add");setIsStyleCategoryModalOpen(true)}} >
              <Plus className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${t(`Admin.styleCategory.id`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${t(`Admin.styleCategory.code`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${t(`Admin.styleCategory.description`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${t(`Admin.styleCategory.active`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${t(`Admin.styleCategory.actions`)}`}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {styleCategories?.length > 0 ?
              styleCategories.map((style, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{style.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{style.styleCategoryCode}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{style.description}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {<div className="w-8 h-8 rounded-md flex items-center justify-center bg-white border-2 border-gray-200" >
                        {style.isActive ? (
                          <Check className="w-5 h-5 text-green-500" />
                        ) : (
                          <X className="w-5 h-5 text-red-500" />
                        )}
                    </div>}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex space-x-3">
                      <button onClick={() => {setModalTitle(`${t(`Admin.styleCategory.editCategory`)}`); setIsStyleCategoryModalOpen(true); setType("edit"); setStyleCategory(style)}} className="text-blue-600 hover:text-blue-900">
                        <Edit className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{`${t(`Admin.styleCategory.notfound`)}`}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
              </tr>}
            </tbody>
          </table>
        </div>

        {type &&
           <StyleCategoryModal isStyleCategoryModalOpen={isStyleCategoryModalOpen} onCloseStyleCategoryModal={() => setIsStyleCategoryModalOpen(false)} title={modalTitle} type={type} styleCategory={type==="add" ? null : styleCategory} />
        }
      </div>
       )
}
