import React from "react";
import { useTranslation } from "react-i18next";

export const CategoriesColumn = ({styleCategories, selectedCategory, setSelectedCategory}) => {
  const [t] = useTranslation("global");

  return (
    <div className="bg-white rounded-lg shadow-md border p-4 flex flex-col">
      <h3 className="text-lg font-medium text-gray-700 mb-4">{`${t(`Admin.AdminPanel.categories`)}`}</h3>
      <div className="overflow-y-auto h-96 space-y-2 pr-2">
        {styleCategories?.map((category) => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category)}
            className={`w-full p-3 rounded-lg border text-left transition-colors ${
              selectedCategory?.id === category.id
                ? "border-blue-500 bg-blue-50 text-blue-700"
                : "border-gray-200 hover:border-blue-200 hover:bg-blue-50"
            }`}
          >
            <div className="font-medium">{category.styleCategoryCode}</div>
            <div className="text-sm text-gray-500">{category.description}</div>
          </button>
        ))}
      </div>
    </div>
  );
};
