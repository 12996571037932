
import { useTranslation } from "react-i18next";
import { TimePicker } from "./Utilities/TimePicker";

/**
 * @module ArrivalTime
 * @description Component for managing the arrival time and reservation notes for guests.
 * This component allows users to input the expected arrival time and any additional reservation notes.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.guests - An array of guest objects, where each object contains guest data.
 * @param {function} props.setGuests - Function to update the guests' data.
 *
 * @returns {JSX.Element} The rendered arrival time input and reservation notes.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the ArrivalTime component
 * <ArrivalTime guests={guestsArray} setGuests={setGuestsFunction} />
 */
export const ArrivalTime = ({ isHovered, isPreviewMode, OCArrivalTimeRef, handleElementEnter, handleElementLeave, guests, setGuests }) => {
  const [t] = useTranslation("global");

  /**
   * @function handleGuestChange
   * @description Handles changes to the guest data fields.
   * This function updates the specific field for a guest identified by their id,
   * and updates the guests state accordingly.
   *
   * @param {number} id - The unique identifier of the guest to be updated.
   * @param {string} fieldName - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * handleGuestChange(0, 'ArrivalTime', '15:00');
   */
  const handleGuestChange = (id, fieldName, value) => {
    setGuests((prevGuests) =>
      prevGuests.map((guest) =>
        guest.id === id
          ? { ...guest, data: { ...guest.data, [fieldName]: value } }
          : guest
      )
    );
  };

  const format = 'HH:mm';

  return (
    <>
      {guests && guests.length > 0 && (
        <div id="OC-FormField" ref={OCArrivalTimeRef} onMouseOver={(e) => handleElementEnter(e, OCArrivalTimeRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCArrivalTimeRef, "OC-FormField")} className={`${isPreviewMode && isHovered === "OC-FormField" ? "diagonal-stripes" : ""} flex flex-col space-y-4 max-w-md mx-auto p-4`}>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-xs">
              <label htmlFor="horaChegada" className="block text-sm font-medium dark:text-white mb-1 text-center" >
              {`${t(`ArrivalTime.arrivaltime`)}`}
              </label>
              <TimePicker value={guests[0].data.ArrivalTime || ""} onChange={handleGuestChange} />
            </div>
          </div>
          <div>
            <input name="ReservationNotes" onChange={(e) => handleGuestChange(0, e.target.name, e.target.value) } value={guests[0].data.ReservationNotes || ""} type="text" id="comentarios" className="w-full OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:border-white dark:placeholder:text-gray-300 dark:bg-gxp_dark_3 dark:text-white shadow-sm focus:ring-2 focus:border-blue-500 focus:ring-blue-500 OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant" placeholder={`${t(`ArrivalTime.additionalcomments`)}`} />
          </div>
        </div>
      )}
    </>
  );
};
