import React from 'react'
import { CheckCircle, Mail } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export const ConfirmRegister = () => {
  const navigate = useNavigate()

  return (
    <div className="h-full flex items-center justify-center flex-1">
      <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-lg">
        {/* Ícone e título */}
        <div className="text-center mb-6">
          <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
          <h2 className="mt-4 text-2xl font-bold text-gray-900">Registo Efetuado com Sucesso</h2>
        </div>

        {/* Mensagem principal */}
        <div className="mt-6 text-center">
          <p className="text-gray-600">
            A sua conta foi criada, mas precisa de ser ativada por um administrador antes de poder aceder ao sistema.
          </p>
        </div>

        {/* Instruções */}
        <div className="mt-6 bg-blue-50 rounded-lg p-4">
          <div className="flex items-start">
            <Mail className="h-5 w-5 text-blue-600 mt-0.5" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">Próximos passos:</h3>
              <p className="mt-2 text-sm text-blue-600">
                Por favor, contacte o administrador do sistema para ativar a sua conta.
              </p>
              <p className="mt-2 text-sm text-blue-600">
              Tiago Ferreira - tiago.ferreira@hhs.pt
              </p>
              <p className="mt-2 text-sm text-blue-600">
              Sérgio Costa - sergio.costa@hhs.pt
              </p>
            </div>
          </div>
        </div>

        {/* Botão de voltar */}
        <div className="mt-8">
          <button
            onClick={() => navigate('/admin/login')}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Voltar para Login
          </button>
        </div>
      </div>
    </div>
  )
}

