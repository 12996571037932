import { ChevronDown, Hotel, LayoutDashboard, Menu, Package } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import { logoutUser } from "../../../services";
import { AppContext } from "../../../context/AppContext";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { Tooltip } from 'react-tooltip'

export const Sidebar = ({ isOpen, categories, activeSection, expandedCategories, onToggle, onCategoryClick, onToggleCategory }) => {
  const [showContent, setShowContent] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  const logout = async () => {
    setIsLoading(true)

    try {
      const response = await logoutUser()

      if (response.message === "success") {
        console.log("Logout bem sucedido")
        dispatch({type:"SET_ADMIN_LOGGEDIN", payload:null})
        navigate("/admin/login", { replace: true })
        return // garante que o código pare aqui
      }

    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

//   useEffect(() => {
//     if (shouldRedirect) {
//         navigate("/admin/login", { replace: true })
//     }
// }, [shouldRedirect])

  // Função para encontrar a categoria ativa baseada na URL atual
  const findActiveCategory = (pathname) => {
    // Procura primeiro nas subcategorias
    for (const category of categories) {
      if (category.subCategories) {
        const subCategory = category.subCategories.find(sub => sub.linkTo === pathname);
        if (subCategory) {
          onCategoryClick(`${category.name}.${subCategory.name}`);
          if (!expandedCategories.includes(category.name)) {
            onToggleCategory(category.name);
          }
          return;
        }
      }
      // Verifica a categoria principal
      if (category.linkTo === pathname) {
        onCategoryClick(category.name);
        return;
      }
    }
  };

  // Atualiza a categoria ativa quando a rota muda
  useEffect(() => {
    findActiveCategory(location.pathname);
  }, [location.pathname]);

  // Handler para quando a animação da sidebar completar
  const handleAnimationComplete = () => {
    if (isOpen) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  };

  return (
    <motion.div
      className={`${isOpen ? 'w-64' : 'w-20'} bg-white shadow-lg relative`}
      initial={false}
      animate={{ width: isOpen ? 256 : 80 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      onAnimationComplete={handleAnimationComplete}
    >
      <button
        onClick={onToggle}
        className="absolute right-0 top-4 transform translate-x-1/2 bg-white rounded-full p-2 shadow-md"
      >
        <motion.div animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
          <Menu className="w-4 h-4" />
        </motion.div>
      </button>

      <div className="p-4">
        <div className="flex items-center space-x-2 mb-8">
          <Package className="w-8 h-8 text-blue-600" />
          {showContent && isOpen && (
            <span className="text-xl font-bold">
              Admin Panel
            </span>
          )}
        </div>

        <nav>
          {categories.map((category) => (
            <div key={category.name} className="mb-2">
              <Link
                to={category.linkTo}
                onClick={(e) => { if (category.subCategories) { e.preventDefault(); onToggleCategory(category.name); if(!isOpen){ onToggle() } } else { onCategoryClick(category.name); if(!isOpen){ onToggle() }                  } if(category.name === "logout"){ logout() } }}
                className={`w-full flex items-center p-2 rounded-lg ${
                  activeSection === category.name
                    ? 'bg-blue-50 text-blue-600'
                    : 'hover:bg-gray-50'
                }`}
                data-tooltip-id={category.name}
                data-tooltip-content={category.label.charAt(0).toUpperCase() + category.label.slice(1)}
                data-tooltip-place="right"
              >
              {!isOpen && <Tooltip id={category.name} style={{ padding: "4px 8px"}} offset={5} />}

                {category.name === "logout" && isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="shrink-0">
                      {category.icon}
                    </div>
                    {isOpen && (
                      <motion.div
                        className="flex flex-1 items-center whitespace-nowrap"
                        initial={{ width: 0, opacity: 0 }}
                        animate={{ width: 'auto', opacity: 1 }}
                        exit={{ width: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <span className={`ml-3 flex-1 ${
                          category.label === "Utilizadores"
                            ? "line-through"
                            : "no-underline"
                        }`}>
                          {category.label}
                        </span>
                        {category.subCategories && (
                          <motion.div
                            animate={{ rotate: expandedCategories.includes(category.name) ? 180 : 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            <ChevronDown className="w-4 h-4" />
                          </motion.div>
                        )}
                      </motion.div>
                    )}
                  </>
                )}
              </Link>

              <AnimatePresence>
                {isOpen && category.subCategories && expandedCategories.includes(category.name) && (
                  <motion.div
                    className="ml-6 mt-2 space-y-2"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {category.subCategories.map((sub) => (
                      <motion.div
                        key={sub.name}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Link
                          to={sub.linkTo}
                          onClick={() => onCategoryClick(`${category.name}.${sub.name}`)}
                          className={`w-full flex items-center p-2 rounded-lg ${
                            activeSection === `${category.name}.${sub.name}`
                              ? 'bg-blue-50 text-blue-600'
                              : 'hover:bg-gray-50'
                          }`}
                        >
                          {sub.icon}
                          <span className="ml-2">{sub.label}</span>
                        </Link>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </nav>
      </div>
    </motion.div>
  );
};