import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module Nif
 * @description Component for handling the NIF (Tax Identification Number) input for a guest.
 * This component allows for the input of a guest's NIF, determines if it is a required field based on rules,
 * and provides validation feedback.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.guest - The guest object containing data such as NIF, nationality, and country ISO code.
 * @param {function} props.handleNifChange - Function to handle changes in the NIF input field.
 * @param {function} props.validateNifOnBlur - Function to validate the NIF when the input loses focus.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation.
 * @param {boolean} props.error - Indicates whether there is an error in the NIF input.
 * @param {string} props.cS - A string representing conditional styles for the component.
 * @param {Object} props.initialData - Initial data containing the hotel and country information.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @returns {JSX.Element} The rendered NIF input field.
 * @example
 * <Nif
 *   guest={guestData}
 *   handleNifChange={handleNifChange}
 *   validateNifOnBlur={validateNifOnBlur}
 *   requiredFields={requiredFields}
 *   error={nifError}
 *   cS="1"
 *   initialData={initialData}
 * />
 */
export const Nif = ({ guest, handleNifChange, validateNifOnBlur, requiredFields, error,initialData, }) => {
  const [t] = useTranslation("global");
  const [isSameCountry, setIsSameCountry] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  /**
   * @function useEffectsetIsSameCountry
   * @description Checks if the guest's country matches the hotel's country and updates the state.
   * Dependencies: initialData, guest.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * // Automatically determines if the guest's country matches the hotel's country.
   * useEffect(() => {
   *   // Logic to compare countries
   * }, [initialData, guest]);
   */
  useEffect(() => {
    if (!initialData?.CountryList || !initialData?.Hotel?.CountryCodeLic)
      return;

    if (!guest?.data?.CountryIso) return;

    const hotelCountry = initialData.CountryList.find(
      (element) => element?.IsoCode === initialData.Hotel.CountryCodeLic
    );
    setIsSameCountry(hotelCountry?.IsoCode === guest.data.CountryIso);
  }, [initialData, guest]);

  /**
   * @function isRequired
   * @description Determines if the NIF (FiscalNumber) field is required based on validation rules
   *              and if the guest's country matches the hotel's country.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * const isRequired = requiredFields.some(
   *   (field) => field.Field === "FiscalNumber" && field.IsMandatory
   * ) && isSameCountry;
   */
  const isRequired = requiredFields.some( (field) => field.Field === "FiscalNumber" && field.IsMandatory ) && isSameCountry;

  /**
   * @function useEffectvalidateNifOnBlur
   * @description Triggers NIF validation whenever the guest's nationality ISO changes.
   * Dependencies: guest.data.NationalityIso.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   validateNifOnBlur(guest.id, guest.data.FiscalNumber, guest.data.NationalityIso);
   * }, [guest.data.NationalityIso]);
   */
  useEffect(() => {
    if(isSameCountry){
      validateNifOnBlur( guest.id, guest.data.FiscalNumber, guest.data.NationalityIso );
    }
  }, [guest.data.NationalityIso]);

  return (
    <div>
      {(labelIsActive === "true" || labelIsActive === null) && (
      <label
        htmlFor="nif"
        className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
      >
        {`${t(`Nif.vatnumber`)}`}{" "}
        <span className="dark:text-white ml-2">
          {isRequired ? `( * )\u00A0` : ``}
        </span>
      </label>
      )}
      <input
        name="FiscalNumber"
        required={isRequired}
        onChange={(e) => handleNifChange(guest.id, e.target.value)}
        onBlur={() =>
          validateNifOnBlur(
            guest.id,
            guest.data.FiscalNumber,
            guest.data.NationalityIso
          )
        }
        value={guest.data.FiscalNumber || ""}
        type="text"
        id="nif"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Nif.vatnumber`)} ${isRequired ? `( * )\u00A0` : ``}` : "" }
      />
      {error && (
        <div className="flex mt-1 items-center">
          <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
          <span className="text-red-500 text-sm">{error}</span>{" "}
        </div>
      )}
    </div>
  );
};
