import { useState, useRef, useEffect } from "react";
import { ArrowBigLeftDash, ArrowBigRightIcon, CopyIcon } from "lucide-react";
import { FirstName } from "./FormFields/FirstName";
import { LastName } from "./FormFields/LastName";
import { DocumentType } from "./FormFields/DocumentType";
import { DocumentNumber } from "./FormFields/DocumentNumber";
import { DocumentExpireDate } from "./FormFields/DocumentExpireDate";
import { BirthDate } from "./FormFields/BirthDate";
import { Gender } from "./FormFields/Gender";
import { Nationality } from "./FormFields/Nationality";
import { Email } from "./FormFields/Email";
import { Phone } from "./FormFields/Phone";
import { Nif } from "./FormFields/Nif";
import { Address } from "./FormFields/Address";
import { useTranslation } from "react-i18next";
import {Title} from "./FormFields/Title"
import { useLocation, useParams } from "react-router-dom";
import { InvoiceData } from "./FormFields/InvoiceData";
import ScanDoc from "./Utilities/ScanDoc";

/**
 * @module HostForm
 * @description This component allows the user to enter and manage the host's information, including
 * email, phone number, and address. It provides functionality to update the guest's data and validates the email format.
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isMain - Indicates if this is the main host.
 * @param {Object} props.guest - The guest object containing guest data.
 * @param {Function} props.setGuests - Function to update the guests state.
 * @param {Object} props.initialData - Initial data required for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const HostForm = ({localReservation, setLocalReservation, OCButton1Ref, OCFormFieldRef, isHovered, handleElementEnter, handleElementLeave, activeTab, showConsent, showGeneralTerms, previous, next, isMain, mainGuest, guest, guests, setGuests, initialData, titles, militaryMode, titlesLoading, setTitlesLoading, isPreviewMode }) => {
  const [nifErrors, setNifErrors] = useState({}); // State to store NIF errors for each guest
  const [nifValues, setNifValues] = useState({}); // State to store NIFs for each guest
  const [requiredFields, setRequiredFields] = useState( [] );
  const [fieldErrors, setFieldErrors] = useState({});
  const [emailError, setEmailError] = useState(""); // State to track the error message
  const [t] = useTranslation("global");
  const [isSameCountry, setIsSameCountry] = useState(false);
  const { pathname } = useLocation();
  const OCFormRef = useRef(null);
  const [billingType, setBillingType] = useState(null);
  const {detailId} = useParams();

  useEffect(() => {
    if(localReservation && detailId){

      if(detailId && detailId === localReservation.detailId){
        setBillingType(localReservation.billingType)
        return;
      }
      if(detailId && detailId !== localReservation.detailId){
        setBillingType('sameAsGuest')
        return;
      }
  }
  }, [detailId])


  /**
   * @function handleGuestChange
   * @description Updates a specific field in the guest's data.
   * This function modifies the data of a guest identified by the provided ID.
   * It takes the field name to be updated and the new value, then it updates
   * the guest's data in the state by mapping over the current guests.
   * If the guest's ID matches the provided ID, it creates a new object
   * with the updated field, leaving other fields unchanged.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {string|number} id - The unique identifier of the guest whose data is to be updated.
   * @param {string} fieldName - The name of the field to be updated in the guest's data.
   * @param {any} value - The new value to set for the specified field.
   * @returns {void}
   *
   * @example
   * // To update the phone number of a guest with ID 1:
   * handleGuestChange(1, "Phone", "+351 123 456 789");
   */
  const handleGuestChange = (id, fieldName, value) => {
    setGuests((prevGuests) =>
      prevGuests.map((guest) =>
        guest.id === id
          ? { ...guest, data: { ...guest.data, [fieldName]: value } }
          : guest
      )
    );

    setLocalReservation((prevReservation) => {
      // Acessar o estado mais recente dos guests através de uma ref ou estado global
      const updatedGuests = guests.map(guest => {
          if (guest.id === id) {
              return {
                  ...guest,
                  data: { ...guest.data, [fieldName]: value }
              };
          }
          return guest;
      });

      return {
          ...prevReservation,
          reservation: {
              ...prevReservation.reservation,
              Guests: updatedGuests.map((guest) => guest.data),
          },
      };
  });

    // Remove the error for the field being modified
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[fieldName];
      return newErrors;
    });  };


  /**
   * @function copyAddressData
   * @description Copies address data from the main guest to the specified guest index.
   * This function updates the specified guest's address fields (CountryId, CountryIso,
   * Address1, Town, ZipCode) to match the main guest's address data.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {number} guestIndex - The index of the guest to update.
   * @param {Event} e - The event that triggered this function, used to prevent default behavior.
   * @returns {void}
   */
  const copyAddressData = (guestIndex, e) => {
    e.preventDefault();

    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests]; // Create a copy of the previous state

      // Check if the guest index is valid
      if (updatedGuests[guestIndex]) {
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex], // Keep current guest data
          data: {
            ...updatedGuests[guestIndex].data, // Keep current guest data
            CountryId: mainGuest.data.CountryId,
            CountryIso: mainGuest.data.CountryIso,
            Address1: mainGuest.data.Address1,
            Town: mainGuest.data.Town,
            ZipCode: mainGuest.data.ZipCode,
          },
        };
      }

      return updatedGuests; // Return the updated state
    });
  };

  /**
   * @function ValidateNIF
   * @description Validates a NIF (Número de Identificação Fiscal) for Portugal or other countries.
   * This function checks the validity of the provided NIF based on the country specified.
   * For Portugal, it checks the format and the checksum of the NIF. For other countries,
   * it performs basic checks on the length and known invalid NIFs.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {string} nif - The NIF to validate, which may contain spaces or other characters.
   * @param {string} country - The country code to determine validation rules (e.g., "PT" for Portugal).
   * @returns {boolean} Returns true if the NIF is valid, otherwise returns false.
   */
  const validateNIF = (nif, country) => {
    if (!nif || typeof nif !== "string") {
      return false; // Return false if the NIF is invalid
    }

    // Remove all non-numeric characters from the NIF
    nif = nif.replace(/[^\d]+/g, "");

    if (country === "PT") {
      // NIF validation for Portugal (9 digits)
      if (nif.length !== 9) return false;

      const initialDigits = ["1", "2", "3", "5", "6", "7", "8", "9"];
      const firstDigit = nif.charAt(0);

      if (!initialDigits.includes(firstDigit)) return false;

      // Calculate the check digit
      let checkDigit = parseInt(firstDigit) * 9;
      for (let i = 2; i <= 8; i++) {
        checkDigit += parseInt(nif.charAt(i - 1)) * (10 - i);
      }

      checkDigit = 11 - (checkDigit % 11);
      if (checkDigit >= 10) checkDigit = 0;

      return checkDigit === parseInt(nif.charAt(8));
    }

    // Return true for NIFs from other countries, if you don't want to validate
    return true; // Change to false if you want it to return false for NIFs from other countries
  }

  const handleNifChange = (guestId, value) => {
    handleGuestChange(guestId, "FiscalNumber", value);
    // Clear any existing NIF error
    setFieldErrors(prev => ({ ...prev, FiscalNumber: "" }));
  }

  const handleInvoiceNifChange = (guestId, value) => {
    handleGuestChange(guestId, "invoiceEntityVATNumber", value);
    // Clear any existing NIF error
    setFieldErrors(prev => ({ ...prev, invoiceEntityVATNumber: "" }));
  }

  /**
   * @function validateNifonBlur
   * @description Validates the NIF when the input loses focus or the nationality changes.
   * This function checks if the NIF is empty, validates it, and updates the NIF errors state
   * based on the validation result. If valid, it updates the guest's NIF in the global state.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {string} nif - The NIF to validate.
   * @param {string} country - The country code for validation.
   * @param {string|number} id - The unique identifier of the guest being validated.
   * @returns {void}
   */
  const validateNifOnBlur = (guestId, nif, country) => {
    if (!nif || nif.trim() === "") {
      // Clear any error if the field is empty
      setFieldErrors(prev => ({ ...prev, FiscalNumber: "" }));
      return;
    }
    if(isSameCountry){
      if (!validateNIF(nif, country)) {
        setFieldErrors(prev => ({ ...prev, FiscalNumber: t(`HostForm.invalidvat`) }));
      } else {
        setFieldErrors(prev => ({ ...prev, FiscalNumber: "" }));
      }
    }
  }

  const validateInvoiceNifOnBlur = (guestId, nif, country) => {
    if (!nif || nif.trim() === "") {
      // Clear any error if the field is empty
      setFieldErrors(prev => ({ ...prev, invoiceEntityVATNumber: "" }));
      return;
    }
    if(isSameCountry){
      if (!validateNIF(nif, country)) {
        setFieldErrors(prev => ({ ...prev, invoiceEntityVATNumber: t(`HostForm.invalidvat`) }));
      } else {
        setFieldErrors(prev => ({ ...prev, invoiceEntityVATNumber: "" }));
      }
    }
  }

  /**
   * @function validateRequiredFields
   * @description Flattens the structure to ensure access to the list of required fields
   * and checks if all required fields are filled in the guest's data.
   * Returns true if all required fields are filled or if "Fill Later" is checked.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @returns {boolean} True if all required fields are filled; otherwise, false.
   */
  const validateRequiredFields = () => {
    const errors = { ...fieldErrors }; // Mantém os erros existentes (incluindo erros de NIF)
    let allFieldsFilled = true;

    // Verificar campos obrigatórios
    requiredFields.forEach((field) => {
      if (field.IsMandatory) {
        const fieldValue = guest.data[field.Field];
        // Validações específicas por país
        if ((field.Field === "Address2" || field.Field === "Address3" || field.Field === "AddressState" || field.Field === "RegionId")
            && !isSameCountry && initialData?.Hotel?.CountryCodeLic === "BR") {
          return;
        }
        if ((field.Field === "FiscalNumber") && !isSameCountry) {
          return;
        }
        if ((field.Field === "invoiceEntityVATNumber") && !isSameCountry) {
          return;
        }

        // Validação de campo vazio
        if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
          errors[field.Field] = `${t("HostForm.fieldRequired")}`;
          allFieldsFilled = false;
        }
      }
    });

    // Adicionar erro de email se existir
    if (emailError !== "") {
      errors["Email"] = `${t(`Email.error`)}`;
      allFieldsFilled = false;
    }

    // Verificar erros de NIF existentes
    if (fieldErrors.invoiceEntityVATNumber || fieldErrors.FiscalNumber) {
      allFieldsFilled = false;
    }

    setFieldErrors(errors);
    return allFieldsFilled || guest.data.FillLater;
  };

  /**
   * @function handleContinue
   * @description Handles the continue action by validating required fields
   * and calling the next function if validation passes. Alerts the user if not all fields are filled.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @param {Event} e - The event triggered by the continue action.
   * @returns {void}
   */
  const handleContinue = (e) => {
    e.preventDefault(); // Prevents form submission

    if(isPreviewMode){
      handleElementLeave(e, OCButton1Ref, "OC-Button1");
      next(e, guest.id); // If validation passes, calls the next function

      window.parent.postMessage({
        action: "OC-ConsentPage",
        debugInfo: 'Change to Consent Page'
      }, "*");

      return;
    }

    if (validateRequiredFields(e)) {
      next(e, guest.id); // If validation passes, calls the next function
    }
  };

  useEffect(() => {

    if (initialData?.GuestFields) {
      const militaryFields = initialData.Parameters?.MilitaryMode ? [
        { Field: 'Title_Id', IsMandatory: true },
        { Field: 'TitleShort_Id', IsMandatory: true },
        { Field: 'TitleLong_Id', IsMandatory: true },
        { Field: 'Military_Id', IsMandatory: true }
      ] : [];

      setRequiredFields([
        ...(Array.isArray(initialData.GuestFields) ? initialData.GuestFields.flat() : []),
        ...militaryFields
      ]);
    }
  }, [initialData]);

     /**
   * @function useEffectsetIsSameCountry
   * @description Checks if the guest's country matches the hotel's country and updates the state.
   * Dependencies: initialData, guest.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * // Automatically determines if the guest's country matches the hotel's country.
   * useEffect(() => {
   *   // Logic to compare countries
   * }, [initialData, guest]);
   */
     useEffect(() => {

      if (!initialData?.CountryList || !initialData?.Hotel?.CountryCodeLic) return;
      if (!guest?.data?.CountryIso) return;

      const hotelCountry = initialData.CountryList.find(
        (element) => element?.IsoCode === initialData.Hotel.CountryCodeLic
      );

      const newIsSameCountry = hotelCountry?.IsoCode === guest.data.CountryIso;
      if (isSameCountry !== newIsSameCountry) {
        setIsSameCountry(newIsSameCountry);
      }
    }, [initialData?.Hotel?.CountryCodeLic, guest?.data?.CountryIso, isSameCountry]);

  return (
    <>
      {((billingType && guest && initialData && titles && militaryMode===true && !titlesLoading)||(guest && initialData&& militaryMode===false)) &&
        <div id="OC-Form" ref={OCFormRef} onMouseOver={(e) => handleElementEnter(e, OCFormRef, "OC-Form")} onMouseLeave={(e) => handleElementLeave(e, OCFormRef, "OC-Form")} className={`${isPreviewMode && isHovered === "OC-Form" ? "diagonal-stripes" : ""} OC-Form-bg_color OC-Form-border_color OC-Form-border_rounded OC-Form-border_position OC-Form-border_width px-4 py-2 mx-auto dark:bg-gxp_dark_3 shadow mt-4`}>
          <div className={`flex justify-between items-center`}>
            <div className='flex items-center justify-center'>
              <h2 className={`OC-Form-title_color OC-Form-title_size OC-Form-title_font_family OC-Form-title_variant font-bold dark:text-white mr-3`}> {" "} {guest.title}{""} </h2>
              <ScanDoc />
            </div>
            <div className={` ${ guest.id === 0 ? "hidden" : "" } flex items-center`} >
              <input name={`FillLater`} type="checkbox" checked={guest.data.FillLater} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.checked) } className={` mr-2`} />
              <span className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant">{`${t(`HostForm.filllater`)}`}</span>
            </div>
          </div>
          <div className={``}>
            <div id="OC-FormField" ref={OCFormFieldRef} onMouseEnter={(e) => handleElementEnter(e, OCFormFieldRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCFormFieldRef, "OC-FormField")} className={` grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3`}>
              <Title titlesLoading={titlesLoading} setTitlesLoading={setTitlesLoading} handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors} titles={titles} militaryMode={militaryMode} />
            </div>
            <h3 id="OC-Form" className={` OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant dark:text-white pt-4`}>{`${t(`HostForm.data`)}`}</h3>
            <div id="OC-FormField" ref={OCFormFieldRef} onMouseOver={(e) => handleElementEnter(e, OCFormFieldRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCFormFieldRef, "OC-FormField")} className={`${isPreviewMode && isHovered === "OC-FormField" ? "diagonal-stripes" : ""} grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3`}>
              <FirstName id="OC-FormField" className={``} handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors.FirstName} />
              <LastName id="OC-FormField" className={``} handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors.LastName} />
              <BirthDate handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors.Birthdate} />
              <Nif guest={guest} handleNifChange={handleNifChange} validateNifOnBlur={validateNifOnBlur} requiredFields={requiredFields} error={fieldErrors.FiscalNumber} initialData={initialData} />
              <Nationality validateNifOnBlur={validateNifOnBlur} handleGuestChange={handleGuestChange} guest={guest} nifValues={nifValues} initialData={initialData} requiredFields={requiredFields} error={fieldErrors.NationalityId} />
              <Gender handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors.Gender} />
            </div>
              <h3 className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant dark:text-white pt-4">{`${t(`HostForm.identification`)}`}</h3>
            <div id="OC-FormField" ref={OCFormFieldRef} onMouseOver={(e) => handleElementEnter(e, OCFormFieldRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCFormFieldRef, "OC-FormField")} className={`${isPreviewMode && isHovered === "OC-FormField" ? "diagonal-stripes" : ""} grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3`}>
              <DocumentType handleGuestChange={handleGuestChange} guest={guest} initialData={initialData} requiredFields={requiredFields} error={fieldErrors.DocType}  />
              <DocumentNumber handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors.DocNumber} />
              <DocumentExpireDate handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors.DocVal}/>
            </div>
              <h3 className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant dark:text-white pt-4">{`${t(`HostForm.contacts`)}`}</h3>
            <div id="OC-FormField" ref={OCFormFieldRef} onMouseOver={(e) => handleElementEnter(e, OCFormFieldRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCFormFieldRef, "OC-FormField")} className={`${isPreviewMode && isHovered === "OC-FormField" ? "diagonal-stripes" : ""} grid grid-cols-1 md:grid-cols-2 max-sm:gap-y-0 gap-3`}>
              <Email emailError={emailError} setEmailError={setEmailError} handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} error={fieldErrors.Email} />
              <Phone initialData={initialData} guest={guest} handleGuestChange={handleGuestChange} requiredFields={requiredFields} error={fieldErrors.Mobile} />
            </div>
            {(isMain || !isMain) && (
              <div >
                <div className="flex items-center justify-between mt-4">
                  <h3 className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant dark:text-white">{`${t(`HostForm.address`)}`}</h3>
                  {!isMain && (
                    <button onClick={(e) => copyAddressData(guest.id, e)} className="OC-Form-text_color OC-Form-text_size OC-Form-text_font_family OC-Form-text_variant flex items-center dark:text-white hover:text-blue-700 focus:outline-none" >
                      <CopyIcon size={16} className="mr-2" /> {`${t(`HostForm.copy`)}`}
                    </button>
                  )}
                </div>
                <div id="OC-FormField" ref={OCFormFieldRef} onMouseOver={(e) => handleElementEnter(e, OCFormFieldRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCFormFieldRef, "OC-FormField")} className={`${isPreviewMode && isHovered === "OC-FormField" ? "diagonal-stripes" : ""}`}>
                  <Address isSameCountry={isSameCountry} handleGuestChange={handleGuestChange} initialData={initialData} guest={guest} requiredFields={requiredFields} error={fieldErrors}/>
                </div>
                <div id="OC-FormField" ref={OCFormFieldRef} onMouseOver={(e) => handleElementEnter(e, OCFormFieldRef, "OC-FormField")} onMouseLeave={(e) => handleElementLeave(e, OCFormFieldRef, "OC-FormField")} className={`${isPreviewMode && isHovered === "OC-FormField" ? "diagonal-stripes" : ""}`}>
                  <InvoiceData setLocalReservation={setLocalReservation} setGuests={setGuests} initialData={initialData} handleInvoiceNifChange={handleInvoiceNifChange} validateInvoiceNifOnBlur={validateInvoiceNifOnBlur} billingType={billingType} setBillingType={setBillingType} handleGuestChange={handleGuestChange} guest={guest} requiredFields={requiredFields} setRequiredFields={setRequiredFields} error={fieldErrors} setError={setFieldErrors}/>
                </div>
                <div className="flex justify-around mt-6">
                  {!showConsent && !showGeneralTerms && activeTab!==0 &&
                  <button type="button" onClick={(e) => previous(e, guest.id)} className={`py-2 px-4 dark:bg-white border ${ !showGeneralTerms && !showConsent && activeTab === 0 ? "bg-gray-400 cursor-not-allowed" : "OC-Button2-bg_color" } OC-Button2-icon_color OC-Button2-border_color rounded`} >
                    <ArrowBigLeftDash />
                  </button>}
                  <button id="OC-Button1" ref={OCButton1Ref} onMouseOver={(e) => handleElementEnter(e, OCButton1Ref, "OC-Button1")} onMouseLeave={(e) => handleElementLeave(e, OCButton1Ref, "OC-Button1")} type="button" onClick={handleContinue} className={`${isPreviewMode && isHovered === "OC-Button1" ? "diagonal-stripes" : ""} py-2 px-4 ${ showGeneralTerms && !showConsent && activeTab === guests.length - 1 ? "bg-gray-400 cursor-not-allowed" : "OC-Button1-bg_color" } OC-Button1-icon_color OC-Button1-border_color rounded`} >
                    <ArrowBigRightIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      }
    </>
  );
};
