import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { AlertTriangle } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @module FirstName
 * @description Component for entering a guest's first name.
 * This component includes an input field for the first name entry
 * and handles its change through the provided props.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing first name data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered first name input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the FirstName component
 * <FirstName
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const FirstName = ({ handleGuestChange, guest, requiredFields, error, }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  // Check if the FirstName field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "FirstName" && field.IsMandatory
  );

  return (
    <div>
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label
          required={isRequired}
          className={`OC-FormField-label_location OC-FormField-label_color OC-FormField-label_size OC-FormField-label_font_family OC-FormField-label_variant OC-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1`}
        >
          {`${t(`FirstName.firstname`)}`}
          <span className="dark:text-white ml-2">
            {isRequired ? `( * )\u00A0` : ``}
          </span>
        </label>
      )}
      <input
        name="FirstName"
        onChange={(e) =>
          handleGuestChange(guest.id, e.target.name, e.target.value)
        }
        value={guest.data.FirstName || ""}
        type="text"
        id="nome"
        className={`OC-FormField-text_color OC-FormField-text_size OC-FormField-text_font_family OC-FormField-text_variant OC-FormField-bg_color OC-FormField-border_rounded OC-FormField-border_position OC-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] ${
          error
            ? "border-red-500"
            : "OC-FormField-border_color dark:border-white"
        } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
        OC-FormField-placeholder_color OC-FormField-placeholder_font_family OC-FormField-placeholder_variant`}
        placeholder={placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`FirstName.firstname`)} ${isRequired ? `( * )\u00A0` : ``}` : ""}
      />
      {error && (
        <div className="flex mt-1 items-center">
          {" "}
          <AlertTriangle className="text-red-500 mr-1" size={16} />{" "}
          <span className="text-red-500 text-sm">{`${t(
            `HostForm.fieldRequired`
          )}`}</span>{" "}
        </div>
      )}
    </div>
  );
};
