import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import React from "react";
import App from "./App";

import { ScrollToTop } from "./components";
import { Store } from "./context/AppContext";
import { RefsProvider } from "./context/RefsContext";

import { I18nextProvider } from "react-i18next";
import register from "./swDev";

import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";
import { LanguageProvider } from "./pages/Settings/Language/LanguageProvider";
import { configureI18n } from "./pages/Settings/Language/ConfigureI18n";
import 'react-tooltip/dist/react-tooltip.css'



const i18nInstance  = configureI18n();  // Inicialize uma vez

register();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RefsProvider>
      <I18nextProvider i18n={i18nInstance }>
        <LanguageProvider>
          <BrowserRouter forceRefresh={true} basename="/">
            <Store>
              <ScrollToTop />
              <ToastContainer autoClose={3000} position={"bottom-right"} />
              <App />
            </Store>
          </BrowserRouter>
        </LanguageProvider>
      </I18nextProvider>
    </RefsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
