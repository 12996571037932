import { Check, Edit, Plus, Search, Trash2, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { GroupHotelModal } from "../Components/Modal/GroupHotelModal";
import { useTranslation } from "react-i18next";

// HotelList Component
export const GroupHotelList = () => {
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [state, dispatch] = useContext(AppContext);
    const [groupHotels, setGroupHotels] = useState([]);
    const [type, setType] = useState("");
    const [groupHotel, setGroupHotel] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [t] = useTranslation("global");

    useEffect(() => {
      // Verifica se os dados existem antes de atualizar o estado
      if (state.adminInitialData?.groupHotels) {
        setGroupHotels(state.adminInitialData.groupHotels);
      }
    }, [state.adminInitialData]);

    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">{`${t(`Admin.AdminPanel.hotelsGroup`)}`}</h2>
          <div className="flex space-x-4">
            <div className="relative">
              <input type="text" placeholder={`${t(`Admin.groupHotelList.searchGroup`)}`} className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            </div>
            <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700" onClick={() => {setType("add"); setModalTitle(`${t(`Admin.groupHotelList.newHotelGroup`)}`); setIsGroupModalOpen(true); }} >
              <Plus className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.groupHotelList.groupId`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.groupHotelList.description`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.groupHotelList.active`)}`}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {`${t(`Admin.groupHotelList.actions`)}`}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {groupHotels.length > 0 ?
              groupHotels.map((groupHotel, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{groupHotel.groupId}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{groupHotel.description}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {<div className="w-8 h-8 rounded-md flex items-center justify-center bg-white border-2 border-gray-200" >
                        {groupHotel.isActive ? (
                          <Check className="w-5 h-5 text-green-500" />
                        ) : (
                          <X className="w-5 h-5 text-red-500" />
                        )}
                    </div>}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex space-x-3">
                      <button type="button" onClick={() => {setModalTitle(`${t(`Admin.groupHotelList.editHotelGroup`)}`); setIsGroupModalOpen(true); setType("edit"); setGroupHotel(groupHotel)}} className="text-blue-600 hover:text-blue-900">
                        <Edit className="w-4 h-4" />
                      </button>
                      {/* <button className="text-red-600 hover:text-red-900">
                        <Trash2 className="w-4 h-4" />
                      </button> */}
                    </div>
                  </td>
                </tr>
              )):
              <tr>
                <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{`${t(`Admin.groupHotelList.notfound`)}`}</td>
              </tr>}
            </tbody>
          </table>
        </div>
        {type &&
           <GroupHotelModal isGroupModalOpen={isGroupModalOpen} onCloseGroupModal={() => setIsGroupModalOpen(false)} title={modalTitle} type={type} groupHotel={type==="add" ? null : groupHotel} />
        }
      </div>
    );
  };
