import { Save, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { saveGroupHotel } from "../../../services";
import { AppContext } from "../../../context/AppContext";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { ValueListEditor } from "../utils/ValueListEditor";

export const ValueListModal = ({ isValueListModalOpen, onCloseValueListModal, title, type, editForm, setEditForm, }) => {
  const [isSending, setIsSending] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [saveStatus, setSaveStatus] = useState({
    status: null,
    message: "",
  });

  return (
    <AnimatePresence>
      {isValueListModalOpen && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]" >
          <motion.div initial={{ scale: 0.95, opacity: 0, y: 20 }} animate={{ scale: 1, opacity: 1, y: 0 }} exit={{ scale: 0.95, opacity: 0, y: 20 }} transition={{ duration: 0.2, ease: "easeOut", }} className="bg-white rounded-lg shadow-xl w-full max-w-md" >
            <motion.div className="flex items-center justify-between p-4 border-b" initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.1 }} >
              <h2 className="text-xl font-semibold">{title}</h2>
              <motion.button onClick={onCloseValueListModal} className="p-1 hover:bg-gray-100 rounded-full" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
                <X className="w-5 h-5" />
              </motion.button>
            </motion.div>

            <motion.div className="p-4" initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.15 }} >
              <div className="space-y-4" >
                <div>
                  <ValueListEditor editForm={editForm} setEditForm={setEditForm} />
                </div>

                {/* Status Message */}
                {saveStatus.status && (
                  <div className={`p-3 rounded-md ${ saveStatus.status === "success" ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700" }`} >
                    {saveStatus.message}
                  </div>
                )}
                <div className="flex justify-end space-x-3 pt-4">
                  {isSending ? (
                    <button className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                      <Loader />
                    </button>
                  ) : (
                    <button type="button"  onClick={(e) => { onCloseValueListModal(); }} disabled={saveStatus.status === "error" || isSending} className=" flex px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                      <Save className="w-5 h-5" />
                    </button>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
